import { getText } from './http';
import { buildStsUrl } from './stsApi';

class MatchApi {
    constructor(urlPart) {
        this.urlPart = urlPart;
    }

    getUrlSuffix(matchId) {
        return `${this.urlPart}/${matchId}`;
    }

    getUrl(connection, matchId) {
        return `${buildStsUrl(connection)}${this.getUrlSuffix(matchId)}`;
    }

    getXml(connection, matchId, setXml) {
        const url = `/api/sts-mls/sts?stsUrl=${encodeURIComponent(this.getUrl(connection, matchId))}`;
        getText(url, setXml);
    }
}

export const lineupApi = new MatchApi('-02.01-Match-Information');
export const matchStatisticsApi = new MatchApi('-03.03-EventData-Match-Statistics');
export const postMatchApi = new MatchApi('-03.02-EventData-Match-Raw_Postmatch');
export const positionalDataApi = new MatchApi('-04.07-PositionalData-Match-Statistics');
export const heatMapApi = new MatchApi('-04.06-Heatmaps-Match');
export const tacticalFormationApi = new MatchApi('-04.09-RealtacticalFormation');
export const attackingZonesApi = new MatchApi('-06.05-AttackingZones');
