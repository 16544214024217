import React from 'react';
import { useParams } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import DataTable from '../../shared/components/DataTable';
import { useProjectConnectionsListingApi } from '../hooks';

const columns = [
    {
        id: 'code',
        label: 'Connection Code',
        minWidth: 170,
        align: 'center'
    },
    {
        id: 'providerCode',
        label: 'Provider Code',
        minWidth: 170
    },
    {
        id: 'providerName',
        label: 'Provider Name',
        minWidth: 170
    },
];

const ProjectConnectionsList = ({ add, details }) => {
    const { projectCode } = useParams();
    const [{
        isLoading,
        isError,
        data
    }] = useProjectConnectionsListingApi(projectCode);

    useErrorToast(isError, 'Failed to load connections');

    const handleDetails = (row) => details(row);

    const rows = data.map((item) => {
        const provider = item.provider ? item.provider : {};
        return {
            code: item.code,
            providerName: provider.name || '',
            providerCode: provider.code || ''
        };
    });

    return (
        <>
            {isLoading && <LinearProgress role="progressbar" />}
            <DataTable
                columns={columns}
                disablePagination
                rows={rows}
                add={add}
                details={handleDetails}
                tableName="project-connections"
            />
        </>
    );
};

export default ProjectConnectionsList;
