import React from 'react';
import getGroupsApi from '../../shared/api/b2cGroupsApi';
import { notRequested } from '../../shared/async';
import AsyncSelect from '../../shared/components/asyncSelect';

export default function GroupSelect(props) {
    const {
        connection,
        competitionId,
        seasonPlatformId,
        projectId,
        seasonId,
        stageId,
        setGroupId,
        groupId
    } = props;

    const [groups, setGroups] = React.useState(notRequested());
    const [dataset, setDataset] = React.useState(notRequested());

    React.useEffect(() => {
        if (projectId && seasonId) {
            getGroupsApi.getJson(projectId, seasonPlatformId, stageId, setDataset);
        }
    }, [connection, competitionId, seasonPlatformId, stageId, setDataset]);

    React.useEffect(() => {
        if (dataset && dataset.data) {
            const data = JSON.parse(dataset.data);
            const newObj = JSON.parse(JSON.stringify(dataset));
            newObj.data = data.groups;
            setGroups(newObj);
        }
    }, [dataset]);

    return (
        groups
            && (
                <AsyncSelect
                    result={groups}
                    id={groupId}
                    setId={setGroupId}
                    getIdFromItem={(s) => s.id}
                    getNameFromItem={(n) => n.name}
                />
            )
    );
}
