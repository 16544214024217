import { postData } from '../utils/api';

export const CONNECTIONS_API_ROUTE = '/api/connections';
const PROJECT_CONNECTIONS_API_ROUTE = '/api/projects';

export const buildGetProjectConnectionsUrl = (project) => `${PROJECT_CONNECTIONS_API_ROUTE}/${project}/connections`;

export const buildListFilteredConnectionsUrl = (project, { provider }) => {
    const baseUrl = `${PROJECT_CONNECTIONS_API_ROUTE}/${project}/connections`;

    if (provider) {
        return `${baseUrl}?provider=${provider}`;
    }

    return baseUrl;
};

export const buildGetConnectionUrl = (project, code) => `${buildGetProjectConnectionsUrl(project)}/${code}`;

export const editConnection = (connection) => postData(
    buildGetConnectionUrl(connection.project, connection.code),
    connection,
    'PUT'
);

export const deleteConnection = (connection) => postData(
    buildGetConnectionUrl(connection.project, connection.code),
    {},
    'DELETE'
);

export const addConnection = (connection) => postData(CONNECTIONS_API_ROUTE, connection);
