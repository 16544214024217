import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DataSourceOpsList from '../listing/DataSourceOpsList';
import ProjectAggregatedStatus from './ProjectAggregatedStatus';
import { ingestionAggregatedStatuses } from '../../shared/const';

const useStyles = makeStyles(() => ({
    heading: {
        textTransform: 'uppercase'
    }
}));

const ProjectOpsDetailsView = ({ projectDetails, onViewDataSourceDetails }) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h4" className={classes.heading}>
                Project Monitoring Details
            </Typography>
            <Divider />
            <ProjectAggregatedStatus
                projectName={projectDetails.projectName}
                projectStatus={projectDetails.status}
            />
            <Typography variant="h6" gutterBottom noWrap>
                Data Sources
            </Typography>
            <DataSourceOpsList
                data={projectDetails.dataSources}
                onViewDetails={onViewDataSourceDetails}
            />
        </>
    );
};

ProjectOpsDetailsView.propTypes = {
    projectDetails: PropTypes.shape({
        status: PropTypes.oneOf(ingestionAggregatedStatuses).isRequired,
        projectName: PropTypes.string.isRequired,
        projectCode: PropTypes.string.isRequired,
        dataSources: PropTypes.arrayOf(PropTypes.shape({
            dataSourceName: PropTypes.string.isRequired,
            providerCode: PropTypes.string.isRequired,
            status: PropTypes.oneOf(ingestionAggregatedStatuses).isRequired,
            key: PropTypes.shape({
                dataSourceCode: PropTypes.string.isRequired,
                projectCode: PropTypes.string.isRequired
            }),
            lastRun: PropTypes.string
        }))
    }).isRequired,
    onViewDataSourceDetails: PropTypes.func
};

ProjectOpsDetailsView.defaultProps = {
    onViewDataSourceDetails: () => {}
};

export default ProjectOpsDetailsView;
