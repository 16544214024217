import React from 'react';
import { useParams } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import DataTable from '../../shared/components/DataTable';
import { useProjectDataSourcesListingApi } from '../hooks';

const columns = [
    {
        id: 'code',
        label: 'Data Source Code',
        minWidth: 170,
        align: 'center'
    },
    {
        id: 'connection',
        label: 'Connection Code',
        minWidth: 170
    },
    {
        id: 'provider',
        label: 'Provider Code',
        minWidth: 170
    },
];

const ProjectDataSourceList = ({ add, details }) => {
    const { projectCode } = useParams();
    const [{
        isLoading,
        isError,
        data
    }] = useProjectDataSourcesListingApi(projectCode);

    useErrorToast(isError, 'Failed to load data sources');

    const handleDetails = (row) => details(row);

    return (
        <>
            {isLoading && <LinearProgress role="progressbar" />}
            <DataTable
                columns={columns}
                disablePagination
                rows={data}
                add={add}
                details={handleDetails}
                tableName="project-datasources"
            />
        </>
    );
};

export default ProjectDataSourceList;
