import { getText } from './http';
import { buildStsUrl } from './stsApi';

class TeamApi {
    constructor(urlPart) {
        this.urlPart = urlPart;
    }

    getUrlSuffix(seasonId, teamId) {
        return `${this.urlPart}/${teamId}_${seasonId}`;
    }

    getUrl(connection, seasonId, teamId) {
        return `${buildStsUrl(connection)}${this.getUrlSuffix(seasonId, teamId)}`;
    }

    getXml(connection, seasonId, teamId, setXml) {
        const url = `/api/sts-mls/sts?stsUrl=${encodeURIComponent(this.getUrl(connection, seasonId, teamId))}`;
        return getText(url, setXml);
    }
}

export const rosterApi = new TeamApi('-01.05-BaseData-Individuals_Players');
export const teamOfficialsApi = new TeamApi('-01.05-BaseData-Individuals_Teamofficials');
