import React from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import Connections from '../connections';
import Projects from '../projects';
import Destinations from '../destinations';
import Operations from '../operations';
import OperationalTool from '../operational-tool';
import Apis from '../apis';
import LoggingMonitor from '../logging-monitor';
import LoggingDetails from '../logging-monitor/loggingDetails';

export const ROUTES_URLS = {
    CONNECTIONS: '/connections',
    PROJECTS: '/projects',
    DATA_SOURCES: '/data-sources',
    DESTINATIONS: '/destinations',
    OPERATIONS: '/operations',
    OPERATIONAL_TOOL: '/operational-tool',
    LOGS: '/logs',
    LOG_DETAILS: '/log-details',
    APIS: '/apis'
};

function Routes() {
    return (
        <Switch>
            <Route path={ROUTES_URLS.PROJECTS}>
                <Projects />
            </Route>
            <Route path={ROUTES_URLS.CONNECTIONS}>
                <Connections />
            </Route>
            <Route path={ROUTES_URLS.DESTINATIONS}>
                <Destinations />
            </Route>
            <Route path={ROUTES_URLS.OPERATIONS}>
                <Operations />
            </Route>
            <Route path={ROUTES_URLS.OPERATIONAL_TOOL}>
                <OperationalTool />
            </Route>
            <Route path={ROUTES_URLS.APIS}>
                <Apis />
            </Route>
            <Route path={ROUTES_URLS.LOGS}>
                <LoggingMonitor />
            </Route>
            <Route path={ROUTES_URLS.LOG_DETAILS}>
                <LoggingDetails />
            </Route>
            <Route exact path="/">
                <Redirect to={ROUTES_URLS.PROJECTS} />
            </Route>
        </Switch>
    );
}

export default Routes;
