import React from 'react';
import { useParams } from 'react-router-dom';
import GoBackButton from '../../shared/components/GoBackButton';
import DataSourceForm from './DataSourceForm';

import { useErrorToast } from '../../shared/toast/ToastProvider';
import { useAddDataSourceApi } from '../hooks';
import { useProvidersList } from '../../providers/hooks';

const newDataSource = {
    code: '',
    project: '',
    connection: '',
    provider: ''
};

const AddProjectDataSource = ({ onComplete, goBack }) => {
    const { projectCode } = useParams();
    const [{
        isSaving,
        isError: isSaveError,
        error: saveError
    }, onSaveHandler] = useAddDataSourceApi(onComplete);
    const [{
        isLoading: isProvidersLoading,
        isError: isLoadProvidersError,
        error: loadProvidersError,
        data: providers
    }] = useProvidersList();

    const isLoading = isSaving || isProvidersLoading;
    const isError = isSaveError || isLoadProvidersError;
    const providersSelectList = providers.map((provider) => ({
        key: provider.code,
        value: provider.name
    }));

    useErrorToast(isSaveError, saveError);
    useErrorToast(isLoadProvidersError, loadProvidersError);

    return (
        <>
            <DataSourceForm
                isNew
                isLoading={isLoading}
                isError={isError}
                isSaving={isSaving}
                dataSource={{ ...newDataSource, project: projectCode }}
                providers={providersSelectList}
                onSubmit={onSaveHandler}
                onCancel={goBack}
            />
            <GoBackButton goBack={goBack} text="go to listing" />
        </>
    );
};

export default AddProjectDataSource;
