import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import LinearProgress from '@material-ui/core/LinearProgress';
import GreenSwitch from '../../shared/components/GreenSwitch';

const useStyles = makeStyles(() => ({
    saveProgress: {
        color: green[500],
        minWidth: 90,
    }
}));

const SwitchToggle = ({
    isPropertyEnabled,
    onChange,
    isSaving,
    propName
}) => {
    const classes = useStyles();
    return (
        <Grid container alignItems="center">
            {
                isSaving && (
                    <Grid item>
                        <LinearProgress className={classes.saveProgress} />
                    </Grid>
                )
            }
            <Grid item className={classes.switch}>
                <GreenSwitch
                    checked={isPropertyEnabled}
                    onChange={onChange}
                    disabled={isSaving}
                    inputProps={{ 'aria-label': 'isPropertyEnabled', title: `switch ${propName}` }}
                />
            </Grid>
        </Grid>
    );
};

export default SwitchToggle;

SwitchToggle.propTypes = {
    isPropertyEnabled: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    propName: PropTypes.string.isRequired
};
