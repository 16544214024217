import Box from '@material-ui/core/Box';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDestinationsListingApi, useProjectDestinationsListingApi } from '../hooks';
import DestinationsTable from './DestinationTable';
import { useErrorToast } from '../../shared/toast/ToastProvider';

const DestinationsList = ({
    addDestination,
    destinationDetails,
    project,
    columns
}) => {
    const [{
        data,
        isLoading,
        isError,
        totalCount,
        pageSize,
        currentPage
    }, setPageSize, setPage] = project
        ? useProjectDestinationsListingApi(project)
        : useDestinationsListingApi();
    useErrorToast(isError, 'Failed to load destinations');

    return (
        <Box>
            <Typography variant="h4" gutterBottom>DESTINATIONS</Typography>
            {isLoading && <LinearProgress role="progressbar" />}
            <DestinationsTable
                rows={data}
                page={currentPage}
                rowsPerPage={pageSize}
                setPage={setPage}
                setRowsPerPage={setPageSize}
                totalCount={totalCount}
                disablePagination={!!project}
                add={addDestination}
                details={destinationDetails}
                columns={columns}
                tableName={project ? 'project-destinations' : 'destinations'}
            />
        </Box>
    );
};

export default DestinationsList;
