import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import React from 'react';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Dialog = ({
    children,
    open,
    onConfirm,
    onCancel,
    title = 'Confirm deletion',
    cancelText = 'Cancel',
    confirmText = 'Delete',
    confirmColor = 'secondary'
}) => (
    <MuiDialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
    >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {children}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel} color="primary">
                {cancelText}
            </Button>
            <Button onClick={onConfirm} color={confirmColor} autoFocus variant="contained">
                {confirmText}
            </Button>
        </DialogActions>
    </MuiDialog>
);

Dialog.propTypes = {
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.node.isRequired
    ]).isRequired,
    title: PropTypes.string,
    confirmColor: PropTypes.string
};

Dialog.defaultProps = {
    cancelText: 'Cancel',
    confirmText: 'OK',
    title: '',
    confirmColor: 'secondary'
};

export default Dialog;
