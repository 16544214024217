export const findLocaleName = (localeCode, locales) => {
    const locale = locales.find((l) => l.code.toLowerCase() === localeCode.toLowerCase());
    if (!locale || !locale.localeName) {
        return '';
    }

    return locale.localeName;
};

export const buildLocalesRows = (selectedLocales, defaultLocale, locales) => {
    const localesRows = selectedLocales.map((locale) => ({
        code: locale,
        isDefault: defaultLocale === locale,
        name: findLocaleName(locale, locales)
    }));
    localesRows.sort((a, b) => {
        if (a.isDefault) {
            return -1;
        }
        if (b.isDefault) {
            return 1;
        }

        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });

    return localesRows;
};
