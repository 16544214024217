import { getText } from './http';
import { buildStsUrl } from './stsApi';

class StandingsApi {
    constructor(urlPart) {
        this.urlPart = urlPart;
    }

    getUrlSuffix(competitionId) {
        return `${this.urlPart}/${competitionId}`;
    }

    getUrl(connection, competitionId) {
        return `${buildStsUrl(connection)}${this.getUrlSuffix(competitionId)}`;
    }

    getXml(connection, competitionId, setXml) {
        const url = `/api/sts-mls/sts?stsUrl=${encodeURIComponent(this.getUrl(connection, competitionId))}`;
        return getText(url, setXml);
    }
}

const standingsApi = new StandingsApi('-05.01-Tables');

export default standingsApi;
