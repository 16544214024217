import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const TabContent = ({ tabIndex, children }) => (
    <div
        role="tabpanel"
        id={`nav-tabpanel-${tabIndex}`}
        aria-labelledby={`nav-tab-${tabIndex}`}
    >
        <Box p={3}>
            {children}
        </Box>
    </div>
);

TabContent.propTypes = {
    tabIndex: PropTypes.number,
    children: PropTypes.node,
};

TabContent.defaultProps = {
    tabIndex: 0,
    children: null,
};

export default TabContent;
