import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { toRelativeTimeString } from '../../utils/datetime-util';

const styles = (theme) => createStyles({
    relTime: {
        '&::before': {
            content: '","',
            paddingRight: theme.spacing(0.5)
        }
    }
});

const DataSetConfigMonitoringStatus = ({
    dataSourceStatus,
    dataSetId,
    isLoading,
    classes
}) => {
    if (isLoading) {
        return <Skeleton animation="wave" width="200px" />;
    }

    const statuses = dataSourceStatus.dataSetConfigs || [];
    const dataSetStatus = statuses.find((status) => status.dataSetConfigId === Number(dataSetId));
    if (!dataSetStatus || !dataSetStatus.lastRunInfo) {
        return <span>UNEXECUTED</span>;
    }

    return (
        <>
            <span>{dataSetStatus.lastRunInfo.status}</span>
            {dataSetStatus.lastRunInfo.timeStamp && (
                <span className={classes.relTime}>
                    {toRelativeTimeString(dataSetStatus.lastRunInfo.timeStamp)}
                </span>
            )}
        </>
    );
};

export default withStyles(styles)(DataSetConfigMonitoringStatus);
