import React from 'react';
import SnackbarAlert from './SnackbarAlert';

const ToastConsumer = ({ state, handleClose }) => {
    const { show, message, severity } = state;

    return (
        <SnackbarAlert
            open={show}
            text={message}
            severity={severity}
            handleClose={handleClose}
        />
    );
};

export default ToastConsumer;
