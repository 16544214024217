import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory
} from 'react-router-dom';
import AddProjectConnection from '../details/AddProjectConnection';
import ProjectConnectionDetail from '../details/ProjectConnectionDetails';
import { useShowToastContext } from '../../shared/toast/ToastProvider';
import ProjectConnectionsList from '../listing/ProjectConnectionsList';

const ADD_ROUTE = 'add-connection';

const ProjectConnections = () => {
    const { path, url } = useRouteMatch();
    const showAlert = useShowToastContext();

    const history = useHistory();

    const handleAdd = () => {
        history.push(`${url}/${ADD_ROUTE}`);
    };

    const handleDetails = (model) => {
        history.push(`${url}/${model.code}`);
    };

    const goToListing = () => {
        history.push(url);
    };

    const handleEditCompleted = (isDelete) => {
        const message = isDelete ? 'Deleted successfully' : 'Saved successfully';
        showAlert(message, false);
        goToListing();
    };

    return (
        <Switch>
            <Route exact path={path}>
                <ProjectConnectionsList
                    add={handleAdd}
                    details={handleDetails}
                />
            </Route>
            <Route path={`${path}/${ADD_ROUTE}`}>
                <AddProjectConnection goBack={goToListing} onComplete={handleEditCompleted} />
            </Route>
            <Route path={`${path}/:connectionCode`}>
                <ProjectConnectionDetail
                    goBack={goToListing}
                    onComplete={handleEditCompleted}
                />
            </Route>
        </Switch>
    );
};

export default ProjectConnections;
