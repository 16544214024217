import { useGetApi, useSaveApi } from '../shared/hooks/fetchingHooks';
import {
    buildGetProjectDataSourcesUrl,
    buildProjectDataSourcesBaseUrl,
    editDataSource,
    addDataSource,
    deleteDataSource,
    addDataSetConfig,
    deleteDataSetConfig,
    updateDataSetConfigSchedule,
    updateDataSetConfigProperties,
    toggleDataSetConfigSchedule,
    toggleDataSetConfigLiveMode,
    updateDataSetConfigInfo
} from './api';

export const useProjectDataSourcesListingApi = (project) => useGetApi(
    buildProjectDataSourcesBaseUrl(project), []
);

export const useGetDataSourceApi = (projectCode, dataSourceCode) => {
    const startingUrl = buildGetProjectDataSourcesUrl(projectCode, dataSourceCode);

    const [state, setUrl, forceReload] = useGetApi(startingUrl, { code: '', connection: '', provider: '' });
    const dataSourceCodeUpdateHandler = (newProjectCode, newDataSourceCode) => setUrl(
        buildGetProjectDataSourcesUrl(
            newProjectCode,
            newDataSourceCode
        )
    );

    return [state, dataSourceCodeUpdateHandler, forceReload];
};

export const useEditDataSourceApi = (onComplete) => useSaveApi(editDataSource, onComplete);

export const useAddDataSourceApi = (onComplete) => useSaveApi(addDataSource, onComplete);

export const useDeleteDataSourceApi = (onComplete) => useSaveApi(deleteDataSource, onComplete);

export const useAddDataSetConfigApi = (onComplete) => useSaveApi(addDataSetConfig, onComplete);

export const useUpdateDataSetConfigScheduleApi = (onComplete) => useSaveApi(
    updateDataSetConfigSchedule,
    onComplete
);

export const useDeleteDataSetConfigApi = (onComplete) => useSaveApi(
    deleteDataSetConfig,
    onComplete
);

export const useUpdateDataSetConfigPropertiesApi = (onComplete) => useSaveApi(
    updateDataSetConfigProperties,
    onComplete
);

export const useToggleDataSetConfigScheduleApi = (onComplete) => useSaveApi(
    toggleDataSetConfigSchedule,
    onComplete
);

export const useToggleDataSetConfigLiveModeApi = (onComplete) => useSaveApi(
    toggleDataSetConfigLiveMode,
    onComplete
);

export const useUpdateDataSetConfigInfoApi = (onComplete) => useSaveApi(
    updateDataSetConfigInfo,
    onComplete
);
