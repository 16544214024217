import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import ReactJson from 'react-json-view';
import {
    Paper,
    Typography,
    Grid,
    Divider,
    Container
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GoBackButton from '../../shared/components/GoBackButton';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    heading: {
        textTransform: 'uppercase',
    },
    mainInfo: {
        padding: theme.spacing(2),
    },
}));

function LoggingDetails() {
    const location = useLocation();
    const { data } = location.state;
    const history = useHistory();

    const handleGoBack = () => {
        history.goBack();
    };

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const classes = useStyles();

    return (
        <Container>
            <Paper className={classes.root}>
                <Typography variant="h4" className={classes.heading}>
                    Logging Details
                </Typography>
                <Divider />
                <Grid
                    direction="column"
                    alignItems=""
                    container
                    justifyContent="center"
                >
                    {data && isJsonString(data) && (
                        <Grid className={classes.mainInfo}>
                            <Typography variant="h5">Data recieved</Typography>
                            <Divider />
                            <ReactJson src={JSON.parse(data)} />
                        </Grid>
                    )}
                </Grid>
            </Paper>
            <GoBackButton goBack={handleGoBack} text="back to logs" />
        </Container>
    );
}

export default LoggingDetails;
