import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

import {
    ingestionAggregatedStatuses,
    ingestionStatusesThemeColorMap,
    ingestionStatusesLabelsMap,
    defaultIngestionStatus
} from '../const';

const getColorScheme = (status, palette) => {
    const paletteName = ingestionStatusesThemeColorMap[status];
    const colors = palette[paletteName];

    if (!colors) {
        console.error(`Cannot find colors for status: ${status}`);
        return {
            color: palette.primary.main,
            borderColor: palette.primary.main,
        };
    }

    return {
        color: colors.main,
        borderColor: colors.main,
    };
};

const IngestionStatusChip = ({ status }) => {
    const ColoredChip = withStyles(({ palette }) => ({
        root: getColorScheme(status, palette)
    }))((props) => <Chip variant="outlined" {...props} />);

    return (<ColoredChip label={ingestionStatusesLabelsMap[status]} />);
};

IngestionStatusChip.propTypes = {
    status: PropTypes.oneOf(ingestionAggregatedStatuses)
};

IngestionStatusChip.defaultProps = {
    status: defaultIngestionStatus
};

export default IngestionStatusChip;
