import React from 'react';
import * as PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import LinkTab from './LinkTab';
import { TABS_ROUTES, getTabIndex } from '../const';

const TabsBar = ({
    rootUrl,
    onChange,
    value,
}) => (
    <AppBar position="static">
        <Tabs
            variant="fullWidth"
            value={value}
            onChange={onChange}
            aria-label="nav tab"
        >
            <LinkTab
                tabIndex={0}
                label="Detail"
                to={`${rootUrl}/${TABS_ROUTES.DETAILS}`}
            />
            <LinkTab
                tabIndex={getTabIndex(TABS_ROUTES.CONNECTIONS)}
                label="Connections"
                to={`${rootUrl}/${TABS_ROUTES.CONNECTIONS}`}
            />
            <LinkTab
                tabIndex={getTabIndex(TABS_ROUTES.DATA_SOURCES)}
                label="Data Sources"
                to={`${rootUrl}/${TABS_ROUTES.DATA_SOURCES}`}
            />
            {
            // Hide Destinations for the time being
            /* <LinkTab
                tabIndex={getTabIndex(TABS_ROUTES.DESTINATIONS)}
                label="Destinations"
                to={`${rootUrl}/${TABS_ROUTES.DESTINATIONS}`}
            /> */
            }
        </Tabs>
    </AppBar>
);

TabsBar.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    rootUrl: PropTypes.string.isRequired,
};

export default TabsBar;
