import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const SectionHeader = ({ headerText }) => (
    <>
        <Typography variant="h6">{headerText}</Typography>
        <Divider />
    </>
);

export default SectionHeader;
