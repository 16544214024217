import React from 'react';
import Tab from '@material-ui/core/Tab';
import { Link as RouterLink } from 'react-router-dom';

const a11yProps = (index) => ({
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
});

const LinkTab = (props) => {
    const { to, tabIndex, label } = props;
    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return (
        <Tab
            label={label}
            component={renderLink}
            {...a11yProps(tabIndex)}
        />
    );
};

export default LinkTab;
