import { XMLParser } from 'fast-xml-parser';
import { error, requested, succeeded } from '../async';

export async function getXml(url, propertyName, setResult) {
    setResult(requested());
    try {
        const result = await fetch(url);
        if (result.ok) {
            const text = await result.text();
            const parser = new XMLParser({ ignoreAttributes: false, attributeNamePrefix: '' });
            const parsed = parser.parse(text);
            setResult(succeeded(parsed.PutDataRequest[propertyName]));
            return;
        }
        setResult(error(result.statusText));
    } catch (caughtError) {
        setResult(error((caughtError).message));
    }
}

export async function getJson(url, setResult) {
    setResult(requested());
    try {
        const result = await fetch(url);
        if (result.ok) {
            const json = await result.json();
            setResult(succeeded(json));
            return;
        }
        setResult(error(result.statusText));
    } catch (caughtError) {
        setResult(error((caughtError).message));
    }
}

export async function getText(url, setResult) {
    setResult(requested());
    try {
        const result = await fetch(url);
        if (result.ok) {
            const text = await result.text();
            setResult(succeeded(text));
            return;
        }
        setResult(error(result.statusText));
    } catch (caughtError) {
        setResult(error((caughtError).message));
    }
}
