import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const entityCodeRule = yup
    .string()
    .min(3, 'Minimum 3 characters short')
    .max(20, 'Maximum 20 characters long')
    .matches(
        /^([a-z|_|0-9|\\-])+$/,
        'Code can only contain letters, numbers, -, _'
    )
    .required('Can\'t be empty');
