import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Redirect,
    Route,
    Switch,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import ProjectConnections from '../../connections/project-tab';
import ProjectDataSources from '../../data-sources/project-tab';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import EditProject from '../edit/EditProject';
import { useGetProjectApi } from '../hooks';
import CurrentTabDescription from './CurrentTabDescription';
import TabContent from './TabContent';
import TabsBar from './TabsBar';
// import ProjectDestinations from '../../destinations/project-tabs/index';
import { TABS_ROUTES, getTabIndex } from '../const';
import Breadcrumbs from '../../shared/components/Breadcrumbs';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export const NavigationTabs = ({
    projectUrl,
    projectPath,
    goBack,
    onComplete,
}) => {
    const classes = useStyles();
    const { projectTab: projectTabParam, projectCode } = useParams();
    const [{
        isLoading,
        isError: isLoadError,
        data: project,
        error: errorMessage
    }] = useGetProjectApi(projectCode);
    useErrorToast(isLoadError, errorMessage);

    const [tabIndex, setTabIndex] = React.useState(0);
    React.useEffect(() => {
        const index = getTabIndex(projectTabParam);
        setTabIndex(index);
    }, [projectTabParam]);

    const handleChange = () => {
        // handled by route effect above to avoid double render;
    };

    if (isLoading) {
        return <LinearProgress color="secondary" />;
    }

    return (
        <>
            <Breadcrumbs />
            <CurrentTabDescription project={project} />
            <div className={classes.root}>
                <TabsBar value={tabIndex} onChange={handleChange} rootUrl={projectUrl} />
                <TabContent tabIndex={tabIndex}>
                    <Switch>
                        <Route path={`${projectPath}/${TABS_ROUTES.DETAILS}`}>
                            <EditProject
                                goBack={goBack}
                                onComplete={onComplete}
                                project={project}
                            />
                        </Route>
                        <Route path={`${projectPath}/${TABS_ROUTES.CONNECTIONS}`}>
                            <ProjectConnections />
                        </Route>
                        <Route path={`${projectPath}/${TABS_ROUTES.DATA_SOURCES}`}>
                            <ProjectDataSources />
                        </Route>
                        {/*  <Route path={`${projectPath}/${TABS_ROUTES.DESTINATIONS}`}>
                            <ProjectDestinations />
                        </Route> */}
                    </Switch>
                </TabContent>
            </div>
        </>
    );
};

const NavigationTabsRouter = ({ goBack, onComplete }) => {
    // PATH: /projects/:projectCode
    // URL: /projects/uefa
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/:projectTab`}>
                <NavigationTabs
                    projectUrl={url}
                    projectPath={path}
                    goBack={goBack}
                    onComplete={onComplete}
                />
            </Route>
            <Route>
                <Redirect to={`${url}/${TABS_ROUTES.DETAILS}`} />
            </Route>
        </Switch>
    );
};

export default NavigationTabsRouter;
