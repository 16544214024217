import React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import AddLocale from './AddLocale';
import { buildLocalesRows } from './helpers';
import withLocales from './hoc';
import LocaleRow from './LocaleRow';
import SectionHeader from '../../shared/components/SectionHeader';

const LocalesList = ({
    locales,
    localesSelectList,
    selectedLocales,
    defaultLocale,
    onLocalesChange,
    onDefaultLocaleChange
}) => {
    const addLocale = (locale) => onLocalesChange([...selectedLocales, locale]);
    const removeLocale = (locale) => {
        const newLocales = selectedLocales.filter((l) => l.toLowerCase() !== locale.toLowerCase());
        onLocalesChange(newLocales);
    };
    const setDefaultLocale = (locale) => onDefaultLocaleChange(locale);

    const availableLocales = localesSelectList
        .filter((l) => !selectedLocales
            .some((selected) => selected.toLowerCase() === l.key.toLowerCase()));

    const localesRows = buildLocalesRows(selectedLocales, defaultLocale, locales);

    return (
        <>
            <List subheader={<SectionHeader headerText="Locales" />} disablePadding>
                {
                    localesRows.map((locale) => (
                        <LocaleRow
                            key={locale.code}
                            locale={locale}
                            setDefaultLocale={setDefaultLocale}
                            removeLocale={removeLocale}
                            selectedLocalesLength={localesRows.length}
                        />
                    ))
                }
            </List>
            <Divider />
            <AddLocale
                locales={availableLocales}
                onAddLocale={addLocale}
            />
        </>
    );
};

export default withLocales(LocalesList);
