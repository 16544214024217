import asArray from '../arrayOrObject';
import { convert } from '../async';
import { getXml } from './http';

export function buildStsUrl(connection) {
    const {
        clientId,
        serviceUrl,
        feedNamePrefix
    } = connection.jsonProperties;

    return `${serviceUrl}/PullOnce/${clientId}/${feedNamePrefix || 'Feed'}`;
}

export function getCompetitions(connection, setCompetions) {
    const stsUrl = `${buildStsUrl(connection)}-01.01-BaseData-Competition`;
    return getXml(`/api/sts-mls/sts?stsUrl=${encodeURIComponent(stsUrl)}`, 'Competitions', (result) => {
        const competitions = convert(result, (source) => asArray(source.Competition));
        setCompetions(competitions);
    });
}

export function getSeasons(connection, competitionId, setSeasons) {
    const stsUrl = `${buildStsUrl(connection)}-01.07-BaseData-Season/${competitionId}`;
    return getXml(`/api/sts-mls/sts?stsUrl=${encodeURIComponent(stsUrl)}`, 'Seasons', (result) => {
        const seasons = convert(result, (source) => asArray(source.Season));
        setSeasons(seasons);
    });
}
