import React from 'react';
import AsyncSelect from '../shared/components/asyncSelect';
import { notRequested } from '../shared/async';
import { getStsConnections } from '../shared/api/adminApis';

export default function ConnectionSelect(props) {
    const { projectId, connection, setConnection } = props;

    const [connections, setConnections] = React.useState(notRequested());

    React.useEffect(() => {
        if (projectId) {
            getStsConnections(projectId, setConnections);
        }
    }, [projectId, setConnections]);

    const setConnectionCode = (code) => {
        if (!code) {
            return;
        }
        const newConnection = connections.data.find((c) => c.code === code);
        setConnection(newConnection);
    };

    return (
        <AsyncSelect
            result={connections}
            id={connection?.code}
            setId={setConnectionCode}
            getIdFromItem={(s) => s.code}
            getNameFromItem={(s) => s.code}
        />
    );
}
