import React from 'react';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import { useAddProjectApi } from '../hooks';
import ProjectForm from './ProjectForm';

const newProject = {
    name: '',
    code: '',
    description: '',
    defaultLocale: 'en-US',
    strapiConfig: {
        url: '',
        token: ''
    },
    locales: ['en-US']
};

function AddProject({ goBack, onComplete }) {
    const [{
        isSaving,
        isError: isSaveError,
        error: saveError,
    }, onSaveHandler] = useAddProjectApi(onComplete);

    useErrorToast(isSaveError, saveError);

    return (
        <ProjectForm
            isNew
            project={newProject}
            onSubmit={onSaveHandler}
            onCancel={goBack}
            isLoading={isSaving}
            isError={isSaveError}
        />
    );
}

export default AddProject;
