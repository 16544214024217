import asArray from '../arrayOrObject';
import { convert } from '../async';
import { getXml, getText } from './http';
import { buildStsUrl } from './stsApi';

class SeasonApi {
    constructor(urlPart, rootPropertyName, getSourceData, map, competitionIdFirst) {
        this.urlPart = urlPart;
        this.rootPropertyName = rootPropertyName;
        this.getSourceData = getSourceData;
        this.map = map;
        this.competitionIdFirst = competitionIdFirst;
    }

    getUrlSuffix(competitionId, seasonId) {
        return this.competitionIdFirst
            ? `${this.urlPart}/${competitionId}_${seasonId}`
            : `${this.urlPart}/${seasonId}_${competitionId}`;
    }

    getUrl(connection, competitionId, seasonId) {
        return `${buildStsUrl(connection)}${this.getUrlSuffix(competitionId, seasonId)}`;
    }

    getXml(connection, competitionId, seasonId, setXml) {
        const url = `/api/sts-mls/sts?stsUrl=${encodeURIComponent(this.getUrl(connection, competitionId, seasonId))}`;
        return getText(url, setXml);
    }

    getData(connection, competitionId, seasonId, setData) {
        const url = `/api/sts-mls/sts?stsUrl=${encodeURIComponent(this.getUrl(connection, competitionId, seasonId))}`;
        return getXml(url, this.rootPropertyName, (result) => {
            const data = convert(result,
                (source) => asArray(this.getSourceData(source)).map(this.map));
            setData(data);
        });
    }
}

export const matchApi = new SeasonApi('-01.06-BaseData-Schedule', 'Fixtures', (s) => s.Fixture, (m) => ({
    ...m,
    PlannedKickoffTime: new Date(m.PlannedKickoffTime),
    PlannedKickOffTimeCustom: new Date(m.PlannedKickOffTimeCustom)
}), false);

export const teamApi = new SeasonApi('-01.04-BaseData-Clubs', 'Clubs', (s) => s.Club, (m) => m, false);

export const seasonStatsApi = new SeasonApi('-05.02-EventData-Season-Statistics_Competition', 'SeasonStatistic', (s) => s.SeasonStatistic, (m) => m, true);

export const seasonPositionalStatsApi = new SeasonApi('-05.03-PositionalData-Season-Statistics_Competition', 'SeasonStatistic', (s) => s.SeasonStatistic, (m) => m, true);
