import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    LinearProgress,
    Paper
} from '@material-ui/core';
import { useErrorToast } from '../shared/toast/ToastProvider';
import { useSearchByTextAndFilterApi } from './hooks';

export default function OperationsToolMvp() {
    // todo: use formic
    const [searchText, setSearchText] = useState('');
    const [provider, setProvider] = useState('');
    const [sport, setSport] = useState('');
    const [entityType, setEntityType] = useState('');
    const [{
        data,
        isLoading,
        isError,
    }, searchHandler] = useSearchByTextAndFilterApi(searchText, { provider, sport, entityType });

    const handleSearch = () => {
        searchHandler(searchText, { provider, sport, entityType });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        handleSearch();
    };

    const clearFilters = () => {
        setSearchText('');
        setProvider('');
        setSport('');
        setEntityType('');
    };

    useErrorToast(isError, 'Failed to load operations');
    return (
        <>
            <Typography variant="h4" gutterBottom>
                Operational Tool
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                >
                    <Grid item>
                        <TextField
                            id="searchText"
                            disabled={isLoading}
                            label="Text to search"
                            helperText="Required. Case insensitive. Part of any id"
                            margin="normal"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="provider"
                            label="Provider"
                            helperText="Case sensitive. e.g. opta"
                            margin="normal"
                            disabled={isLoading}
                            value={provider}
                            onChange={(e) => setProvider(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="sport"
                            label="Sport"
                            helperText="Case sensitive. e.g. Football"
                            margin="normal"
                            disabled={isLoading}
                            value={sport}
                            onChange={(e) => setSport(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="entityType"
                            label="Entity Type"
                            helperText="Case sensitive. e.g. Person"
                            margin="normal"
                            disabled={isLoading}
                            value={entityType}
                            onChange={(e) => setEntityType(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Button name="cancel" variant="contained" onClick={clearFilters} disabled={isLoading}>
                            Clear filters
                        </Button>
                        <Button
                            type="submit"
                            name="search"
                            variant="contained"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disabled={isLoading}
                            onSubmit={handleSubmit}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {isLoading && <LinearProgress role="progressbar" />}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Provider Id</TableCell>
                            <TableCell>Platform Id</TableCell>
                            <TableCell align="right">Provider</TableCell>
                            <TableCell align="right">Entity Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row.providerId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.providerId}
                                </TableCell>
                                <TableCell>{row.platformId}</TableCell>
                                <TableCell align="right">{row.provider}</TableCell>
                                <TableCell align="right">{row.entityType}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
