import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { useProjectsListingApi } from '../hooks';
import ProjectsTable from './ProjectsTable';
import { useErrorToast } from '../../shared/toast/ToastProvider';

function ProjectsList({ addProject, projectDetails }) {
    const [{
        data,
        isLoading,
        isError,
        totalCount,
        pageSize,
        currentPage,
    }, setPageSize, setPage] = useProjectsListingApi();
    useErrorToast(isError, 'Failed to load projects');

    const handleProjectDetails = (row) => projectDetails(row.code);

    return (
        <>
            <Typography variant="h4" gutterBottom>PROJECTS</Typography>
            {isLoading && <LinearProgress role="progressbar" />}
            <ProjectsTable
                rows={data}
                page={currentPage}
                rowsPerPage={pageSize}
                setPage={setPage}
                setRowsPerPage={setPageSize}
                totalCount={totalCount}
                add={addProject}
                details={handleProjectDetails}
            />
        </>
    );
}

export default ProjectsList;
