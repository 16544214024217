import { getText } from './http';

class GroupsApi {
    /* eslint-disable class-methods-use-this */
    getUrlSuffix(projectId, seasonId, stageId) {
        return `v1/${projectId}/football/seasons/${seasonId}/stages/${stageId}/groups`;
    }

    getUrl(connection, seasonId, projectId) {
        return `${this.connection}/${this.getUrlSuffix(seasonId, projectId)}`;
    }

    getJson(projectId, seasonId, stageId, setJson) {
        const url = `/api/sts-mls/b2c?b2cUrl=${encodeURIComponent(this.getUrlSuffix(projectId, seasonId, stageId))}`;
        return getText(url, setJson);
    }
}

const getGroupsApi = new GroupsApi();

export default getGroupsApi;
