const ID_MAPPING_API_ROUTE = '/api/operational-tool/id-mapping';

function buildFindMappingByTextAndFilterUrl(searchText, { provider, sport, entityType }) {
    if (!searchText) {
        return '';
    }

    let baseUrl = `${ID_MAPPING_API_ROUTE}/${searchText}?`;

    // todo: use some lib for query params
    if (provider) {
        baseUrl += `provider=${provider}&`;
    }

    if (sport) {
        baseUrl += `sport=${sport}&`;
    }

    if (entityType) {
        baseUrl += `entityType=${entityType}&`;
    }

    return baseUrl.slice(0, -1);
}

export default buildFindMappingByTextAndFilterUrl;
