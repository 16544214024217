import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory
} from 'react-router-dom';
import AddProjectDataSource from '../edit/AddProjectDataSource';
import ProjectDataSourceDetails from '../edit/ProjectDataSourceDetails';
import ProjectDataSourcesList from '../listing/ProjectDataSourcesList';
import { useShowToastContext } from '../../shared/toast/ToastProvider';

const ADD_ROUTE = 'add-datasource';

const ProjectDataSources = () => {
    const { path, url } = useRouteMatch();
    const showAlert = useShowToastContext();

    const history = useHistory();

    const handleAdd = () => {
        history.push(`${url}/${ADD_ROUTE}`);
    };

    const handleDetails = (model) => {
        history.push(`${url}/${model.code}`);
    };

    const goToListing = () => {
        history.push(url);
    };

    const handleEditCompleted = (isDelete) => {
        const message = isDelete ? 'Deleted successfully' : 'Saved successfully';
        showAlert(message, false);
        goToListing();
    };

    return (
        <Switch>
            <Route exact path={path}>
                <ProjectDataSourcesList
                    add={handleAdd}
                    details={handleDetails}
                    onComplete={handleEditCompleted}
                />
            </Route>
            <Route path={`${path}/${ADD_ROUTE}`}>
                <AddProjectDataSource
                    onComplete={handleEditCompleted}
                    goBack={goToListing}
                />
            </Route>
            <Route path={`${path}/:dataSourceCode`}>
                <ProjectDataSourceDetails
                    onComplete={handleEditCompleted}
                    goBack={goToListing}
                />
            </Route>
        </Switch>
    );
};

export default ProjectDataSources;
