import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { withProviders, getSchema } from '../../providers/providers-hoc';
import DeleteButton from '../../shared/components/DeleteButton';
import Select from '../../shared/components/Select';
import JsonPropertiesForm from '../../shared/components/JsonPropertiesForm';
import { entityCodeRule } from '../../shared/form-validation';

const useStyles = makeStyles((theme) => ({
    buttonsGrid: {
        marginTop: theme.spacing(2)
    },
    buttons: {
        '& > button + button': {
            marginLeft: theme.spacing(2),
        },
    },
    propertiesForm: {
        marginTop: theme.spacing(3)
    }
}));

const validationSchema = yup.object({
    code: entityCodeRule,
    provider: yup
        .string()
        .required('Is required'),
    project: yup
        .string()
        .required('Is required'),
});

const ConnectionForm = ({
    isNew,
    model,
    onSubmit,
    onDelete,
    onCancel,
    isLoading,
    providersSelectList,
    providersList,
    isProjectTab
}) => {
    const [properties, setProperties] = React.useState(model.properties);
    const [connectionSchema, setSchema] = React.useState('{}');
    const [provider, setProvider] = React.useState(model.provider);
    React.useEffect(() => {
        const schema = getSchema(provider, providersList);
        setSchema(schema);
    }, [provider, providersList.length]);

    const formik = useFormik({
        initialValues: {
            code: model.code,
            provider: model.provider,
            project: model.project
        },
        validationSchema,
        validateOnChange: false,
        onSubmit: (values) => onSubmit({
            ...values,
            jsonProperties: JSON.stringify(properties)
        })
    });
    const classes = useStyles();

    const handleProviderChange = async (value) => {
        setProvider(value);
        await formik.setFieldValue('provider', value);
        await formik.setTouched({
            ...formik.touched,
            provider: true
        });
    };

    return (
        <>
            {isLoading && <LinearProgress color="secondary" role="progressbar" />}
            <form onSubmit={formik.handleSubmit}>
                <Typography variant="h4" gutterBottom noWrap>
                    {`${isNew ? 'ADD' : 'EDIT'} CONNECTION`}
                </Typography>
                <Grid container spacing={2}>
                    {!isProjectTab && (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="project"
                                label="Project Code"
                                margin="normal"
                                disabled={isLoading}
                                value={formik.values.project}
                                error={formik.touched.project && Boolean(formik.errors.project)}
                                helperText={formik.touched.project && formik.errors.project}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Select
                            disabled={isLoading || !isNew}
                            label="Provider"
                            margin="normal"
                            setValue={handleProviderChange}
                            value={formik.values.provider}
                            values={providersSelectList}
                            error={formik.touched.provider && Boolean(formik.errors.provider)}
                            helperText={formik.touched.provider && formik.errors.provider}
                            isLoading={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="code"
                            disabled={!isNew || isLoading}
                            label="Code"
                            margin="normal"
                            value={formik.values.code}
                            error={formik.touched.code && Boolean(formik.errors.code)}
                            helperText={formik.touched.code && formik.errors.code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Grid>
                </Grid>
                <JsonPropertiesForm
                    className={classes.propertiesForm}
                    schema={connectionSchema}
                    model={properties}
                    onChange={setProperties}
                    idPrefix="connection-properties"
                />
                <Grid container justifyContent="space-between" className={classes.buttonsGrid}>
                    {!isNew && (
                        <Grid item>
                            <DeleteButton
                                disabled={isLoading}
                                onDelete={onDelete}
                                itemName={`Connection ${formik.values.code}`}
                            />
                        </Grid>
                    )}
                    <Grid item className={classes.buttons}>
                        <Button name="cancel" variant="contained" onClick={onCancel} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            name="save"
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            disabled={isLoading}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default withProviders(ConnectionForm);
