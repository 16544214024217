import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useParams } from 'react-router-dom';
import BackdropProgress from '../../shared/components/BackdropProgress';
import withDataSets from './datasets-hoc';
import { useDataSetMetadata, useRunIngestionApi } from './hooks';
import DataSetForm from './DataSetForm';
import { useErrorToast, useShowToastContext } from '../../shared/toast/ToastProvider';
import {
    useUpdateDataSetConfigScheduleApi,
    useToggleDataSetConfigScheduleApi,
    useUpdateDataSetConfigPropertiesApi,
    useDeleteDataSetConfigApi,
    useUpdateDataSetConfigInfoApi,
    useToggleDataSetConfigLiveModeApi
} from '../hooks';
import { createSaveHandler } from './saveSectionsHelper';

const EditDataSetConfig = ({
    dataSource,
    back,
    onComplete,
    dataSets,
    isLoading,
    dataSourceStatus,
    isStatusLoading
}) => {
    const { dataSetConfigId } = useParams();
    const selectedConfig = dataSource.dataSetConfigs
        .find((config) => config.id === Number(dataSetConfigId)) || {};

    const dataSetsSelectList = React.useMemo(() => [{
        key: selectedConfig.name,
        value: selectedConfig.name
    }], [selectedConfig.name]);

    const dataSetMetadata = useDataSetMetadata(dataSets, selectedConfig.name);
    const showAlert = useShowToastContext();

    const [{
        isSaving: isRunning,
        isError: isRunError,
        error: runError
    }, onRunHandler] = useRunIngestionApi(() => {
        showAlert('Ingestion request has been send.', false);
    });

    const [{
        isSaving: isUpdatingSchedule,
        isError: isUpdateScheduleError,
        error: updateScheduleError
    }, onDataSetConfigScheduleUpdate] = useUpdateDataSetConfigScheduleApi(() => {
        showAlert('Schedule has been updated.', false);
    });

    const [{
        isSaving: isSwitchingSchedule,
        isError: isSwitchScheduleError,
        error: switchScheduleError
    }, handleSwitchSchedule] = useToggleDataSetConfigScheduleApi();

    const [{
        isSaving: isSwitchingLiveMode,
        isError: isSwitchLiveModeError,
        error: switchLiveModeError
    }, handleSwitchLiveMode] = useToggleDataSetConfigLiveModeApi();

    const [{
        isSaving: isUpdatingProperties,
        isError: isUpdatePropertiesError,
        error: updatePropertiesError
    }, onDataSetConfigPropertiesUpdate] = useUpdateDataSetConfigPropertiesApi(() => {
        showAlert('Data Set Properties has been updated.', false);
    });

    const [{
        isSaving: isUpdatingInfo,
        isError: isUpdateInfoError,
        error: updateInfoError
    }, onDataSetConfigInfoUpdate] = useUpdateDataSetConfigInfoApi(() => {
        showAlert('Data Set Config Info has been updated', false);
    });

    const [{
        isSaving: isDeleting,
        isError: isDeleteError,
        error: deleteError
    }, onDataSetConfigDelete] = useDeleteDataSetConfigApi(() => {
        onComplete(true);
    });

    const handleRunIngestion = async () => {
        await onRunHandler({
            datasetId: Number(dataSetConfigId),
            projectCode: dataSource.project,
            dataSourceCode: dataSource.code
        });
    };

    const handleSaveConfig = async (section, model, successCallback) => {
        const { project, code: dataSourceCode } = dataSource;
        const saveModel = { id: dataSetConfigId, project, dataSourceCode, };
        const save = createSaveHandler(saveModel, {
            updateProperties: onDataSetConfigPropertiesUpdate,
            updateSchedule: onDataSetConfigScheduleUpdate,
            toggleSchedule: handleSwitchSchedule,
            toggleLiveMode: handleSwitchLiveMode,
            updateInfo: onDataSetConfigInfoUpdate
        });

        await save(section, model, successCallback);
    };

    const handleDeleteConfig = async (id) => {
        const { project, code: dataSourceCode } = dataSource;
        await onDataSetConfigDelete({ project, dataSourceCode, id });
    };

    useErrorToast(isRunError, runError);
    useErrorToast(isUpdateScheduleError, updateScheduleError);
    useErrorToast(isUpdatePropertiesError, updatePropertiesError);
    useErrorToast(isSwitchScheduleError, switchScheduleError);
    useErrorToast(isSwitchLiveModeError, switchLiveModeError);
    useErrorToast(isDeleteError, deleteError);
    useErrorToast(isUpdateInfoError, updateInfoError);

    if (isLoading) {
        return <LinearProgress color="secondary" />;
    }

    const isSaving = isUpdatingSchedule || isSwitchingSchedule || isUpdatingProperties
        || isSwitchingLiveMode;

    return (
        <>
            <LinearProgress hidden={!isSaving} color="secondary" />
            <BackdropProgress open={isRunning || isDeleting} />
            <DataSetForm
                dataSetsSelectList={dataSetsSelectList}
                dataSetConfig={selectedConfig}
                dataSetMetadata={dataSetMetadata}
                onSave={handleSaveConfig}
                onDelete={handleDeleteConfig}
                onRunIngestion={handleRunIngestion}
                goBack={back}
                isSwitchSaving={isSwitchingSchedule}
                isScheduleSaving={isUpdatingSchedule}
                isPropertiesSaving={isUpdatingProperties}
                isInfoSaving={isUpdatingInfo}
                isSendingIngestCommand={isRunning}
                dataSourceStatus={dataSourceStatus}
                isStatusLoading={isStatusLoading}
            />
        </>
    );
};

export default withDataSets(EditDataSetConfig);
