import { postData } from '../../utils/api';

const buildRunIngestionApi = (projectCode, dataSourceCode) => `/api/projects/${projectCode}/data-sources/${dataSourceCode}/run-ingestion`;

export const DATASETS_API_BASE_ROUTE = (providerCode) => `/api/providers/${providerCode}/datasets`;

export const runIngestion = (model) => postData(
    buildRunIngestionApi(model.projectCode, model.dataSourceCode),
    {
        projectCode: model.projectCode,
        dataSourceCode: model.dataSourceCode,
        datasetId: model.datasetId
    },
    'POST'
);
