import { Grid } from '@material-ui/core';
import React from 'react';
import TeamSelect from './teamSelect';

export default function TeamSelector(props) {
    const { teamPlatformId } = props;

    return (
        <Grid container columns={11}>
            <Grid item xs={1}>
                Team:
            </Grid>
            <Grid item xs={5} style={{ paddingRight: '0.5rem' }}>
                <TeamSelect {...props} />
            </Grid>
            <Grid item xs={5}>
                {teamPlatformId}
            </Grid>
        </Grid>
    );
}
