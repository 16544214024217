import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory,
} from 'react-router-dom';
import IndexPage from '../shared/components/IndexPage';
import { useShowToastContext } from '../shared/toast/ToastProvider';
import AddProject from './edit/AddProject';
import ProjectsList from './listing/ProjectsList';
import ProjectDetails from './navigation-tabs';

const ADD_PROJECT_ROUTE = 'add-project';

function Projects() {
    const { path, url } = useRouteMatch();
    const showAlert = useShowToastContext();

    const history = useHistory();

    const handleAddProject = () => {
        history.push(`${url}/${ADD_PROJECT_ROUTE}`);
    };

    const goToDetails = (id) => {
        history.push(`${url}/${id}/details`);
    };

    const goToListing = () => {
        history.push(url);
    };

    // todo: redirect to `${url}/${id}/details for AddCustomer
    const handleEditCompleted = (isDelete) => {
        const errorMessage = isDelete ? 'Deleted successfully' : 'Saved successfully';
        showAlert(errorMessage, false);
        goToListing();
    };

    return (
        <IndexPage>
            <Switch>
                <Route exact path={path}>
                    <ProjectsList addProject={handleAddProject} projectDetails={goToDetails} />
                </Route>
                <Route path={`${path}/${ADD_PROJECT_ROUTE}`}>
                    <AddProject goBack={goToListing} onComplete={handleEditCompleted} />
                </Route>
                <Route path={`${path}/:projectCode`}>
                    <ProjectDetails goBack={goToListing} onComplete={handleEditCompleted} />
                </Route>
            </Switch>
        </IndexPage>
    );
}

export default Projects;
