import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LaunchIcon from '@material-ui/icons/Launch';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteConfirmationDialog from '../../shared/components/DeleteConfirmationDialog';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const Actions = ({
    runIngestion, onDelete, hidden, isRunIngestionAvailable
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    if (hidden) {
        return null;
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = React.useState(false);

    const text = 'This will delete data set config immediately. Are you sure you want to continue?';
    const handleDeleteCancel = () => setOpen(false);
    const handleDeleteClick = () => {
        setOpen(true);
        handleClose();
    };
    const handleIngestionClick = () => {
        runIngestion();
        handleClose();
    };

    const handleDeleteConfirm = () => {
        setOpen(false);
        onDelete();
    };

    const menuIcon = anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;

    return (
        <div>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClick}
                endIcon={menuIcon}
            >
                Actions
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem onClick={handleDeleteClick}>
                    <ListItemIcon>
                        <DeleteForeverIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Remove" />
                </StyledMenuItem>
                {
                    isRunIngestionAvailable ? (
                        <StyledMenuItem onClick={handleIngestionClick}>
                            <ListItemIcon>
                                <LaunchIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Run Ingestion" />
                        </StyledMenuItem>
                    ) : null
                }
            </StyledMenu>
            <DeleteConfirmationDialog
                text={text}
                open={open}
                onConfirm={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
            />
        </div>
    );
};

export default Actions;

Actions.propTypes = {
    hidden: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    runIngestion: PropTypes.func.isRequired,
    isRunIngestionAvailable: PropTypes.bool
};

Actions.defaultProps = {
    hidden: false,
    isRunIngestionAvailable: true
};
