import React from 'react';
import DataTable from '../../shared/components/DataTable';

const columns = [{
    id: 'code',
    label: 'Code',
    minWidth: 170,
    align: 'center',
    format: (value) => value,
}, {
    id: 'name',
    label: 'Name',
    minWidth: 170,
}];

const ProjectsTable = (props) => (<DataTable columns={columns} {...props} />);

export default ProjectsTable;
