import React from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import ProjectOpsList from '../listing/ProjectsOpsList';
import { useProjectsStatusListingApi } from '../hooks';
import { useErrorToast } from '../../shared/toast/ToastProvider';

export default function OperationsHubDashboard({ onViewDetails }) {
    const [{
        data,
        isLoading,
        isError,
    }] = useProjectsStatusListingApi();

    useErrorToast(isError, 'Failed to load operations');
    return (
        <>
            <Typography variant="h4" gutterBottom>
                SDXP Dashboard
            </Typography>
            {isLoading && <LinearProgress role="progressbar" />}
            {
                !isLoading && (
                    <ProjectOpsList
                        projectOps={data}
                        onViewProjectOpsDetails={onViewDetails}
                    />
                )
            }
        </>
    );
}
