import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Select = ({
    value,
    setValue,
    label,
    values,
    disabled,
    isLoading,
    fullWidth = true,
    error = false,
    helperText = '',
    readOnly = false
}) => {
    const classes = useStyles();
    const labelId = `${label.replace(' ', '')}-select-label`;
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <FormControl disabled={disabled} margin="normal" error={error} fullWidth={fullWidth}>
            <InputLabel id={labelId}>
                {label}
            </InputLabel>
            <MuiSelect
                labelId={labelId}
                value={isLoading ? '' : value}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ readOnly }}
            >
                {values.map((item) => (
                    <MenuItem value={item.key} key={item.key}>
                        {item.value}
                    </MenuItem>
                ))}
            </MuiSelect>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default Select;
