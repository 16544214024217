import React from 'react';
import { useLocalesList } from './hooks';
import { useErrorToast } from '../../shared/toast/ToastProvider';

const withLocales = (WrappedComponent) => ({
    isLoading: isOuterLoading,
    isError: isOuterError,
    ...otherProps
}) => {
    const [{
        isLoading: isLocalesLoading,
        isError: isLoadLocalesError,
        data: locales,
        error
    }] = useLocalesList();
    useErrorToast(isLoadLocalesError, `Failed to load locales: ${error}`);
    const isError = isOuterError || isLoadLocalesError;
    const isLoading = isOuterLoading || isLocalesLoading;
    const localesSelectList = locales.map((locale) => ({
        key: locale.code,
        value: locale.localeName
    }));

    return (
        <WrappedComponent
            isLoading={isLoading}
            isError={isError}
            locales={locales}
            localesSelectList={localesSelectList}
            {...otherProps}
        />
    );
};

export default withLocales;
