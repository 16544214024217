import React from 'react';
import { useParams } from 'react-router-dom';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import { useAddConnectionApi } from '../hooks';
import ConnectionForm from './ConnectionForm';

const newConnection = {
    code: '',
    project: '',
    provider: '',
    properties: {}
};

function AddProjectConnection({ goBack, onComplete }) {
    const { projectCode } = useParams();
    const [{
        isSaving,
        isError: isSaveError,
        error: saveError
    }, onSaveHandler] = useAddConnectionApi(onComplete);

    useErrorToast(isSaveError, saveError);

    return (
        <ConnectionForm
            isNew
            isProjectTab
            model={{ ...newConnection, project: projectCode }}
            onSubmit={onSaveHandler}
            onCancel={goBack}
            isLoading={isSaving}
            isError={isSaveError}
        />
    );
}

export default AddProjectConnection;
