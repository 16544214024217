import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DeleteButton from '../../shared/components/DeleteButton';
import { entityCodeRule } from '../../shared/form-validation';
import LocalesList from '../locales/LocalesList';
import SectionHeader from '../../shared/components/SectionHeader';

const useStyles = makeStyles((theme) => ({
    buttonsGrid: {
        marginTop: theme.spacing(2),
    },
    buttons: {
        '& > button + button': {
            marginLeft: theme.spacing(2),
        },
    },
}));

const validationSchema = yup.object({
    code: entityCodeRule,
    name: yup.string()
        .max(150, 'Too long')
        .required(),
    description: yup.string()
        .max(400, 'Too long'),
    defaultLocale: yup.string().required(),
    locales: yup.array(yup.string()).min(1),
    strapiUrl: yup.string()
});

const ProjectForm = ({
    isNew,
    project,
    onSubmit,
    onDelete,
    onCancel,
    isLoading,
}) => {
    const formik = useFormik({
        initialValues: {
            code: project.code,
            name: project.name,
            description: project.description,
            defaultLocale: project.defaultLocale,
            strapiUrl: !project.strapiConfig ? '' : project.strapiConfig.url,
            strapiToken: !project.strapiConfig ? '' : project.strapiConfig.token,
            locales: project.locales
        },
        validationSchema,
        validateOnChange: false,
        onSubmit: ({ strapiUrl, strapiToken, ...rest }) => {
            onSubmit({
                ...rest,
                strapiConfig: {
                    url: strapiUrl,
                    token: strapiToken
                }
            });
        }
    });

    const classes = useStyles();

    const handleLocalesChange = (newLocales) => {
        formik.setFieldValue('locales', newLocales);
    };

    const handleDefaultLocaleChange = (newLocale) => {
        formik.setFieldValue('defaultLocale', newLocale);
    };

    return (
        <>
            {isLoading && <LinearProgress color="secondary" role="progressbar" />}
            <form onSubmit={formik.handleSubmit}>
                <Typography variant="h4" gutterBottom noWrap>
                    {`${isNew ? 'ADD' : 'EDIT'} PROJECT`}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            id="code"
                            disabled={!isNew || isLoading}
                            label="Code"
                            margin="normal"
                            value={formik.values.code}
                            error={formik.touched.code && Boolean(formik.errors.code)}
                            helperText={formik.touched.code && formik.errors.code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="name"
                            label="Name"
                            margin="normal"
                            disabled={isLoading}
                            value={formik.values.name}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="description"
                            label="Description"
                            margin="normal"
                            multiline
                            minRows={5}
                            disabled={isLoading}
                            value={formik.values.description}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SectionHeader headerText="Competition Hub Configuration" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="strapiUrl"
                            label="URL"
                            margin="normal"
                            disabled={isLoading}
                            value={formik.values.strapiUrl}
                            error={formik.touched.strapiUrl && Boolean(formik.errors.strapiUrl)}
                            helperText={formik.touched.strapiUrl && formik.errors.strapiUrl}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="strapiToken"
                            label="Security Token"
                            margin="normal"
                            disabled={isLoading}
                            value={formik.values.strapiToken}
                            error={formik.touched.strapiToken && Boolean(formik.errors.strapiToken)}
                            helperText={formik.touched.strapiToken && formik.errors.strapiToken}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalesList
                            defaultLocale={formik.values.defaultLocale}
                            selectedLocales={formik.values.locales}
                            onLocalesChange={handleLocalesChange}
                            onDefaultLocaleChange={handleDefaultLocaleChange}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between" className={classes.buttonsGrid}>
                    {!isNew
                    && (
                        <Grid item>
                            <DeleteButton disabled={isLoading} onDelete={onDelete} itemName={`Project ${formik.values.code}`} />
                        </Grid>
                    )}
                    <Grid item className={classes.buttons}>
                        <Button name="cancel" variant="contained" onClick={onCancel} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            name="save"
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            disabled={isLoading}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default ProjectForm;
