import React from 'react';
import { Paper } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import IngestionStatusChip from '../../shared/components/IngestionStatusChip';

const dataSourceColumns = [{
    id: 'dataSourceName',
    label: 'DataSource Name',
    minWidth: 170,
    format: (value) => value,
}, {
    id: 'providerCode',
    label: 'Provider Code',
    minWidth: 100,
}, {
    id: 'status',
    minWidth: 120,
    format: (value) => <IngestionStatusChip status={value} />
}];

const DataSourceOpsList = ({ data, onViewDetails }) => {
    const keyedData = data.map((item) => ({ code: `${item.key.dataSourceCode}`, ...item }));

    return (
        <Paper>
            <DataTable
                columns={dataSourceColumns}
                disablePagination
                tableName="data-sources"
                rows={keyedData}
                details={({ code }) => { onViewDetails(code); }}
                withoutAddButton
            />
        </Paper>
    );
};

export default DataSourceOpsList;
