import React from 'react';
import AsyncSelect from '../shared/components/asyncSelect';
import { notRequested } from '../shared/async';
import { getCompetitions } from '../shared/api/stsApi';

export default function CompetitionSelect(props) {
    const {
        connection,
        competitionId,
        setCompetitionId
    } = props;

    const [competitions, setCompetions] = React.useState(notRequested());

    React.useEffect(() => {
        if (connection) {
            getCompetitions(connection, setCompetions);
        }
    }, [connection, setCompetions]);

    return (
        <AsyncSelect result={competitions} id={competitionId} setId={setCompetitionId} getIdFromItem={(c) => c.CompetitionId} getNameFromItem={(c) => `${c.CompetitionId} - ${c.CompetitionName}`} />
    );
}
