import React from 'react';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import { useProviderDataSetsList } from './hooks';

const withDataSets = (WrappedComponent) => ({
    isLoading: isOuterLoading,
    isError: isOuterError,
    provider,
    ...otherProps
}) => {
    const [{
        isLoading: isDataSetsLoading,
        isError: isLoadDatasetsError,
        data: dataSets,
        error
    }] = useProviderDataSetsList(provider);

    const isError = isOuterError || isLoadDatasetsError;
    const isLoading = isOuterLoading || isDataSetsLoading;
    const dataSetsSelectList = React.useMemo(() => dataSets.map((dataSet) => ({
        key: dataSet.code,
        value: dataSet.code,
        mode: dataSet.mode
    })), [dataSets.length]);
    useErrorToast(isLoadDatasetsError, `Failed to load data sets: ${error}`);

    return (
        <WrappedComponent
            provider={provider}
            isLoading={isLoading}
            isError={isError}
            dataSets={dataSets}
            dataSetsSelectList={dataSetsSelectList}
            {...otherProps}
        />
    );
};

export default withDataSets;
