import { usePagedListingApi, useGetApi, useSaveApi } from '../shared/hooks/fetchingHooks';
import {
    PROJECTS_API_BASE_ROUTE,
    buildGetProjectUrl,
    editProject,
    addProject,
    deleteProject,
} from './api';

export const useProjectsListingApi = () => usePagedListingApi(PROJECTS_API_BASE_ROUTE);

export const useGetProjectApi = (projectCode) => {
    const startingUrl = buildGetProjectUrl(projectCode);

    const [state, setUrl] = useGetApi(startingUrl, { name: '', code: '', description: '' });
    const projectCodeUpdateHandler = (id) => setUrl(buildGetProjectUrl(id));

    return [state, projectCodeUpdateHandler];
};

export const useEditProjectApi = (onComplete) => useSaveApi(editProject, onComplete);

export const useAddProjectApi = (onComplete) => useSaveApi(addProject, onComplete);

export const useDeleteProjectApi = (onComplete) => useSaveApi(deleteProject, onComplete);
