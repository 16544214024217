export const SECTIONS = {
    PROPERTIES: 'PROPERTIES',
    SCHEDULE: 'SCHEDULE',
    SWITCH_SCHEDULE: 'SWITCH_SCHEDULE',
    SWITCH_LIVE_MODE: 'SWITCH_LIVE_MODE',
    INFO: 'INFO'
};

export const saveSectionsHelper = (handleSave) => {
    /* eslint-disable max-len */
    const updateProperties = (model, successCallback) => handleSave(SECTIONS.PROPERTIES, model, successCallback);
    const updateSchedule = (model, successCallback) => handleSave(SECTIONS.SCHEDULE, model, successCallback);
    const switchSchedule = (model, successCallback) => handleSave(SECTIONS.SWITCH_SCHEDULE, model, successCallback);
    const switchLiveMode = (model, successCallback) => handleSave(SECTIONS.SWITCH_LIVE_MODE, model, successCallback);
    const updateInfo = (model, successCallback) => handleSave(SECTIONS.INFO, model, successCallback);
    /* eslint-enable max-len */

    return {
        updateProperties,
        updateSchedule,
        switchSchedule,
        switchLiveMode,
        updateInfo
    };
};

export const createSaveHandler = (
    sharedModel, {
        updateProperties,
        updateSchedule,
        toggleSchedule,
        toggleLiveMode,
        updateInfo,
    }
) => async (section, model, successCallback) => {
    const saveModel = sharedModel;
    switch (section) {
        case SECTIONS.PROPERTIES: {
            saveModel.jsonProperties = model.jsonProperties;
            await updateProperties(saveModel, successCallback);
            break;
        }
        case SECTIONS.SCHEDULE: {
            saveModel.cron = model.cron;
            await updateSchedule(saveModel, successCallback);
            break;
        }
        case SECTIONS.SWITCH_SCHEDULE: {
            saveModel.enabled = Boolean(model);
            await toggleSchedule(saveModel, successCallback);
            break;
        }
        case SECTIONS.SWITCH_LIVE_MODE: {
            saveModel.enabled = Boolean(model);
            await toggleLiveMode(saveModel, successCallback);
            break;
        }
        case SECTIONS.INFO: {
            saveModel.label = model.label;
            await updateInfo(saveModel, successCallback);
            break;
        }
        default:
            break;
    }
};
