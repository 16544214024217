import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Container,
    LinearProgress,
} from '@material-ui/core';
import GoBackButton from '../../shared/components/GoBackButton';

import { useErrorToast } from '../../shared/toast/ToastProvider';
import { useOperationDetailsApi } from '../hooks';
import OperationDetailsView from '../detail/OperationDetailsView';

export default function RawDataOpsDetails({ onGoBack }) {
    const { operationId, projectCode, dataSourceCode } = useParams();
    const [{
        data,
        isLoading,
        isError
    }] = useOperationDetailsApi(operationId);

    useErrorToast(isError, 'Failed to load operation details');

    if (isLoading) {
        return <LinearProgress role="progressbar" />;
    }

    return (
        <Container>
            {data && (Object.keys(data).length > 0)
                && <OperationDetailsView operationDetails={data} />}
            <GoBackButton
                text="Back to datasource monitoring details"
                goBack={() => onGoBack(projectCode, dataSourceCode)}
            />
        </Container>
    );
}
