import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable*/
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        maxHeight: 500,
    },
    fab: {
        margin: theme.spacing(2),
    },
    row: {
        cursor: 'pointer',
    },
}));

const LoggingTable = ({
    columns,
    rows,
    details,
    tableName = 'data',
    cellRenderer,
    ...restProps
}) => {
    const classes = useStyles();
    console.log(rows);

    return (
        <div className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label={`${tableName}-table`}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.length && rows?.map((row) => (
                            <TableRow
                                className={classes.row}
                                hover
                                tabIndex={-1}
                                key={row.code}
                                onClick={() => details(row)}
                            >
                                {columns.map((column) => {
                                    let value = row[column.id];
                                    if (cellRenderer) {
                                        value = cellRenderer(
                                            column,
                                            value,
                                            row
                                        );
                                    }

                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                        >
                                            {column.format
                                                ? column.format(value)
                                                : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination {...restProps} />
        </div>
    );
};

const Pagination = ({
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    totalCount,
    disablePagination,
}) => {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    if (disablePagination) {
        return null;
    }

    return (
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
};

export default LoggingTable;
