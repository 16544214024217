import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React from 'react';
import Rating from '@material-ui/lab/Rating';

const LocaleRow = ({
    locale,
    selectedLocalesLength,
    setDefaultLocale,
    removeLocale
}) => {
    const { code, isDefault, name } = locale;
    const handleDefaultLocaleChange = (_, value) => {
        if (value > 0) {
            setDefaultLocale(code);
        }
    };
    const handleRemove = () => {
        removeLocale(code);
    };

    return (
        <ListItem data-testid={`${code}-row`}>
            <ListItemIcon>
                <Rating
                    name={`${code}-rating`}
                    value={isDefault ? 1 : 0}
                    max={1}
                    onChange={handleDefaultLocaleChange}
                    readOnly={isDefault}
                />
            </ListItemIcon>
            <ListItemText
                primary={name}
            />
            <ListItemSecondaryAction>
                <Tooltip title="Remove" placement="right">
                    <div>
                        <IconButton
                            edge="end"
                            aria-label={`remove-${code}`}
                            color="secondary"
                            disabled={selectedLocalesLength < 2 || isDefault}
                            onClick={handleRemove}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    </div>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default LocaleRow;
