import { Grid } from '@material-ui/core';
import React from 'react';
import {
    getLineupApi,
    getMatchPlayerStatisticsApi,
    getMatchTeamStatisticsApi,
    getFeedApi
} from '../shared/api/b2cMatchApis';
import {
    getMatchApi,
    getTeamApi,
    getSeasonPlayerStatisticsApi,
    getSeasonTeamStatisticsApi
} from '../shared/api/b2cSeasonApis';
import { getStandingsApi } from '../shared/api/b2cStandingsApis';
import { getStandingsByGroupApi } from '../shared/api/b2cStandingsByGroupApi';
import { getRosterApi, getTeamOfficialsApi } from '../shared/api/b2cTeamApis';
import {
    attackingZonesApi,
    lineupApi as stsLineupApi,
    matchStatisticsApi as stsMatchStatisticsApi,
    positionalDataApi,
    postMatchApi,
} from '../shared/api/stsMatchApis';
import {
    matchApi as stsMatchApi,
    teamApi as stsTeamApi,
    seasonStatsApi,
    seasonPositionalStatsApi
} from '../shared/api/stsSeasonApis';
import { rosterApi, teamOfficialsApi } from '../shared/api/stsTeamApis';
import standingsApi from '../shared/api/stsStandingsApi';
import ButtonNav from '../shared/components/buttonNav';
import MatchTab from './matchTab';
import SeasonTab from './seasonTab';
import SeasonStatsTab from './seasonStatsTab';
import StandingsTab from './standingsTab';
import TeamTab from './teamTab';
import StandingsByGroupTab from './standingByGroupsTab';
import { getJson } from '../shared/api/http';

const tabs = [
    'MATCHES',
    'LINEUP',
    'MATCH_STATISTICS',
    'POSITIONAL_DATA',
    'ATTACKING_ZONES',
    'POST_MATCH',
    'TEAMS',
    'ROSTER',
    'TEAM_OFFICIALS',
    'SEASON_STATISTICS',
    'SEASON_POSITIONAL_STATISTICS',
    'STANDINGS',
    'STANDINGS BY GROUPS'
];

function getTabName(tab) {
    switch (tab) {
        case 'SEASON_STATISTICS':
            return 'Season Stats';
        case 'SEASON_POSITIONAL_STATISTICS':
            return 'Season Positional Stats';
        case 'MATCHES':
            return 'Matches';
        case 'LINEUP':
            return 'Lineup';
        case 'MATCH_STATISTICS':
            return 'Match Stats';
        case 'POSITIONAL_DATA':
            return 'Positional Data';
        case 'ATTACKING_ZONES':
            return 'Attacking Zones';
        case 'POST_MATCH':
            return 'Post Match';
        case 'TEAMS':
            return 'Teams';
        case 'ROSTER':
            return 'Roster';
        case 'TEAM_OFFICIALS':
            return 'Team Officials';
        case 'STANDINGS':
            return 'Standings';
        case 'STANDINGS BY GROUPS':
            return 'Standings By Groups';
        default:
            return undefined;
    }
}

function getApi(apiFunction, b2cHost) {
    if (apiFunction && b2cHost) {
        return apiFunction(b2cHost);
    }
    return undefined;
}

export default function Tabs(props) {
    const { connection } = props;

    const [tab, setTab] = React.useState('MATCHES');
    const [b2cRootUrl, setb2cRootUrl] = React.useState(undefined);

    React.useEffect(() => {
        getJson('/api/sts-mls/b2c-root-url', (result) => {
            if (result.type === 'SUCCEEDED') {
                setb2cRootUrl(result.data.b2cRootUrl);
            }
        });
    }, []);

    const b2cMatchApi = React.useMemo(() => getApi(getMatchApi, b2cRootUrl), [b2cRootUrl]);
    const b2cTeamApi = React.useMemo(() => getApi(getTeamApi, b2cRootUrl), [b2cRootUrl]);
    const b2cStandingsApi = React.useMemo(() => getApi(getStandingsApi, b2cRootUrl), [b2cRootUrl]);
    const b2cStandingsByGroupApi = React.useMemo(
        () => getApi(getStandingsByGroupApi, b2cRootUrl), [b2cRootUrl]
    );

    const b2cRosterApi = React.useMemo(() => getApi(getRosterApi, b2cRootUrl), [b2cRootUrl]);
    const b2cTeamOfficialsApi = React.useMemo(
        () => getApi(getTeamOfficialsApi, b2cRootUrl), [b2cRootUrl]
    );
    const b2cLineupApi = React.useMemo(() => getApi(getLineupApi, b2cRootUrl), [b2cRootUrl]);
    const b2cMatchTeamStatisitcsApi = React.useMemo(
        () => getApi(getMatchTeamStatisticsApi, b2cRootUrl), [b2cRootUrl]
    );
    const b2cMatchPlayerStatisitcsApi = React.useMemo(
        () => getApi(getMatchPlayerStatisticsApi, b2cRootUrl), [b2cRootUrl]
    );
    const b2cFeedApi = React.useMemo(() => getApi(getFeedApi, b2cRootUrl), [b2cRootUrl]);

    const b2cSeasonTeamStatisitcsApi = React.useMemo(
        () => getApi(getSeasonTeamStatisticsApi, b2cRootUrl), [b2cRootUrl]
    );
    const b2cSeasonPlayerStatisitcsApi = React.useMemo(
        () => getApi(getSeasonPlayerStatisticsApi, b2cRootUrl), [b2cRootUrl]
    );

    const tabContents = React.useMemo(() => {
        if (!connection || !b2cRootUrl) {
            return undefined;
        }
        switch (tab) {
            case 'SEASON_STATISTICS':
                return (
                    <SeasonStatsTab
                        key="season-stats"
                        {...props}
                        getStsSeasonDataUrl={(c, s) => seasonStatsApi.getUrl(connection, c, s)}
                        getStsSeasonDataXml={
                            (c, s, d) => seasonStatsApi.getXml(connection, c, s, d)
                        }
                        getB2cSeasonData={[
                            {
                                name: 'Team Stats',
                                getSeasonDataUrl:
                                    (p, s) => b2cSeasonTeamStatisitcsApi.getUrl(p, s),
                                getSeasonDataJson:
                                    (p, s, d) => b2cSeasonTeamStatisitcsApi.getJson(p, s, d)
                            },
                            {
                                name: 'Player Stats',
                                getSeasonDataUrl:
                                    (p, s) => b2cSeasonPlayerStatisitcsApi.getUrl(p, s),
                                getSeasonDataJson:
                                    (p, s, d) => b2cSeasonPlayerStatisitcsApi.getJson(p, s, d)
                            }
                        ]}
                    />
                );
            case 'SEASON_POSITIONAL_STATISTICS':
                return (
                    <SeasonStatsTab
                        key="season-positional-stats"
                        {...props}
                        getStsSeasonDataUrl={
                            (c, s) => seasonPositionalStatsApi.getUrl(connection, c, s)
                        }
                        getStsSeasonDataXml={
                            (c, s, d) => seasonPositionalStatsApi.getXml(connection, c, s, d)
                        }
                        getB2cSeasonData={[
                            {
                                name: 'Team Stats',
                                getSeasonDataUrl:
                                    (p, s) => b2cSeasonTeamStatisitcsApi.getUrl(p, s),
                                getSeasonDataJson:
                                    (p, s, d) => b2cSeasonTeamStatisitcsApi.getJson(p, s, d)
                            },
                            {
                                name: 'Player Stats',
                                getSeasonDataUrl:
                                    (p, s) => b2cSeasonPlayerStatisitcsApi.getUrl(p, s),
                                getSeasonDataJson:
                                    (p, s, d) => b2cSeasonPlayerStatisitcsApi.getJson(p, s, d)
                            }
                        ]}
                    />
                );
            case 'MATCHES':
                return (
                    <SeasonTab
                        key="matches"
                        {...props}
                        getStsSeasonDataUrl={(c, s) => stsMatchApi.getUrl(connection, c, s)}
                        getStsSeasonDataXml={(c, s, d) => stsMatchApi.getXml(connection, c, s, d)}
                        getB2cSeasonDataUrl={(p, s) => b2cMatchApi.getUrl(p, s)}
                        getB2cSeasonDataJson={(p, s, d) => b2cMatchApi.getJson(p, s, d)}
                    />
                );
            case 'LINEUP':
                return (
                    <MatchTab
                        key="line-up"
                        {...props}
                        getStsMatchDataUrl={(m) => stsLineupApi.getUrl(connection, m)}
                        getStsMatchDataXml={(m, d) => stsLineupApi.getXml(connection, m, d)}
                        getB2cMatchData={[
                            {
                                name: 'Line Up',
                                getMatchDataUrl: (p, s, m) => b2cLineupApi.getUrl(p, s, m),
                                getMatchDataJson: (p, s, m, d) => b2cLineupApi.getJson(p, s, m, d)
                            }
                        ]}
                    />
                );
            case 'MATCH_STATISTICS':
                return (
                    <MatchTab
                        key="match-stats"
                        {...props}
                        getStsMatchDataUrl={(m) => stsMatchStatisticsApi.getUrl(connection, m)}
                        getStsMatchDataXml={
                            (m, d) => stsMatchStatisticsApi.getXml(connection, m, d)
                        }
                        getB2cMatchData={[
                            {
                                name: 'Team Stats',
                                getMatchDataUrl:
                                    (p, s, m) => b2cMatchTeamStatisitcsApi.getUrl(p, s, m),
                                getMatchDataJson:
                                    (p, s, m, d) => b2cMatchTeamStatisitcsApi.getJson(p, s, m, d)
                            },
                            {
                                name: 'Player Stats',
                                getMatchDataUrl:
                                    (p, s, m) => b2cMatchPlayerStatisitcsApi.getUrl(p, s, m),
                                getMatchDataJson:
                                    (p, s, m, d) => b2cMatchPlayerStatisitcsApi.getJson(p, s, m, d)
                            }
                        ]}
                    />
                );
            case 'POSITIONAL_DATA':
                return (
                    <MatchTab
                        key="position-stats"
                        {...props}
                        getStsMatchDataUrl={(m) => positionalDataApi.getUrl(connection, m)}
                        getStsMatchDataXml={(m, d) => positionalDataApi.getXml(connection, m, d)}
                        getB2cMatchData={[
                            {
                                name: 'Team Stats',
                                getMatchDataUrl:
                                    (p, s, m) => b2cMatchTeamStatisitcsApi.getUrl(p, s, m),
                                getMatchDataJson:
                                    (p, s, m, d) => b2cMatchTeamStatisitcsApi.getJson(p, s, m, d)
                            },
                            {
                                name: 'Player Stats',
                                getMatchDataUrl:
                                    (p, s, m) => b2cMatchPlayerStatisitcsApi.getUrl(p, s, m),
                                getMatchDataJson:
                                    (p, s, m, d) => b2cMatchPlayerStatisitcsApi.getJson(p, s, m, d)
                            }
                        ]}
                    />
                );
            case 'ATTACKING_ZONES':
                return (
                    <MatchTab
                        key="attacking-zones"
                        {...props}
                        getStsMatchDataUrl={(m) => attackingZonesApi.getUrl(connection, m)}
                        getStsMatchDataXml={(m, d) => attackingZonesApi.getXml(connection, m, d)}
                        getB2cMatchData={[
                            {
                                name: 'Team Stats',
                                getMatchDataUrl:
                                    (p, s, m) => b2cMatchTeamStatisitcsApi.getUrl(p, s, m),
                                getMatchDataJson:
                                    (p, s, m, d) => b2cMatchTeamStatisitcsApi.getJson(p, s, m, d)
                            },
                            {
                                name: 'Player Stats',
                                getMatchDataUrl:
                                    (p, s, m) => b2cMatchPlayerStatisitcsApi.getUrl(p, s, m),
                                getMatchDataJson:
                                    (p, s, m, d) => b2cMatchPlayerStatisitcsApi.getJson(p, s, m, d)
                            }
                        ]}
                    />
                );
            case 'POST_MATCH':
                return (
                    <MatchTab
                        key="postmatch"
                        {...props}
                        getStsMatchDataUrl={(m) => postMatchApi.getUrl(connection, m)}
                        getStsMatchDataXml={(m, d) => postMatchApi.getXml(connection, m, d)}
                        getB2cMatchData={[
                            {
                                name: 'Feed',
                                getMatchDataUrl: (p, s, m) => b2cFeedApi.getUrl(p, s, m),
                                getMatchDataJson: (p, s, m, d) => b2cFeedApi.getJson(p, s, m, d)
                            }
                        ]}
                    />
                );
            case 'TEAMS':
                return (
                    <SeasonTab
                        key="teams"
                        {...props}
                        getStsSeasonDataUrl={(c, s) => stsTeamApi.getUrl(connection, c, s)}
                        getStsSeasonDataXml={(c, s, d) => stsTeamApi.getXml(connection, c, s, d)}
                        getB2cSeasonDataUrl={(p, s) => b2cTeamApi.getUrl(p, s)}
                        getB2cSeasonDataJson={(p, s, d) => b2cTeamApi.getJson(p, s, d)}
                    />
                );
            case 'ROSTER':
                return (
                    <TeamTab
                        key="roster"
                        {...props}
                        getStsTeamDataUrl={(s, t) => rosterApi.getUrl(connection, s, t)}
                        getStsTeamDataXml={(s, t, d) => rosterApi.getXml(connection, s, t, d)}
                        getB2cTeamDataUrl={(p, t) => b2cRosterApi.getUrl(p, t)}
                        getB2cTeamDataJson={(p, t, d) => b2cRosterApi.getJson(p, t, d)}
                    />
                );
            case 'TEAM_OFFICIALS':
                return (
                    <TeamTab
                        key="teamOfficials"
                        {...props}
                        getStsTeamDataUrl={(s, t) => teamOfficialsApi.getUrl(connection, s, t)}
                        getStsTeamDataXml={(s, t, d) => teamOfficialsApi.getXml(
                            connection, s, t, d
                        )}
                        getB2cTeamDataUrl={(p, t) => b2cTeamOfficialsApi.getUrl(p, t)}
                        getB2cTeamDataJson={(p, t, d) => b2cTeamOfficialsApi.getJson(p, t, d)}
                    />
                );
            case 'STANDINGS':
                return (
                    <StandingsTab
                        key="standings"
                        {...props}
                        getStsStandingsUrl={(c) => standingsApi.getUrl(connection, c)}
                        getStsStandingsXml={(s, t) => standingsApi.getXml(connection, s, t)}
                        getB2cStandingsUrl={(p, t) => b2cStandingsApi.getUrl(t, p)}
                        getB2cStandingsJson={(p, t, d) => b2cStandingsApi.getJson(t, p, d)}
                    />
                );
            case 'STANDINGS BY GROUPS':
                return (
                    <StandingsByGroupTab
                        key="standingsByGroup"
                        {...props}
                        getStsStandingsUrl={(c) => standingsApi.getUrl(connection, c)}
                        getStsStandingsXml={(s, t) => standingsApi.getXml(connection, s, t)}
                        getB2cStandingsByGroupUrl={
                            (p, t, g) => b2cStandingsByGroupApi.getUrl(t, p, g)
                        }
                        getB2cStandingsByGroupJson={
                            (p, t, g, d) => b2cStandingsByGroupApi.getJson(t, p, g, d)
                        }
                    />
                );
            default:
                return undefined;
        }
    }, [connection, tab, props, b2cRootUrl]);

    return (
        <>
            <Grid container columns={11} style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }} columnSpacing={{ xs: 2 }}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <ButtonNav tabs={tabs} tab={tab} getTabName={getTabName} setTab={setTab} />
                </Grid>
            </Grid>
            {tabContents}
        </>
    );
}
