import formatDistanceToNow from 'date-fns/formatDistanceToNow';

// eslint-disable-next-line import/prefer-default-export
export function toRelativeTimeString(lastRun) {
    if (!Number(lastRun)) {
        return null;
    }

    const result = formatDistanceToNow(new Date(Number(lastRun)));

    return `${result} ago`;
}
