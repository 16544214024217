import { Grid } from '@material-ui/core';
import React from 'react';
import { notRequested } from '../shared/async';
import AsyncXmlAndJson from '../shared/components/asyncXmlAndJson';
import TeamSelector from './teamSelector';

export default function TeamTab(props) {
    const {
        projectId,
        seasonId,
        teamId,
        teamPlatformId,
        getStsTeamDataUrl,
        getStsTeamDataXml,
        getB2cTeamDataUrl,
        getB2cTeamDataJson,
    } = props;

    const [stsTeamData, setStsTeamData] = React.useState(notRequested());
    const [b2cTeamData, setB2cTeamData] = React.useState(notRequested());

    const stsUrl = React.useMemo(() => {
        if (seasonId && teamId) {
            return getStsTeamDataUrl(seasonId, teamId);
        }
        return undefined;
    }, [seasonId, teamId, getStsTeamDataUrl]);

    const b2cUrl = React.useMemo(() => {
        if (projectId && teamPlatformId) {
            return getB2cTeamDataUrl(projectId, teamPlatformId);
        }
        return undefined;
    }, [projectId, teamPlatformId, getB2cTeamDataUrl]);

    React.useEffect(() => {
        if (seasonId && teamId) {
            getStsTeamDataXml(seasonId, teamId, setStsTeamData);
        } else {
            setStsTeamData(notRequested());
        }
    }, [seasonId, teamId, getStsTeamDataXml, setStsTeamData]);

    React.useEffect(() => {
        if (projectId && teamPlatformId) {
            getB2cTeamDataJson(projectId, teamPlatformId, setB2cTeamData);
        } else {
            setB2cTeamData(notRequested());
        }
    }, [projectId, teamPlatformId, getB2cTeamDataJson, setB2cTeamData]);

    return (
        <>
            <TeamSelector {...props} />
            <Grid container columns={11}>
                <Grid item xs={1}>
                    URLs:
                </Grid>
                <Grid item xs={5} style={{ paddingRight: '0.5rem' }}>
                    <a href={stsUrl} target="_blank" rel="noreferrer">{stsUrl}</a>
                </Grid>
                <Grid item xs={5} style={{ overflowWrap: 'anywhere' }}>
                    <a href={b2cUrl} target="_blank" rel="noreferrer">{b2cUrl}</a>
                </Grid>
            </Grid>
            <AsyncXmlAndJson xml={stsTeamData} json={b2cTeamData} />
        </>
    );
}
