import PropTypes from 'prop-types';
import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';

const SchedulerSettings = ({
    cron,
    onChange,
    disabled
}) => {
    React.useEffect(() => {}, [cron]);
    const [schedulingType, setSchedulingType] = React.useState('Cron Expression');
    const [schedulerSettingsLabel, setschedulerSettingsLabel] = React.useState('');
    const [schedulerSettingsValue, setschedulerSettingsValue] = React.useState(cron);

    const handleComboChange = (value) => {
        setschedulerSettingsLabel(value);
    };

    const handleChange = (event) => {
        setschedulerSettingsValue('');
        document.getElementById('cron').value = '';
        setSchedulingType(event.target.value);
        handleComboChange(event.target.value);
    };

    const handleCronChange = ({ target: { value } }) => {
        let cronExpression = '';
        switch (schedulingType) {
            case 'None':
                cronExpression = '';
                break;
            case 'Cron Expression':
                cronExpression = value;
                break;
            case 'Every X Seconds':
                cronExpression = `0/${value} * * * * *`;
                break;
            case 'Every X Minutes':
                cronExpression = `0/${value} * * * *`;
                break;
            case 'Every X Hours':
                cronExpression = `0 0/${value} * * *`;
                break;
            case 'Every Day at': {
                try {
                    const [hour, min] = value.split(/[.,:]/);
                    cronExpression = `0 ${min} ${hour} * * *`;
                } catch {
                    cronExpression = '';
                }
                break;
            }
            default:
                cronExpression = '';
                break;
        }
        setschedulerSettingsValue(value);
        document.getElementById('cron').value = cronExpression;
        onChange(cronExpression);
    };

    return (
        <Grid container justifyContent="space-between" alignItems="flex-end" spacing={4}>
            <Grid item>
                <Box sx={{ m: 1, minWidth: 200 }}>
                    <FormControl fullWidth>
                        <InputLabel id="scheduling-type-label">Select Scheduling Type</InputLabel>
                        <Select
                            labelId="scheduling-type-label"
                            id="scheduling-type"
                            onChange={handleChange}
                            label="Select Scheduling Type"
                            value={schedulingType}
                            disabled={disabled}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="Every X Seconds">Every x seconds</MenuItem>
                            <MenuItem value="Every X Minutes">Every x minutes</MenuItem>
                            <MenuItem value="Every X Hours">Every x hours</MenuItem>
                            <MenuItem value="Every Day at">Every Day at hh:mm</MenuItem>
                            <MenuItem value="Cron Expression">Cron Expression</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item>
                <TextField
                    disabled={disabled}
                    id="schedulerSettings"
                    label={schedulerSettingsLabel}
                    margin="normal"
                    fullWidth
                    value={schedulerSettingsValue}
                    onChange={handleCronChange}
                />
            </Grid>
            <Grid item>
                <Alert variant="outlined" severity="warning">
                    UTC time
                </Alert>
            </Grid>
        </Grid>
    );
};

export default SchedulerSettings;

SchedulerSettings.propTypes = {
    cron: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

SchedulerSettings.defaultProps = {};
