import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

const OrangeWarningIcon = withStyles(({ palette }) => ({
    root: {
        color: palette.warning.main
    }
}))((props) => <WarningIcon {...props} />);

export default OrangeWarningIcon;
