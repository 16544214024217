import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useListFilteredConnectionsApi } from '../../connections/hooks';
import EditButtonsGroup from '../../shared/components/EditButtonsGroup';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import DeleteButton from '../../shared/components/DeleteButton';
import Select from '../../shared/components/Select';
import { entityCodeRule } from '../../shared/form-validation';

const validationSchema = yup.object({
    code: entityCodeRule,
    provider: yup
        .string()
        .required('Is required'),
    connection: yup
        .string()
        .required('Is required')
});

const DataSourceForm = ({
    isNew,
    dataSource,
    onSubmit,
    onDelete,
    providers,
    isLoading,
    isSaving,
    onCancel
}) => {
    const [isEdit, setEdit] = React.useState(false);
    const handleEdit = () => setEdit(!isEdit);
    // TODO: Get providers here
    const [{
        isLoading: isConnectionsLoading,
        isError: isLoadConnectionsError,
        error: loadConnectionsError,
        data: rawConnections
    }, handleProviderUpdate] = useListFilteredConnectionsApi(
        dataSource.project,
        dataSource.provider
    );
    const {
        code: initialCode,
        connection: initialConnection,
        provider: initialProvider
    } = dataSource;
    const formik = useFormik({
        initialValues: {
            code: initialCode,
            connection: initialConnection,
            provider: initialProvider
        },
        validateOnChange: false,
        validationSchema,
        onSubmit: (values) => {
            onSubmit({
                ...values,
                project: dataSource.project
            });
        }
    });

    const connections = rawConnections
        ? rawConnections.map((item) => ({ key: item.code, value: item.code }))
        : [];

    const handleProviderChanged = async (value) => {
        handleProviderUpdate(value);

        await formik.setValues({
            ...formik.values,
            provider: value,
            connection: ''
        });

        await formik.setTouched({
            ...formik.touched,
            provider: true,
            connection: true
        });
    };

    const handleConnectionChanged = async (value) => {
        await formik.setFieldValue('connection', value);
        await formik.setTouched({
            ...formik.touched,
            connection: true
        });
    };

    const onEditCancel = () => {
        if (isNew) {
            onCancel();
        } else {
            setEdit(false);
        }
    };

    useErrorToast(isLoadConnectionsError, loadConnectionsError);
    const readOnly = isNew ? false : !isEdit;

    return (
        <>
            {(isLoading || isConnectionsLoading) && <LinearProgress color="secondary" role="progressbar" />}
            <form onSubmit={formik.handleSubmit} aria-label="data-source-form">
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h4" noWrap gutterBottom>
                            {isNew ? 'Add Data Source' : 'Data Source'}
                        </Typography>
                    </Grid>
                    <Grid item hidden={isNew}>
                        <DeleteButton
                            disabled={isLoading}
                            onDelete={onDelete}
                            itemName={`Data Source ${formik.values.code}`}
                        />
                    </Grid>
                </Grid>
                <div>
                    <TextField
                        id="code"
                        name="code"
                        disabled={!isNew || isLoading}
                        label="Code"
                        margin="normal"
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                        helperText={formik.touched.code && formik.errors.code}
                        fullWidth
                    />
                </div>
                <div>
                    <Select
                        disabled={!isNew || isLoading}
                        label="Provider"
                        setValue={handleProviderChanged}
                        value={isLoading ? '' : formik.values.provider}
                        values={providers}
                        isLoading={isLoading}
                        error={formik.touched.provider && Boolean(formik.errors.provider)}
                        helperText={formik.touched.provider && formik.errors.provider}
                    />
                </div>
                <div>
                    <Select
                        name="connection"
                        disabled={isLoading || isConnectionsLoading || !formik.values.provider}
                        label="Connection"
                        setValue={handleConnectionChanged}
                        value={isConnectionsLoading || isLoading ? '' : formik.values.connection}
                        values={connections}
                        isLoading={isLoading}
                        error={formik.touched.connection && Boolean(formik.errors.connection)}
                        helperText={formik.touched.connection && formik.errors.connection}
                        readOnly={readOnly}
                    />
                </div>
                <EditButtonsGroup
                    onCancel={onEditCancel}
                    onEdit={handleEdit}
                    isEdit={isEdit || isNew}
                    isCancelDisabled={isLoading}
                    isSaveDisabled={isLoading || !formik.dirty}
                    isEditDisabled={isLoading}
                    isSaving={isSaving}
                />
            </form>
        </>
    );
};

export default DataSourceForm;

DataSourceForm.propTypes = {
    dataSource: PropTypes.shape({
        code: PropTypes.string,
        connection: PropTypes.string,
        project: PropTypes.string.isRequired,
        provider: PropTypes.string
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isNew: PropTypes.bool,
    isSaving: PropTypes.bool.isRequired,
    onDelete: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    providers: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })).isRequired
};

DataSourceForm.defaultProps = {
    isNew: false,
    onDelete: () => {
    },
};
