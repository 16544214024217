import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../shared/components/DataTable';
import { useConnectionsListingApi } from '../hooks';
import { useErrorToast } from '../../shared/toast/ToastProvider';

const columns = [
    {
        id: 'code',
        label: 'Code',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'project',
        label: 'Project',
        minWidth: 170,
    },
    {
        id: 'provider',
        label: 'Provider',
        minWidth: 170,
    },
];

function ConnectionsList({ addConnection, connectionDetails }) {
    const [{
        data,
        isLoading,
        isError,
        totalCount,
        pageSize,
        currentPage
    }, setPageSize, setPage] = useConnectionsListingApi();
    useErrorToast(isError, 'Failed to load connections');

    const handleDetails = (row) => connectionDetails(row.project, row.code);

    return (
        <>
            <Typography variant="h4" gutterBottom>CONNECTIONS</Typography>
            {isLoading && <LinearProgress role="progressbar" />}
            <DataTable
                columns={columns}
                rows={data}
                page={currentPage}
                rowsPerPage={pageSize}
                setPage={setPage}
                setRowsPerPage={setPageSize}
                totalCount={totalCount}
                add={addConnection}
                details={handleDetails}
                tableName="connections"
            />
        </>
    );
}

export default ConnectionsList;
