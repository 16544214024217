import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import IngestionStatusChip from '../../shared/components/IngestionStatusChip';
import Captioned from '../../shared/components/Captioned';
import { ingestionAggregatedStatuses } from '../../shared/const';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingTop: theme.spacing(2)
    },
    projectName: {
        marginBottom: theme.spacing(1)
    }
}));

const ProjectAggregatedStatus = ({ projectName, projectStatus }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root} mb={3}>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Captioned caption="Project Name">
                        <Typography variant="h5" className={classes.projectName}>
                            {projectName}
                        </Typography>
                    </Captioned>
                </Grid>
                <Grid container item xs={4} justifyContent="flex-end">
                    <Captioned caption="Aggregated Status">
                        <IngestionStatusChip status={projectStatus} />
                    </Captioned>
                </Grid>
            </Grid>
        </Box>
    );
};

ProjectAggregatedStatus.propTypes = {
    projectName: PropTypes.string.isRequired,
    projectStatus: PropTypes.oneOf(ingestionAggregatedStatuses).isRequired
};

export default ProjectAggregatedStatus;
