import React from 'react';
import parseJsonProperties from '../../utils/json-properties-parser';

const SWITCH_CRON_ENABLED = 'SWITCH_CRON_ENABLED';

const SWITCH_LIVE_MODE_ENABLED = 'SWITCH_LIVE_MODE_ENABLED';

const SET_JSON_PROPERTIES = 'SET_JSON_PROPERTIES';
const ENABLE_JSON_PROPERTIES_EDIT_MODE = 'ENABLE_JSON_PROPERTIES_EDIT_MODE';
const CANCEL_JSON_PROPERTIES_EDIT_MODE = 'CANCEL_JSON_PROPERTIES_EDIT_MODE';
const SAVE_JSON_PROPERTIES = 'SAVE_JSON_PROPERTIES';

const ENABLE_SCHEDULE_EDIT_MODE = 'ENABLE_SCHEDULE_EDIT_MODE';
const CANCEL_SCHEDULE_EDIT_MODE = 'CANCEL_SCHEDULE_EDIT_MODE';
const SAVE_SCHEDULE = 'SAVE_SCHEDULE';
const SET_CRON = 'SET_CRON';
const SET_CRON_VALID = 'SET_CRON_VALID';
const SET_LABEL = 'SET_LABEL';
const ENABLE_INFO_EDIT_MODE = 'ENABLE_INFO_EDIT_MODE';
const CANCEL_INFO_EDIT_MODE = 'CANCEL_INFO_EDIT_MODE';
const SAVE_INFO = 'SAVE_INFO';

const UPDATE_DATA_SET = 'UPDATE_DATA_SET';

const dataSetConfigReducer = (state, action) => {
    switch (action.type) {
        case SET_CRON:
            return {
                ...state,
                cron: action.cron
            };
        case SWITCH_CRON_ENABLED:
            return {
                ...state,
                isCronEnabled: !state.isCronEnabled
            };
        case SWITCH_LIVE_MODE_ENABLED:
            return {
                ...state,
                isLiveModeEnabled: !state.isLiveModeEnabled
            };
        case SET_JSON_PROPERTIES:
            return {
                ...state,
                jsonProperties: action.jsonProperties
            };
        case ENABLE_JSON_PROPERTIES_EDIT_MODE:
            return {
                ...state,
                isEditProperties: true
            };
        case CANCEL_JSON_PROPERTIES_EDIT_MODE:
            return {
                ...state,
                isEditProperties: false,
                jsonProperties: state.originalState.jsonProperties,
            };
        case SAVE_JSON_PROPERTIES:
            return {
                ...state,
                isEditProperties: false,
                originalState: {
                    ...state.originalState,
                    jsonProperties: state.jsonProperties
                }
            };
        case ENABLE_SCHEDULE_EDIT_MODE:
            return {
                ...state,
                isEditSchedule: true
            };
        case CANCEL_SCHEDULE_EDIT_MODE:
            return {
                ...state,
                isEditSchedule: false,
                cron: state.originalState.cron,
            };
        case SAVE_SCHEDULE:
            return {
                ...state,
                isEditSchedule: false,
                originalState: {
                    ...state.originalState,
                    cron: state.cron
                }
            };
        case SET_CRON_VALID:
            return {
                ...state,
                isCronValid: action.isCronValid
            };
        case UPDATE_DATA_SET:
            return {
                ...state,
                name: action.name,
                jsonProperties: {}
            };
        case SET_LABEL: {
            return {
                ...state,
                label: action.label
            };
        }
        case ENABLE_INFO_EDIT_MODE: {
            return {
                ...state,
                isEditInfo: true
            };
        }
        case CANCEL_INFO_EDIT_MODE: {
            return {
                ...state,
                isEditInfo: false,
                label: state.originalState.label,
            };
        }
        case SAVE_INFO: {
            return {
                ...state,
                isEditInfo: false,
                originalState: {
                    ...state.originalState,
                    label: state.label
                }
            };
        }
        default:
            return state;
    }
};

const getActions = (dispatch) => {
    const changeDataSet = (newDataSetName) => {
        dispatch({ type: UPDATE_DATA_SET, name: newDataSetName });
    };

    const setCron = (value) => {
        dispatch({ type: SET_CRON, cron: value });
    };

    const setProperties = (value) => {
        dispatch({ type: SET_JSON_PROPERTIES, jsonProperties: value });
    };

    const switchSchedule = () => {
        dispatch({ type: SWITCH_CRON_ENABLED });
    };

    const switchLiveMode = () => {
        dispatch({ type: SWITCH_LIVE_MODE_ENABLED });
    };

    const enableEditProperties = () => {
        dispatch({ type: ENABLE_JSON_PROPERTIES_EDIT_MODE });
    };

    const cancelEditProperties = () => {
        dispatch({ type: CANCEL_JSON_PROPERTIES_EDIT_MODE });
    };

    const onSaveProperties = () => {
        dispatch({ type: SAVE_JSON_PROPERTIES });
    };

    const enableEditSchedule = () => {
        dispatch({ type: ENABLE_SCHEDULE_EDIT_MODE });
    };

    const cancelEditSchedule = () => {
        dispatch({ type: CANCEL_SCHEDULE_EDIT_MODE });
    };

    const onSaveSchedule = () => {
        dispatch({ type: SAVE_SCHEDULE });
    };

    const setIsCronValid = (value) => {
        dispatch({ type: SET_CRON_VALID, isCronValid: value });
    };

    const setLabel = (value) => {
        dispatch({ type: SET_LABEL, label: value });
    };

    const enableInfoEdit = () => {
        dispatch({ type: ENABLE_INFO_EDIT_MODE });
    };

    const cancelInfoEdit = () => {
        dispatch({ type: CANCEL_INFO_EDIT_MODE });
    };

    const onSaveInfo = () => {
        dispatch({ type: SAVE_INFO });
    };

    return {
        changeDataSet,
        setCron,
        setProperties,
        switchSchedule,
        switchLiveMode,
        enableEditProperties,
        cancelEditProperties,
        enableEditSchedule,
        cancelEditSchedule,
        setIsCronValid,
        onSaveProperties,
        onSaveSchedule,
        setLabel,
        enableInfoEdit,
        cancelInfoEdit,
        onSaveInfo
    };
};

const useDataSetFormReducer = (dataSetConfig) => {
    const jsonProperties = parseJsonProperties(dataSetConfig);
    const initialState = {
        name: dataSetConfig.name,
        label: dataSetConfig.label,
        cron: dataSetConfig.cron,
        jsonProperties,
        isCronEnabled: dataSetConfig.isCronEnabled,
        isLiveModeEnabled: dataSetConfig.pushMode === 'PUSH_ENABLED'
    };
    const [state, dispatch] = React.useReducer(
        dataSetConfigReducer,
        {
            ...initialState,
            originalState: initialState,
            isCronValid: true,
            isEditProperties: false,
            isEditSchedule: false,
            isEditInfo: false
        }
    );

    const actions = getActions(dispatch);

    return [state, actions];
};

export default useDataSetFormReducer;
