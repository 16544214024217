import React from 'react';

export default function AsyncSelect(props) {
    const {
        result,
        id,
        setId,
        getIdFromItem,
        getNameFromItem
    } = props;

    React.useEffect(() => {
        if (result.type === 'SUCCEEDED') {
            if (result.data.length === 0) {
                setId(undefined);
                return;
            }
            const newIds = result.data.map(getIdFromItem);
            if (!id || !newIds.find((newId) => id === newId)) {
                setId(newIds[0]);
            }
        }
    }, [result, id, setId, getIdFromItem]);

    if (result.type === 'ERROR') {
        return null;
    }
    if (result.type === 'SUCCEEDED') {
        return (
            <select className="form-select" value={id} onChange={(e) => setId(e.currentTarget.value)}>
                {result.data?.map((s) => {
                    const optionId = getIdFromItem(s);
                    const name = getNameFromItem(s);
                    return <option key={optionId} value={optionId}>{name}</option>;
                })}
            </select>
        );
    }
    return <span>Please Wait...</span>;
}
