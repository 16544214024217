import { getText } from './http';

class StagesApi {
    constructor(urlPart) {
        this.urlPart = urlPart;
    }

    getUrlSuffix(projectId, seasonId) {
        return `v1/${projectId}/football/seasons/${seasonId}/${this.urlPart}`;
    }

    getUrl(connection, seasonId, projectId) {
        return `${this.connection}/${this.getUrlSuffix(seasonId, projectId)}`;
    }

    getJson(projectId, seasonId, setJson) {
        const url = `/api/sts-mls/b2c?b2cUrl=${encodeURIComponent(this.getUrlSuffix(projectId, seasonId))}`;
        return getText(url, setJson);
    }
}

const getStagesApi = new StagesApi('stages');

export default getStagesApi;
