import React from 'react';
import {
    useHistory
} from 'react-router-dom';
import IndexPage from '../shared/components/IndexPage';
import { TABS_ROUTES } from '../projects/const';
import Dialog from '../shared/components/Dialog';
import DestinationsList from './listing/DestinationsList';

const Destinations = () => {
    const [showAddDialog, setShowAddDialog] = React.useState(false);

    const history = useHistory();

    const handleAddDestination = () => {
        setShowAddDialog(true);
    };
    const handleCloseAddDialog = () => {
        setShowAddDialog(false);
    };

    const handleDestinationDetails = (destination) => {
        const [project, code] = [destination.projectCode, destination.code];
        history.push(`/projects/${project}/${TABS_ROUTES.DESTINATIONS}/${code}`);
    };
    const goToProjects = () => {
        history.push('/projects');
    };

    return (
        <IndexPage>
            <DestinationsList
                addDestination={handleAddDestination}
                destinationDetails={handleDestinationDetails}
            />
            <Dialog
                onCancel={handleCloseAddDialog}
                onConfirm={goToProjects}
                open={showAddDialog}
                confirmText="GO TO PROJECTS"
            >
                Destination creation is available only under the PROJECTS section.
            </Dialog>
        </IndexPage>
    );
};

export default Destinations;
