import Box from '@material-ui/core/Box';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import DataSetConfigMonitoringStatus from './DataSetConfigMonitoringStatus';

const LastRun = ({
    dataSourceStatus,
    isLoading,
    dataSetConfigId,
    isEnabled
}) => {
    if (!isEnabled) {
        return null;
    }

    return (
        <Box>
            <Typography variant="subtitle2" component="span">Ingestion status:&nbsp;</Typography>
            <Typography variant="body2">
                <DataSetConfigMonitoringStatus
                    dataSourceStatus={dataSourceStatus}
                    isLoading={isLoading}
                    dataSetId={dataSetConfigId}
                />
            </Typography>
        </Box>
    );
};

export default LastRun;
