import { postData } from '../utils/api';

const PROJECTS_API_BASE_ROUTE = '/api/projects';

export const buildProjectDataSourcesBaseUrl = (project) => `${PROJECTS_API_BASE_ROUTE}/${project}/data-sources`;
export const buildGetProjectDataSourcesUrl = (project,
    code) => `${PROJECTS_API_BASE_ROUTE}/${project}/data-sources/${code}`;

export const buildDataSetConfigsUrl = (
    project, code
) => `${PROJECTS_API_BASE_ROUTE}/${project}/data-sources/${code}/dataset-configs`;

export const buildDataSetConfigInstanceUrl = (
    project, code, id
) => `${buildDataSetConfigsUrl(project, code)}/${id}`;

const buildDataSetConfigScheduleUrl = (
    project, code, id
) => `${buildDataSetConfigInstanceUrl(project, code, id)}/schedule`;

const buildDataSetConfigLiveModeUrl = (
    project, code, id
) => `${buildDataSetConfigInstanceUrl(project, code, id)}/push-mode`;

export const buildNextRunUrl = (
    projectCode, dataSourceCode, dataSetId
) => `${buildDataSetConfigScheduleUrl(projectCode, dataSourceCode, dataSetId)}/next-run`;

const buildDataSetConfigToggleScheduleUrl = (
    project, code, id
) => `${buildDataSetConfigScheduleUrl(project, code, id)}/toggle`;

const buildDataSetConfigToggleLiveModeUrl = (
    project, code, id
) => `${buildDataSetConfigLiveModeUrl(project, code, id)}/toggle`;

const buildDataSetConfigPropertiesUrl = (
    project, code, id
) => `${buildDataSetConfigInstanceUrl(project, code, id)}/properties`;

export const editDataSource = (dataSource) => postData(
    buildGetProjectDataSourcesUrl(dataSource.project, dataSource.code),
    dataSource,
    'PUT'
);

export const deleteDataSource = (dataSource) => postData(
    buildGetProjectDataSourcesUrl(dataSource.project, dataSource.code),
    {},
    'DELETE'
);

export const addDataSource = (dataSource) => postData(
    buildProjectDataSourcesBaseUrl(dataSource.project),
    dataSource
);

export const addDataSetConfig = ({
    project,
    dataSourceCode,
    ...rest
}) => postData(
    buildDataSetConfigsUrl(project, dataSourceCode),
    { ...rest }
);

export const updateDataSetConfigSchedule = ({
    project,
    dataSourceCode,
    id,
    cron
}) => postData(
    buildDataSetConfigScheduleUrl(project, dataSourceCode, id),
    { cron },
    'PUT'
);

export const updateDataSetConfigProperties = ({
    project,
    dataSourceCode,
    id,
    jsonProperties
}) => postData(
    buildDataSetConfigPropertiesUrl(project, dataSourceCode, id),
    { jsonPropertiesString: jsonProperties },
    'PUT'
);

export const toggleDataSetConfigSchedule = ({
    project,
    dataSourceCode,
    id,
    enabled
}) => postData(
    buildDataSetConfigToggleScheduleUrl(project, dataSourceCode, id),
    { enabled },
    'PUT'
);

export const toggleDataSetConfigLiveMode = ({
    project,
    dataSourceCode,
    id,
    enabled
}) => postData(
    buildDataSetConfigToggleLiveModeUrl(project, dataSourceCode, id),
    { enabled },
    'PUT'
);

export const deleteDataSetConfig = ({
    project,
    dataSourceCode,
    id
}) => postData(
    buildDataSetConfigInstanceUrl(project, dataSourceCode, id),
    {},
    'DELETE'
);

export const updateDataSetConfigInfo = ({
    project,
    dataSourceCode,
    id,
    label
}) => postData(
    buildDataSetConfigInstanceUrl(project, dataSourceCode, id),
    { label },
    'PUT'
);
