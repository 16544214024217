import React from 'react';
import Form from '@rjsf/material-ui';

const JsonPropertiesForm = ({
    schema,
    model,
    idPrefix,
    onChange,
    className,
    uiSchema,
    disabled = false
}) => {
    if (!schema) {
        return null;
    }

    const schemaObject = typeof schema === 'string'
        ? JSON.parse(schema)
        : schema;

    const handleFormChange = ({ formData }) => {
        onChange(formData);
    };

    return (
        <Form
            className={className}
            schema={schemaObject}
            uiSchema={uiSchema}
            onChange={handleFormChange}
            tagName="div"
            formData={model}
            idPrefix={idPrefix}
            disabled={disabled}
        >
            <span />
            {/* This is needed to avoid rendering of second submit button */}
        </Form>
    );
};

export default JsonPropertiesForm;
