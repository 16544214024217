import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory,
} from 'react-router-dom';
import IndexPage from '../shared/components/IndexPage';
import OperationsHubDashboard from './pages/OperationsHubDashboard';
import ProjectOpsDetails from './pages/ProjectOpsDetails';
import DataSourceOpsDetails from './pages/DataSourceOpsDetails';
import RawDataOpsDetails from './pages/RawDataOpsDetails';

function Operations() {
    const { path, url } = useRouteMatch();
    const history = useHistory();

    const goToProjectDetails = (projectCode) => {
        history.push(`${url}/${projectCode}`);
    };

    const goToDataSourceDetails = (projectCode, dataSourceCode) => {
        history.push(`${path}/${projectCode}/datasources/${dataSourceCode}`);
    };

    const goToListing = () => {
        history.push(url);
    };

    return (
        <IndexPage>
            <Switch>
                <Route exact path={path}>
                    <OperationsHubDashboard onViewDetails={goToProjectDetails} />
                </Route>
                <Route path={`${path}/:projectCode`} exact>
                    <ProjectOpsDetails
                        onGoBack={goToListing}
                        onViewDataSourceDetails={goToDataSourceDetails}
                    />
                </Route>
                <Route path={`${path}/:projectCode/datasources/:dataSourceCode`} exact>
                    <DataSourceOpsDetails onGoBack={goToProjectDetails} />
                </Route>
                <Route path={`${path}/:projectCode/datasources/:dataSourceCode/rawdata/:operationId`}>
                    <RawDataOpsDetails onGoBack={goToDataSourceDetails} />
                </Route>
            </Switch>
        </IndexPage>
    );
}

export default Operations;
