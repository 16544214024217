import React from 'react';
import AsyncSelect from '../shared/components/asyncSelect';
import { notRequested } from '../shared/async';
import { getProjects } from '../shared/api/adminApis';

export default function ProjectSelect(props) {
    const { projectId, setProjectId } = props;

    const [projects, setProjects] = React.useState(notRequested());

    React.useEffect(() => {
        if (projects.type === 'NOT_REQUESTED') {
            getProjects(setProjects);
        }
    }, [projects, setProjects]);

    return (
        <AsyncSelect
            result={projects}
            id={projectId}
            setId={setProjectId}
            getIdFromItem={(c) => c.code}
            getNameFromItem={(c) => c.code}
        />
    );
}
