import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(10),
    }
}));

export default function Empty({ text }) {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.root}
        >
            <SearchIcon fontSize="large" />
            <Typography variant="h6">{text}</Typography>
        </Grid>
    );
}
