import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';
import DataSetForm from './DataSetForm';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import { useAddDataSetConfigApi } from '../hooks';
import withDataSets from './datasets-hoc';
import { useDataSetMetadata } from './hooks';

const newDataSetConfig = {
    name: '',
    jsonProperties: '',
    cron: '',
    isCronEnabled: false,
    label: '',
};

const AddDataSetConfig = ({
    dataSource,
    back,
    onComplete,
    dataSets,
    isLoading,
    dataSetsSelectList
}) => {
    const [{
        isSaving,
        isError: isSaveError,
        error: savingError
    }, addDataSetConfig] = useAddDataSetConfigApi(() => onComplete(false));

    const [selectedDataSetName, setSelectedDataSetName] = React.useState('');
    const dataSetMetadata = useDataSetMetadata(dataSets, selectedDataSetName);

    useErrorToast(isSaveError, savingError);

    const handleSaveConfig = async (model) => {
        const fullModel = {
            project: dataSource.project,
            dataSourceCode: dataSource.code,
            name: model.name,
            jsonProperties: model.jsonProperties,
            cron: model.cron,
            isCronEnabled: model.isCronEnabled,
            label: model.label,
            liveMode: model.isLiveModeEnabled ? 'LIVE_ENABLED' : 'LIVE_DISABLED'
        };

        await addDataSetConfig(fullModel);
    };

    useEffect(() => () => {
        setSelectedDataSetName('');
    }, []);

    if (isLoading) {
        return <LinearProgress color="secondary" />;
    }

    return (
        <>
            <LinearProgress hidden={!isSaving} color="secondary" />
            <DataSetForm
                isNew
                dataSetConfig={newDataSetConfig}
                dataSetMetadata={dataSetMetadata}
                goBack={back}
                onSave={handleSaveConfig}
                dataSetsSelectList={dataSetsSelectList}
                onDataSetChange={setSelectedDataSetName}
            />
        </>
    );
};

export default withDataSets(AddDataSetConfig);

AddDataSetConfig.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    back: PropTypes.func.isRequired,
    dataSource: PropTypes.shape({
        code: PropTypes.string.isRequired,
        connection: PropTypes.string.isRequired,
        project: PropTypes.string.isRequired,
        provider: PropTypes.string.isRequired
    }).isRequired,
    dataSets: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string.isRequired,
            jsonSchema: PropTypes.string,
        })
    ).isRequired,
    onComplete: PropTypes.func.isRequired,
};
