import React from 'react';
import Dialog from './Dialog';

const DeleteConfirmationDialog = ({
    text, open, onConfirm, onCancel
}) => (
    <Dialog
        open={open}
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmText="Delete"
        confirmColor="secondary"
        title="Confirm deletion"
    >
        {text}
    </Dialog>
);

export default DeleteConfirmationDialog;
