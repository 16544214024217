/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import LoggingTable from './loggingTable';
import { Link } from 'react-router-dom';
import getLoggingDataApi from './api';

const columns = [
    {
        id: 'type',
        label: 'Type',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'operation',
        label: 'Name',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'message',
        label: 'Message',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'value',
        label: 'Raw Data',
        minWidth: 170,
        align: 'center',
        format: (value) => <Link to={{pathname: '/log-details', state: { data: value }, }}>View</Link>
    },
    {
        id: 'messageRecievedTime',
        label: 'Message Recieved',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'timeStamp',
        label: 'Timestamp',
        minWidth: 170,
        align: 'center',
    },
];

export default function LoggingMonitor(props) {
    const [
        { data, isLoading, isError, totalCount, pageSize, currentPage },
        setPageSize,
        setPage,
        fetchData
    ] = getLoggingDataApi();

    return (
        <Grid container justifyContent="center">
            <Grid item xs={10}>
                <Typography justifyContent="center" variant="h4" gutterBottom>
                    Logging Monitor
                </Typography>
                {isLoading && <LinearProgress role="progressbar" />}
                <LoggingTable
                    columns={columns}
                    {...props}
                    rows={data?.data}
                    page={currentPage}
                    rowsPerPage={pageSize}
                    disablePagination={!data}
                    setPage={setPage}
                    setRowsPerPage={setPageSize}
                    totalCount={totalCount}
                />
            </Grid>
            <Grid item xs={10}>
                <Button onClick={ () => fetchData() }>Refresh data</Button>
            </Grid>
        </Grid>
    );
}
