import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const SnackbarAlert = ({
    open, text, severity = 'error', handleClose
}) => (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity={severity} elevation={6} variant="filled" onClose={handleClose}>
            {text}
        </Alert>
    </Snackbar>
);

export default SnackbarAlert;
