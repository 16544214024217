import { postData } from '../utils/api';

export const PROJECTS_API_BASE_ROUTE = '/api/projects';

export const buildGetProjectUrl = (projectCode) => `${PROJECTS_API_BASE_ROUTE}/${projectCode}`;

export const editProject = (project) => postData(
    buildGetProjectUrl(project.code),
    project,
    'PUT'
);

export const deleteProject = (projectCode) => postData(
    buildGetProjectUrl(projectCode),
    {},
    'DELETE'
);

export const addProject = (project) => postData(PROJECTS_API_BASE_ROUTE, project);
