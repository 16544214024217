import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Divider,
    Grid
} from '@material-ui/core';

import DataSetConfigOpsList from '../listing/DataSetConfigOpsList';
import Captioned from '../../shared/components/Captioned';
import IngestionStatusChip from '../../shared/components/IngestionStatusChip';
import { ingestionAggregatedStatuses } from '../../shared/const';

const useStyles = makeStyles((theme) => ({
    headingText: {
        textTransform: 'uppercase'
    },
    header: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    tableHeader: {
        marginBottom: theme.spacing(1)
    }
}));

const DataSourceOpsDetailsView = ({
    dataSourceDetails: {
        dataSourceName,
        providerCode,
        status,
        dataSetConfigs,
        key
    }
}) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h4" className={classes.headingText}>Data Source Monitoring Details</Typography>
            <Divider />
            <Grid container className={classes.header}>
                <Grid item xs={4}>
                    <Captioned caption="Data Source Code">
                        <Typography variant="h5">{dataSourceName}</Typography>
                    </Captioned>
                </Grid>
                <Grid item xs={4}>
                    <Captioned caption="Provider Code">
                        <Typography variant="h5">{providerCode}</Typography>
                    </Captioned>
                </Grid>
                <Grid item xs={4}>
                    <Captioned caption="Aggregated Status">
                        <IngestionStatusChip status={status} />
                    </Captioned>
                </Grid>
            </Grid>
            <Typography className={classes.tableHeader} variant="h6">Data Set Configurations</Typography>
            <DataSetConfigOpsList keyInfo={key} dataSetConfigs={dataSetConfigs} />
        </>
    );
};

DataSourceOpsDetailsView.propTypes = {
    dataSourceDetails: PropTypes.shape({
        dataSourceName: PropTypes.string.isRequired,
        providerCode: PropTypes.string.isRequired,
        status: PropTypes.oneOf(ingestionAggregatedStatuses).isRequired,
        dataSetConfigs: PropTypes.arrayOf(PropTypes.shape({
            dataSetConfigId: PropTypes.number.isRequired,
            dataSetName: PropTypes.string,
            adapterCode: PropTypes.string.isRequired,
            cron: PropTypes.string,
            lastRunInfo: PropTypes.shape({
                operationId: PropTypes.string.isRequired,
                status: PropTypes.oneOf(ingestionAggregatedStatuses).isRequired,
                timeStamp: PropTypes.string,
                dataInfo: PropTypes.shape({
                    description: PropTypes.string,
                }),
            }),
            aggregateStatus: PropTypes.oneOf(ingestionAggregatedStatuses).isRequired
        })),
        key: PropTypes.shape({
            dataSourceCode: PropTypes.string.isRequired,
            projectCode: PropTypes.string.isRequired
        })
    }).isRequired
};

export default DataSourceOpsDetailsView;
