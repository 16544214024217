import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import EditButtonsGroup from '../../shared/components/EditButtonsGroup';
import JsonPropertiesForm from '../../shared/components/JsonPropertiesForm';
import Select from '../../shared/components/Select';
import { useShowToastContext } from '../../shared/toast/ToastProvider';
import Actions from './Actions';
import Cron from './Cron';
import SchedulerSettings from './SchedulerSettings';
import useDataSetFormReducer from './DataSetForm.reducer';
import LastRun from './LastRun';
import NextRun from './NextRun';
import { saveSectionsHelper } from './saveSectionsHelper';
import SwitchToggle from './SwitchToggle';

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: theme.spacing(4)
    },
    propertiesForm: {
        marginTop: theme.spacing(3)
    }
}));

const DataSetForm = ({
    dataSetConfig,
    dataSetMetadata,
    dataSetsSelectList,
    onDataSetChange,
    isNew,
    onSave,
    goBack,
    onDelete,
    onRunIngestion,
    isSwitchSaving,
    isScheduleSaving,
    isPropertiesSaving,
    isInfoSaving,
    dataSourceStatus,
    isStatusLoading
}) => {
    const classes = useStyles();
    const showAlert = useShowToastContext();
    const [{
        name,
        cron,
        label,
        jsonProperties,
        isCronEnabled,
        isEditProperties,
        isEditSchedule,
        isEditInfo,
        isCronValid,
        isLiveModeEnabled,
        originalState: { cron: savedCron }
    }, actions] = useDataSetFormReducer(dataSetConfig);
    const saveHandler = saveSectionsHelper(onSave);

    const LIVE_MODE = 'PUSH';

    const handleSaveNewClick = () => {
        const model = {
            name,
            jsonProperties: JSON.stringify(jsonProperties),
            cron,
            isCronEnabled,
            label,
            isLiveModeEnabled
        };
        onSave(model);
    };

    const handleSaveProperties = () => {
        saveHandler.updateProperties(
            { jsonProperties: JSON.stringify(jsonProperties) },
            actions.onSaveProperties
        );
    };

    const validationSchema = yup.object({
        jsonProperites: yup
            .string(),
        cron: yup
            .string()
            .trim()
            .ensure()
            .test(
                'mathces regex',
                'Wrong cron-expression',
                (value) => {
                    if (value === null || value === 'undefined') {
                        return true;
                    }

                    const matches = value.match(/((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*|\?) ?){5,7})/g);

                    return !matches || (matches.length > 0 && matches[0].length === value.length);
                }
            )
    });

    const formik = useFormik({
        initialValues: {
            label: '',
            jsonProperties: '',
            cron: ''
        },
        validationSchema,
        validateOnChange: false,
        onSubmit: () => {
            if (isNew) {
                handleSaveNewClick();
            } else {
                handleSaveProperties();
            }
        }
    });

    const handleDelete = () => onDelete(dataSetConfig.id);

    const handleCronChange = async (value) => {
        actions.setCron(value);

        await formik.setValues({
            ...formik.values,
            cron: value
        });

        await formik.setTouched({
            ...formik.touched,
            cron: true
        });
    };

    const handleJsonPropertiesChange = async (value) => {
        actions.setProperties(value);

        await formik.setValues({
            ...formik.values,
            jsonProperties: JSON.stringify(value)
        });

        await formik.setTouched({
            ...formik.touched,
            jsonProperties: true
        });

        actions.setIsCronValid(!(formik.touched.cron
            && Boolean(formik.errors.cron)));
    };

    const switchSchedule = () => {
        if (!isNew) {
            const verb = isCronEnabled ? 'disabled' : 'enabled';
            saveHandler.switchSchedule(
                !isCronEnabled,
                () => {
                    showAlert(`Schedule has been ${verb}.`, false);
                    actions.switchSchedule();
                }
            );
        } else {
            actions.switchSchedule();
        }
    };

    const switchLiveMode = () => {
        if (!isNew) {
            const verb = isLiveModeEnabled ? 'disabled' : 'enabled';
            saveHandler.switchLiveMode(
                !isLiveModeEnabled,
                () => {
                    showAlert(`Live Mode has been ${verb}.`, false);
                    actions.switchLiveMode();
                }
            );
        } else {
            actions.switchLiveMode();
        }
    };

    const handleSaveSchedule = () => {
        saveHandler.updateSchedule({ cron }, actions.onSaveSchedule);
    };

    const handleDataSetChanged = (code) => {
        onDataSetChange(code);
        actions.changeDataSet(code);
    };

    const handleLabelChange = ({ target: { value } }) => {
        actions.setLabel(value);
    };

    const handleSaveInfo = () => {
        saveHandler.updateInfo({ label }, actions.onSaveInfo);
    };

    const renderBottomButtonsGroup = () => {
        if (isNew) {
            return (
                <EditButtonsGroup
                    onCancel={goBack}
                    isEdit
                    name="new-data-set-config"
                    isEditDisabled={false}
                    isSaveDisabled={false}
                    isCancelDisabled={false}
                    isSaving={false}
                    onSave={formik.onSubmit}
                />
            );
        }

        return (
            <EditButtonsGroup
                onCancel={actions.cancelEditSchedule}
                onEdit={actions.enableEditSchedule}
                isEdit={isEditSchedule}
                name="data-set-config-schedule"
                isEditDisabled={false}
                isSaveDisabled={false}
                isCancelDisabled={false}
                isSaving={isScheduleSaving}
                onSave={handleSaveSchedule}
            />
        );
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h4" gutterBottom noWrap>
                            {isNew ? 'Add Data Set Config' : 'Data Set Config Details'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {
                            !isNew && (
                                <Actions
                                    onDelete={handleDelete}
                                    runIngestion={onRunIngestion}
                                    hidden={isNew}
                                    isRunIngestionAvailable={(!!dataSetMetadata
                                        && dataSetMetadata.mode !== LIVE_MODE)}
                                />
                            )
                        }
                    </Grid>
                </Grid>
                <Select
                    disabled={!isNew}
                    label="Data Set"
                    margin="normal"
                    setValue={handleDataSetChanged}
                    value={name}
                    values={dataSetsSelectList.sort((a, b) => a.value.localeCompare(b.value))}
                />
                <TextField
                    label="Label"
                    margin="normal"
                    value={label}
                    onChange={handleLabelChange}
                    disabled={!isNew && !isEditInfo}
                    fullWidth
                />
                <EditButtonsGroup
                    onCancel={actions.cancelInfoEdit}
                    onEdit={actions.enableInfoEdit}
                    isEdit={isEditInfo}
                    hidden={isNew}
                    name="data-set-config"
                    isEditDisabled={false}
                    isSaveDisabled={false}
                    isCancelDisabled={false}
                    isSaving={isInfoSaving}
                    onSave={handleSaveInfo}
                />
                {
                    Object.keys(dataSetMetadata).length !== 0
                    && Object.keys(dataSetMetadata.jsonSchema).length !== 0
                    && (
                        <Box aria-label="properties" className={classes.section}>
                            <JsonPropertiesForm
                                idPrefix="dataset-properties"
                                schema={dataSetMetadata.jsonSchema}
                                model={jsonProperties}
                                uiSchema={dataSetMetadata.uiSchema}
                                onChange={handleJsonPropertiesChange}
                                className={classes.propertiesForm}
                                disabled={!isEditProperties && !isNew}
                            />
                            <EditButtonsGroup
                                onCancel={actions.cancelEditProperties}
                                onEdit={actions.enableEditProperties}
                                isEdit={isEditProperties}
                                hidden={isNew}
                                name="data-set-config-properties"
                                isEditDisabled={false}
                                isSaveDisabled={false}
                                isCancelDisabled={false}
                                isSaving={isPropertiesSaving}
                                onSave={formik.onSubmit}
                            />
                        </Box>
                    )
                }
                {
                    Object.keys(dataSetMetadata).length !== 0 && dataSetMetadata.mode !== LIVE_MODE
                    && (
                        <>
                            <Box aria-label="scheduling" className={classes.section}>
                                <Grid container justifyContent="space-between" alignItems="flex-start">
                                    <Typography variant="h5" noWrap>Scheduling</Typography>
                                    <Grid item>
                                        <SwitchToggle
                                            isPropertyEnabled={isCronEnabled}
                                            onChange={switchSchedule}
                                            isSaving={isSwitchSaving}
                                            propName="schedule"
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Box mb={2}>
                                    <SchedulerSettings
                                        cron={cron}
                                        onChange={handleCronChange}
                                        disabled={!isNew && !isEditSchedule}
                                        isValid={isCronValid && !formik.errors.cron}
                                        setIsValid={actions.setIsCronValid}
                                        errorMessage={formik.errors.cron}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <Cron
                                        cron={cron}
                                        onChange={handleCronChange}
                                        disabled={!isNew && !isEditSchedule}
                                        isValid={isCronValid && !formik.errors.cron}
                                        setIsValid={actions.setIsCronValid}
                                        errorMessage={formik.errors.cron}
                                    />
                                </Box>
                                <Box>
                                    <LastRun
                                        isLoading={isStatusLoading}
                                        isEnabled={!isNew}
                                        dataSourceStatus={dataSourceStatus}
                                        dataSetConfigId={dataSetConfig.id}
                                    />
                                    <NextRun
                                        savedCron={savedCron}
                                        isEnabled={!isNew && isCronEnabled}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <Grid item>
                                        {renderBottomButtonsGroup()}
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )
                }
                {
                    Object.keys(dataSetMetadata).length !== 0 && dataSetMetadata.mode === LIVE_MODE
                    && (
                        <>
                            <Box aria-label="livemode" className={classes.section}>
                                <Typography variant="h5" noWrap>
                                    Live Mode
                                </Typography>
                                <Divider />
                            </Box>
                            <Box>
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <SwitchToggle
                                        isPropertyEnabled={isLiveModeEnabled}
                                        onChange={switchLiveMode}
                                        isSaving={isSwitchSaving}
                                        propName="live mode"
                                    />
                                    {
                                        isNew ? (
                                            <Grid item>
                                                {renderBottomButtonsGroup()}
                                            </Grid>
                                        ) : null
                                    }
                                </Grid>
                            </Box>
                        </>
                    )
                }
            </form>
        </>
    );
};

DataSetForm.propTypes = {
    dataSetsSelectList: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ).isRequired,
    onDataSetChange: PropTypes.func,
    isNew: PropTypes.bool,
    dataSetConfig: PropTypes.shape({
        name: PropTypes.string.isRequired,
        cron: PropTypes.string.isRequired,
        jsonProperties: PropTypes.string.isRequired,
        isCronEnabled: PropTypes.bool.isRequired,
        label: PropTypes.string
    }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    dataSetMetadata: PropTypes.object.isRequired,
    goBack: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onRunIngestion: PropTypes.func,
    isSwitchSaving: PropTypes.bool,
    isScheduleSaving: PropTypes.bool,
    isPropertiesSaving: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    dataSourceStatus: PropTypes.object,
    isStatusLoading: PropTypes.bool
};

DataSetForm.defaultProps = {
    onDataSetChange: () => {
    },
    isNew: false,
    onDelete: () => {
    },
    onRunIngestion: () => {
    },
    isSwitchSaving: false,
    isScheduleSaving: false,
    isPropertiesSaving: false,
    dataSourceStatus: {},
    isStatusLoading: false
};

export default DataSetForm;
