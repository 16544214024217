import { Grid } from '@material-ui/core';
import React from 'react';
import MatchSelect from './matchSelect';

export default function MatchSelector(props) {
    const { matchPlatformId } = props;

    return (
        <Grid container columns={11}>
            <Grid item xs={1}>
                Match:
            </Grid>
            <Grid item xs={5} style={{ paddingRight: '0.5rem' }}>
                <MatchSelect {...props} />
            </Grid>
            <Grid item xs={5}>
                {matchPlatformId}
            </Grid>
        </Grid>
    );
}
