import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';

const GreenSuccessIcon = withStyles(({ palette }) => ({
    root: {
        color: palette.success.main
    }
}))((props) => <CheckCircleIcon {...props} />);

export default GreenSuccessIcon;
