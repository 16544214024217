import React from 'react';
import { matchApi } from '../shared/api/stsSeasonApis';
import { convert, notRequested } from '../shared/async';
import AsyncSelect from '../shared/components/asyncSelect';

export default function MatchSelect(props) {
    const {
        connection,
        competitionId,
        seasonId,
        matchId,
        setMatchId
    } = props;

    const [matches, setMatches] = React.useState(notRequested());

    const getMatchName = (match) => {
        const date = match.PlannedKickoffTime.toLocaleDateString();
        return `${match.MatchId} - ${match.HomeTeamName} v ${match.GuestTeamName} (${date})`;
    };

    const sortMatches = (matchesToSort) => {
        const sortFunction = (a, b) => a.PlannedKickoffTime.valueOf()
            - b.PlannedKickoffTime.valueOf();
        return matchesToSort.sort(sortFunction);
    };

    React.useEffect(() => {
        if (connection && competitionId && seasonId) {
            matchApi.getData(connection, competitionId, seasonId, (result) => {
                const sortedMatches = convert(result, sortMatches);
                setMatches(sortedMatches);
            });
        }
    }, [connection, competitionId, seasonId, setMatches]);

    return (
        <AsyncSelect
            result={matches}
            id={matchId}
            setId={setMatchId}
            getIdFromItem={(s) => s.MatchId}
            getNameFromItem={getMatchName}
        />
    );
}
