import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import getPlatformId from '../shared/api/idMapperApis';
import CompetitionAndSeasonSelector from './competitionAndSeasonSelector';
import Tabs from './tabs';

export default function Apis() {
    const [projectId, setProjectId] = React.useState(undefined);
    const [connection, setConnection] = React.useState(undefined);
    const [competitionId, setCompetitionId] = React.useState(undefined);
    const [competitionPlatformId, setCompetitionPlatformId] = React.useState(undefined);
    const [seasonId, setSeasonId] = React.useState(undefined);
    const [seasonPlatformId, setSeasonPlatformId] = React.useState(undefined);
    const [teamId, setTeamId] = React.useState(undefined);
    const [teamPlatformId, setTeamPlatformId] = React.useState(undefined);
    const [matchId, setMatchId] = React.useState(undefined);
    const [matchPlatformId, setMatchPlatformId] = React.useState(undefined);

    React.useEffect(() => {
        console.log(projectId);
    }, [projectId]);

    React.useEffect(() => {
        console.log(connection);
    }, [connection]);

    React.useEffect(() => {
        if (projectId && connection?.provider?.code && competitionId) {
            getPlatformId(`${projectId}::${connection.provider.code}::Football_Competition::${competitionId}`, (async) => {
                if (async.type === 'SUCCEEDED') {
                    setCompetitionPlatformId(async.data);
                } else {
                    setCompetitionPlatformId(undefined);
                }
            });
        }
    }, [projectId, connection?.provider?.code, competitionId, setCompetitionPlatformId]);

    React.useEffect(() => {
        if (projectId && connection?.provider?.code && competitionId && seasonId) {
            getPlatformId(`${projectId}::${connection.provider.code}::Football_Season::${competitionId}_${seasonId}`, (async) => {
                if (async.type === 'SUCCEEDED') {
                    setSeasonPlatformId(async.data);
                } else {
                    setSeasonPlatformId(undefined);
                }
            });
        }
    }, [projectId, connection?.provider?.code, competitionId, seasonId, setSeasonPlatformId]);

    React.useEffect(() => {
        if (projectId && connection?.provider?.code && teamId) {
            getPlatformId(`${projectId}::${connection.provider.code}::Football_Team::${teamId}`, (async) => {
                if (async.type === 'SUCCEEDED') {
                    setTeamPlatformId(async.data);
                } else {
                    setTeamPlatformId(undefined);
                }
            });
        }
    }, [projectId, connection?.provider?.code, teamId, setTeamPlatformId]);

    React.useEffect(() => {
        if (projectId && connection?.provider?.code && matchId) {
            getPlatformId(`${projectId}::${connection.provider.code}::Football_Match::${matchId}`, (async) => {
                if (async.type === 'SUCCEEDED') {
                    setMatchPlatformId(async.data);
                } else {
                    setMatchPlatformId(undefined);
                }
            });
        }
    }, [projectId, connection?.provider?.code, matchId, setMatchPlatformId]);

    return (
        <Grid container justifyContent="center" columnSpacing={{ xs: 2 }}>
            <Grid item xs={10}>
                <Typography variant="h4" gutterBottom>
                    STS and SDP APIs
                </Typography>
                <Grid container columns={11}>
                    <Grid item xs={1} />
                    <Grid item xs={5}>
                        <Typography variant="h6" gutterBottom>
                            STS Details
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" gutterBottom>
                            SDP Details
                        </Typography>
                    </Grid>
                </Grid>
                <CompetitionAndSeasonSelector
                    projectId={projectId}
                    setProjectId={setProjectId}
                    connection={connection}
                    setConnection={setConnection}
                    competitionId={competitionId}
                    setCompetitionId={setCompetitionId}
                    competitionPlatformId={competitionPlatformId}
                    seasonId={seasonId}
                    setSeasonId={setSeasonId}
                    seasonPlatformId={seasonPlatformId}
                />
                <Tabs
                    projectId={projectId}
                    connection={connection}
                    competitionId={competitionId}
                    competitionPlatformId={competitionPlatformId}
                    seasonId={seasonId}
                    seasonPlatformId={seasonPlatformId}
                    teamId={teamId}
                    setTeamId={setTeamId}
                    teamPlatformId={teamPlatformId}
                    matchId={matchId}
                    setMatchId={setMatchId}
                    matchPlatformId={matchPlatformId}
                />
            </Grid>
        </Grid>
    );
}
