import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import {
    Route,
    Link as RouterLink
} from 'react-router-dom';

const useStyles = makeStyles(() => ({
    breadcrumb: {
        textTransform: 'capitalize',
    }
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const BreadcrumbsComponent = () => {
    const classes = useStyles();

    return (
        <Route>
            {({ location }) => {
                const pathnames = location.pathname.split('/').filter((x) => x);
                return (
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        {pathnames.map((value, index) => {
                            const last = index === pathnames.length - 1;
                            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                            return last ? (
                                <Typography className={classes.breadcrumb} color="textPrimary" key={to}>
                                    {value}
                                </Typography>
                            ) : (
                                <LinkRouter className={classes.breadcrumb} color="inherit" to={to} key={to}>
                                    {value}
                                </LinkRouter>
                            );
                        })}
                    </Breadcrumbs>
                );
            }}
        </Route>
    );
};

export default BreadcrumbsComponent;
