import { postData } from '../utils/api';

export const DESTINATIONS_API_ROUTE = '/api/destinations';
const PROJECT_DESTINATIONS_API_ROUTE = '/api/projects';

export const buildGetProjectDestinationsUrl = (project) => `${PROJECT_DESTINATIONS_API_ROUTE}/${project}/destinations`;

export const buildGetDestinationUrl = (project, code) => `${buildGetProjectDestinationsUrl(project)}/${code}`;

export const editDestination = (destination) => postData(
    buildGetDestinationUrl(destination.project, destination.code),
    destination,
    'PUT'
);

export const deleteDestination = (destination) => postData(
    buildGetDestinationUrl(destination.project, destination.code),
    {},
    'DELETE'
);

export const addDestination = (destination) => postData(DESTINATIONS_API_ROUTE, destination);
