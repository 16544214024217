import Typography from '@material-ui/core/Typography';
import React from 'react';
import Box from '@material-ui/core/Box';
import DataTable from '../../shared/components/DataTable';
import DataSetConfigMonitoringStatus from './DataSetConfigMonitoringStatus';

const columns = [
    {
        id: 'name',
        label: 'Dataset',
        minWidth: 170,
        align: 'left'
    },
    {
        id: 'lastRunInfo',
        label: 'Ingestion Status',
        minWidth: 170
    }
];

const createCellRenderer = (isLoading, dataSourceStatus) => (column, value, row) => {
    const { id } = column;
    if (id !== 'lastRunInfo') {
        return value;
    }

    return (
        <DataSetConfigMonitoringStatus
            isLoading={isLoading}
            dataSourceStatus={dataSourceStatus}
            dataSetId={row.id}
        />
    );
};

const DataSetConfigsListing = ({
    dataSetConfigs,
    add,
    details,
    dataSourceStatus,
    isStatusLoading
}) => {
    const handleDetails = (row) => {
        details(row);
    };

    const rows = dataSetConfigs.map((cfg) => ({
        code: cfg.id,
        ...cfg
    }));

    const cellRenderer = React.useCallback(
        createCellRenderer(isStatusLoading, dataSourceStatus),
        [isStatusLoading, dataSourceStatus]
    );

    return (
        <Box>
            <Typography variant="h4" gutterBottom noWrap>
                Data Sets
            </Typography>
            <DataTable
                columns={columns}
                disablePagination
                rows={rows}
                add={add}
                details={handleDetails}
                tableName="data-set-configs"
                cellRenderer={cellRenderer}
            />
        </Box>
    );
};

export default DataSetConfigsListing;
