import { Grid } from '@material-ui/core';
import React from 'react';
import { notRequested } from '../shared/async';
import AsyncXmlAndJson from '../shared/components/asyncXmlAndJson';

const StandingsTab = (props) => {
    const {
        seasonId,
        projectId,
        competitionId,
        seasonPlatformId,
        getB2cStandingsUrl,
        getB2cStandingsJson,
        getStsStandingsUrl,
        getStsStandingsXml,
        competitionPlatformId,
    } = props;

    const [b2cStandingsData, setb2cStandingsData] = React.useState(notRequested());
    const [stsStandingData, setStsStandingData] = React.useState(notRequested());

    const b2cUrl = React.useMemo(() => {
        if (projectId && seasonId) {
            return getB2cStandingsUrl(seasonPlatformId, projectId);
        }
        return undefined;
    }, [competitionPlatformId, projectId, getB2cStandingsUrl]);

    const stsUrl = React.useMemo(() => {
        if (competitionId) {
            return getStsStandingsUrl(competitionId,);
        }
        return undefined;
    }, [competitionId, seasonId, getStsStandingsUrl]);

    React.useEffect(() => {
        if (projectId && seasonPlatformId) {
            getB2cStandingsJson(
                seasonPlatformId,
                projectId,
                setb2cStandingsData
            );
        } else {
            setb2cStandingsData(notRequested());
        }
    }, [projectId, setb2cStandingsData, seasonPlatformId]);

    React.useEffect(() => {
        if (competitionId && seasonId) {
            getStsStandingsXml(competitionId, setStsStandingData);
        } else {
            setStsStandingData(notRequested());
        }
    }, [competitionId, seasonId, getStsStandingsXml, setStsStandingData]);

    return (
        <>
            <Grid container columns={11}>
                <Grid item xs={1}>
                    URLs:
                </Grid>
                <Grid item xs={5} style={{ paddingRight: '0.5rem' }}>
                    <a href={stsUrl} target="_blank" rel="noreferrer">{stsUrl}</a>
                </Grid>
                <Grid item xs={5} style={{ overflowWrap: 'anywhere' }}>
                    <a href={b2cUrl} target="_blank" rel="noreferrer">
                        {b2cUrl}
                    </a>
                </Grid>
            </Grid>
            <AsyncXmlAndJson xml={stsStandingData} json={b2cStandingsData} />
        </>
    );
};

export default StandingsTab;
