import React from 'react';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';

const DeleteButton = ({ disabled, itemName, onDelete }) => {
    const [open, setOpen] = React.useState(false);

    const text = `This will delete ${itemName} immediately. Are you sure you want to continue?`;
    const handleCancel = () => setOpen(false);
    const handleClick = () => setOpen(true);

    const handleConfirm = () => {
        setOpen(false);
        onDelete();
    };

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleClick}
                disabled={disabled}
            >
                Delete
            </Button>
            <DeleteConfirmationDialog
                text={text}
                open={open}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        </>
    );
};

export default DeleteButton;
