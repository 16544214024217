import React from 'react';
import Routes from './navigation/Routes';
import Menu from './navigation/Menu';

function App() {
    return (
        <Menu>
            <Routes />
        </Menu>
    );
}

export default App;
