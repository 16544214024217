import React from 'react';
import getStagesApi from '../../shared/api/b2cStagesApis';
import { notRequested } from '../../shared/async';
import AsyncSelect from '../../shared/components/asyncSelect';

export default function StageSelect(props) {
    const {
        connection,
        competitionId,
        seasonPlatformId,
        projectId,
        seasonId,
        setStageId,
        stageId
    } = props;

    const [group, setGroup] = React.useState(notRequested());
    const [stages, setStages] = React.useState(notRequested());

    React.useEffect(() => {
        if (projectId && seasonId) {
            getStagesApi.getJson(projectId, seasonPlatformId, setGroup);
        }
    }, [connection, competitionId, seasonPlatformId, setGroup]);

    React.useEffect(() => {
        if (group && group.data) {
            const data = JSON.parse(group.data);
            const newObj = JSON.parse(JSON.stringify(group));
            newObj.data = data.stages;
            setStages(newObj);
        }
    }, [group]);

    return (
        stages
        && (
            <AsyncSelect
                result={stages}
                id={stageId}
                setId={setStageId}
                getIdFromItem={(s) => s.stageId}
                getNameFromItem={(n) => n.name}
            />
        )
    );
}
