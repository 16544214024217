import { Grid } from '@material-ui/core';
import React from 'react';
import { notRequested } from '../shared/async';
import AsyncXmlAndJson from '../shared/components/asyncXmlAndJson';

export default function SeasonTab(props) {
    const {
        projectId,
        competitionId,
        seasonId,
        seasonPlatformId,
        getStsSeasonDataUrl,
        getStsSeasonDataXml,
        getB2cSeasonDataUrl,
        getB2cSeasonDataJson,
    } = props;

    const [stsSeasonData, setStsSeasonData] = React.useState(notRequested());
    const [b2cSeasonData, setB2cSeasonData] = React.useState(notRequested());

    const stsUrl = React.useMemo(() => {
        if (competitionId && seasonId) {
            return getStsSeasonDataUrl(competitionId, seasonId);
        }
        return undefined;
    }, [competitionId, seasonId, getStsSeasonDataUrl]);

    const b2cUrl = React.useMemo(() => {
        if (projectId && seasonPlatformId) {
            return getB2cSeasonDataUrl(projectId, seasonPlatformId);
        }
        return undefined;
    }, [projectId, seasonPlatformId, getStsSeasonDataUrl]);

    React.useEffect(() => {
        if (competitionId && seasonId) {
            getStsSeasonDataXml(competitionId, seasonId, setStsSeasonData);
        } else {
            setStsSeasonData(notRequested());
        }
    }, [competitionId, seasonId, getStsSeasonDataXml, setStsSeasonData]);

    React.useEffect(() => {
        if (projectId && seasonPlatformId) {
            getB2cSeasonDataJson(projectId, seasonPlatformId, setB2cSeasonData);
        } else {
            setB2cSeasonData(notRequested());
        }
    }, [projectId, seasonPlatformId, getB2cSeasonDataJson, setB2cSeasonData]);

    return (
        <>
            <Grid container columns={11}>
                <Grid item xs={1}>
                    URLs:
                </Grid>
                <Grid item xs={5} style={{ paddingRight: '0.5rem' }}>
                    <a href={stsUrl} target="_blank" rel="noreferrer">{stsUrl}</a>
                </Grid>
                <Grid item xs={5} style={{ overflowWrap: 'anywhere' }}>
                    <a href={b2cUrl} target="_blank" rel="noreferrer">{b2cUrl}</a>
                </Grid>
            </Grid>
            <AsyncXmlAndJson xml={stsSeasonData} json={b2cSeasonData} />
        </>
    );
}
