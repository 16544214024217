import React from 'react';
import { teamApi } from '../shared/api/stsSeasonApis';
import { notRequested } from '../shared/async';
import AsyncSelect from '../shared/components/asyncSelect';

export default function TeamSelect(props) {
    const {
        connection,
        competitionId,
        seasonId,
        teamId,
        setTeamId
    } = props;

    const [teams, setTeams] = React.useState(notRequested());

    React.useEffect(() => {
        if (connection && competitionId && seasonId) {
            teamApi.getData(connection, competitionId, seasonId, setTeams);
        }
    }, [connection, competitionId, seasonId, setTeams]);

    return (
        <AsyncSelect result={teams} id={teamId} setId={setTeamId} getIdFromItem={(s) => s.ClubId} getNameFromItem={(s) => `${s.ClubId} - ${s.ClubName}`} />
    );
}
