import React from 'react';
import { useGetApi, useSaveApi } from '../../shared/hooks/fetchingHooks';
import parseJsonProperties from '../../utils/json-properties-parser';
import { buildNextRunUrl } from '../api';
import { DATASETS_API_BASE_ROUTE, runIngestion } from './api';

export const useProviderDataSetsList = (providerCode) => useGetApi(
    DATASETS_API_BASE_ROUTE(providerCode),
    []
);

export const useRunIngestionApi = (onComplete) => useSaveApi(runIngestion, onComplete);

export const useDataSetMetadata = (dataSets, dataSetName) => {
    const [metadata, setMetadata] = React.useState({});
    const [schemaString, setSchemaString] = React.useState('');
    React.useEffect(() => {
        let dataSetMetadata = {};
        if (dataSets && dataSets.length) {
            const selectedDataSet = dataSets
                .find((dataSet) => (dataSet.code === dataSetName));
            if (selectedDataSet) {
                const jsonSchema = parseJsonProperties({
                    jsonProperties: selectedDataSet.jsonSchema
                });
                const { uiSchema } = jsonSchema;
                if (jsonSchema.uiSchema) {
                    delete jsonSchema.uiSchema;
                }
                dataSetMetadata = {
                    jsonSchema,
                    uiSchema,
                    mode: selectedDataSet.mode
                };

                if (selectedDataSet.jsonSchema !== schemaString) {
                    setSchemaString(selectedDataSet.jsonSchema);
                }
                setMetadata(dataSetMetadata);
            }
        }
    }, [dataSets.length, dataSetName]);

    return metadata;
};

export const useGetNextRunApi = (projectCode, dataSourceCode, dataSetId) => {
    let startingUrl = buildNextRunUrl(projectCode, dataSourceCode, dataSetId);
    if (!projectCode || !dataSourceCode || !dataSetId) {
        startingUrl = null;
    }

    const [state, setUrl, forceReload] = useGetApi(startingUrl, { date: '' });
    const urlUpdateHandler = (newProj, newDs, newDataSetId) => setUrl(
        buildNextRunUrl(newProj, newDs, newDataSetId)
    );

    return [state, urlUpdateHandler, forceReload];
};

const intervalDelay = 30000;

export const useGetDataSourceMonitoringStatus = (projectCode, dataSourceCode, numberOfDatasets) => {
    // TODO: reuse method from the other feature branch when it will be merged to master
    const startingUrl = `/api/monitoring/${projectCode}/datasources/${dataSourceCode}/status`;
    const [state, , forceReload] = useGetApi(startingUrl, { dataSetConfigs: [] });
    React.useEffect(() => {
        let interval = null;

        if (numberOfDatasets > 0) {
            interval = setInterval(forceReload, intervalDelay);
        }

        return function cleanup() {
            clearTimeout(interval);
        };
    }, [numberOfDatasets]);
    return [state, forceReload];
};
