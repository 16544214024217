import { Grid } from '@material-ui/core';
import React from 'react';
import { notRequested } from '../shared/async';
import AsyncXmlAndMultipleJson from '../shared/components/asyncXmlAndMultipleJson';

export default function SeasonStatsTab(props) {
    const {
        projectId,
        competitionId,
        seasonId,
        seasonPlatformId,
        getStsSeasonDataUrl,
        getStsSeasonDataXml,
        getB2cSeasonData,
    } = props;

    const [stsSeasonData, setStsSeasonData] = React.useState(notRequested());
    const [b2cSeasonData, setB2cSeasonData] = React.useState(getB2cSeasonData.map(((d) => ({
        name: d.name,
        data: notRequested()
    }))));

    const stsUrl = React.useMemo(() => {
        if (competitionId && seasonId) {
            return getStsSeasonDataUrl(competitionId, seasonId);
        }
        return undefined;
    }, [competitionId, seasonId, getStsSeasonDataUrl]);

    const b2cUrls = React.useMemo(() => {
        if (projectId && seasonPlatformId) {
            return getB2cSeasonData.map(
                (d) => d.getSeasonDataUrl(projectId, seasonPlatformId)
            );
        }
        return [];
    }, [projectId, seasonPlatformId, getB2cSeasonData]);

    React.useEffect(() => {
        if (competitionId && seasonId) {
            getStsSeasonDataXml(competitionId, seasonId, setStsSeasonData);
        } else {
            setStsSeasonData(notRequested());
        }
    }, [competitionId, seasonId, getStsSeasonDataXml, setStsSeasonData]);

    React.useEffect(() => {
        if (projectId && seasonPlatformId) {
            for (let i = 0; i < getB2cSeasonData.length; i += 1) {
                const {
                    name,
                    getSeasonDataJson
                } = getB2cSeasonData[i];

                getSeasonDataJson(
                    projectId,
                    seasonPlatformId,
                    (d) => setB2cSeasonData(
                        (prev) => prev.map((p) => (p.name === name ? { ...p, data: d } : p))
                    )
                );
            }
        } else {
            for (let i = 0; i < getB2cSeasonData.length; i += 1) {
                setB2cSeasonData(
                    (prev) => prev.map((p, pi) => (pi === i ? { ...p, data: notRequested() } : p))
                );
            }
        }
    }, [projectId, seasonPlatformId, getB2cSeasonData]);

    return (
        <>
            <Grid container columns={11}>
                <Grid item xs={1}>
                    URLs:
                </Grid>
                <Grid item xs={5} style={{ paddingRight: '0.5rem' }}>
                    <a href={stsUrl} target="_blank" rel="noreferrer">{stsUrl}</a>
                </Grid>
                <Grid item xs={5} style={{ overflowWrap: 'anywhere' }}>
                    {
                        b2cUrls.map((u) => (
                            <div key={u}>
                                <a href={u} target="_blank" rel="noreferrer">{u}</a>
                            </div>
                        ))
                    }
                </Grid>
            </Grid>
            <AsyncXmlAndMultipleJson xml={stsSeasonData} json={b2cSeasonData} basic />
        </>
    );
}
