import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../shared/components/DataTable';

const defaultColumns = [
    {
        id: 'code',
        label: 'Destination Code',
        minWidth: 170,
        align: 'center',
        format: (value) => value,
    },
    {
        id: 'project', // todo - match code with project name
        label: 'Project Name',
        minWidth: 170,
    },
    {
        id: 'sink',
        label: 'Sink',
        minWidth: 170,
    },
];

const mapRows = (rows) => {
    if (Array.isArray(rows) && rows.length > 0) {
        return rows.map((x) => ({
            code: x.code,
            project: x.project.name,
            sink: x.sink.sinkName,
            projectCode: x.project.code,
        }));
    }
    return [];
};

const DestinationsTable = ({ rows, ...otherProps }) => (
    <DataTable rows={mapRows(rows)} {...otherProps} />
);

DestinationsTable.defaultProps = {
    columns: defaultColumns,
};

DestinationsTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape),
};

export default DestinationsTable;
