import { Grid } from '@material-ui/core';
import React from 'react';
import StageSelect from './stageSelect';

export default function StageSelector(props) {
    const { stageId } = props;

    return (
        <Grid container columns={11}>
            <Grid item xs={1}>
                Stage:
            </Grid>
            <Grid item xs={5} style={{ paddingRight: '0.5rem' }}>
                <StageSelect {...props} />
            </Grid>
            <Grid item xs={5}>
                {stageId}
            </Grid>
        </Grid>
    );
}
