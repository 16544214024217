import buildFindMappingByTextAndFilterUrl from './api';
import { useGetApi } from '../shared/hooks/fetchingHooks';

// eslint-disable-next-line import/prefer-default-export
export const useSearchByTextAndFilterApi = (searchText, filter) => {
    const initialUrl = buildFindMappingByTextAndFilterUrl(searchText, filter);
    const [state, setUrl] = useGetApi(initialUrl, []);
    const providerUpdateHandler = (newSearchText, newFilter) => setUrl(
        buildFindMappingByTextAndFilterUrl(newSearchText, newFilter)
    );

    return [state, providerUpdateHandler];
};
