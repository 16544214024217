import React from 'react';

export default function ButtonNav(props) {
    const {
        tabs,
        tab,
        getTabName,
        setTab
    } = props;

    return (
        <>
            {tabs.map((t) => {
                if (tab === t) {
                    return (
                        <>
                            <strong>{getTabName(t)}</strong>
                            {' '}
                        </>
                    );
                }
                return (
                    <>
                        <button type="button" onClick={() => setTab(t)}>
                            {getTabName(t)}
                        </button>
                        {' '}
                    </>
                );
            })}
        </>
    );
}
