import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';

const GreenSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: green[500],
        },
        '&$checked + $track': {
            backgroundColor: green[400],
        },
    },
    checked: {},
    track: {},
})(Switch);

export default GreenSwitch;
