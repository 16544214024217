import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import ConnectionForm from './ConnectionForm';
import { useGetConnectionApi, useEditConnectionApi, useDeleteConnectionApi } from '../hooks';
import parseJsonProperties from '../../utils/json-properties-parser';

function ProjectConnectionDetail({ goBack, onComplete }) {
    const { projectCode, connectionCode } = useParams();
    const [{
        isLoading,
        isError: isLoadError,
        data
    }, onConnectionIdUpdate] = useGetConnectionApi(projectCode, connectionCode);
    const [currentProjectCode, setProjectCode] = useState(projectCode);
    const [currentCode, setCode] = useState(connectionCode);
    if (projectCode !== currentProjectCode || connectionCode !== currentCode) {
        setProjectCode(projectCode);
        setCode(connectionCode);
        onConnectionIdUpdate(projectCode, connectionCode);
    }

    const handleDeleteSuccess = () => onComplete(true);
    const [{
        isSaving,
        isError: isSaveError,
        error: saveError
    }, onSaveHandler] = useEditConnectionApi(onComplete);

    const [{
        isSaving: isDeleting,
        isError: isDeleteError,
        error: deleteError
    }, onDeleteHandler] = useDeleteConnectionApi(handleDeleteSuccess);
    const handleDelete = () => onDeleteHandler({ project: projectCode, code: connectionCode });

    useErrorToast(isDeleteError, deleteError);
    useErrorToast(isSaveError, saveError);

    if (isLoading) {
        return <LinearProgress color="secondary" />;
    }

    const model = { ...data, properties: parseJsonProperties(data) };

    return (
        <ConnectionForm
            isProjectTab
            model={model}
            onSubmit={onSaveHandler}
            onDelete={handleDelete}
            onCancel={goBack}
            isLoading={isLoading || isSaving || isDeleting}
            isError={isLoadError || isSaveError || isDeleteError}
        />
    );
}

export default ProjectConnectionDetail;
