const SHOW_TOAST = 'SHOW_TOAST';
const HIDE_TOAST = 'HIDE_TOAST';

export const actions = (dispatch) => {
    const showToast = (message, isError = true) => {
        dispatch({ type: SHOW_TOAST, payload: { message, isError } });
    };

    const hideToast = () => {
        dispatch({ type: HIDE_TOAST, payload: {} });
    };

    return {
        showToast,
        hideToast
    };
};

export const initialSate = {
    show: false,
    message: 'Saved successfully',
    severity: 'success'
};

export const toastReducer = (state, action) => {
    switch (action.type) {
        case SHOW_TOAST: {
            return {
                ...state,
                show: true,
                message: action.payload.message,
                severity: action.payload.isError ? 'error' : 'success'
            };
        }
        case HIDE_TOAST: {
            return {
                ...state,
                show: false
            };
        }
        default:
            throw new Error(`Unsupported action type ${action.type} passed to the toastReducer!`);
    }
};
