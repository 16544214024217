import React, { useState } from 'react';
import {
    Tabs,
    Tab,
    Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Controlled as CodeMirror } from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/neo.css';
import 'codemirror/mode/javascript/javascript';

const prettiers = {
    json: (jsonString) => JSON.stringify(JSON.parse(jsonString), undefined, 4),
    xml: (xml) => xml
};

const formatModeMap = {
    json: 'application/json',
    xml: 'application/xml'
};

const useStyles = makeStyles((theme) => ({
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    tab: {
        '& .CodeMirror': {
            minHeight: '600px'
        }
    }
}));

function TabPanel({
    children,
    value,
    index,
    className
}) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className={className}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const tabProps = (index) => ({
    label: `Data ${index + 1}`,
    key: `data-tab-${index}`
});

const tabPanelProps = (index, value) => ({
    key: `tabpanel-files-${index}`,
    index,
    value
});

export default function RawDataFileView({ calls }) {
    const [tab, setTab] = useState(0);
    const classes = useStyles();

    return (
        <div>
            <Tabs
                variant="scrollable"
                value={tab}
                onChange={(_, newValue) => setTab(newValue)}
                className={classes.tabs}
            >
                {
                    calls && calls.map((_, index) => <Tab {...tabProps(index)} />)
                }
            </Tabs>
            {
                calls && calls.map(({ format, data, metadata }, index) => {
                    const dataFormatLowercased = format.toLowerCase();
                    const dataDecoded = atob(data);

                    return (
                        <TabPanel className={classes.tab} {...tabPanelProps(index, tab)}>
                            { metadata && metadata['provider-url']
                                ? <div><a href={metadata['provider-url']} target="_blank" rel="noreferrer">{metadata['provider-url']}</a></div>
                                : undefined }
                            {
                                prettiers[dataFormatLowercased] && (
                                    <CodeMirror
                                        value={prettiers[dataFormatLowercased](dataDecoded)}
                                        options={{
                                            mode: formatModeMap[dataFormatLowercased],
                                            theme: 'neo',
                                            lineNumbers: true,
                                            readOnly: true
                                        }}
                                    />
                                )
                            }
                        </TabPanel>
                    );
                })
            }
        </div>
    );
}
