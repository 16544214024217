import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    buttons: {
        '& > button + button': {
            marginLeft: theme.spacing(2),
        },
    },
}));

const renderButtons = ({
    hidden,
    isEdit,
    isCancelDisabled,
    isSaveDisabled,
    isEditDisabled,
    isSaving,
    onEdit,
    onCancel,
    onSave
}) => {
    if (hidden) {
        return null;
    }

    if (!isEdit) {
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={onEdit}
                disabled={isEditDisabled}
                startIcon={<EditIcon />}
            >
                Edit
            </Button>
        );
    }

    if (isSaving) {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                startIcon={<CircularProgress size={24} />}
            >
                Saving ...
            </Button>
        );
    }

    const saveButtonProps = typeof (onSave) === 'function'
        ? { onClick: onSave, type: 'button' }
        : { type: 'submit' };

    return (
        <>
            <Button
                variant="contained"
                onClick={onCancel}
                disabled={isCancelDisabled}
            >
                Cancel
            </Button>
            <Button
                {...saveButtonProps}
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={isSaveDisabled}
            >
                Save
            </Button>
        </>
    );
};

const EditButtonsGroup = ({
    hidden,
    isEdit,
    isCancelDisabled,
    isSaveDisabled,
    isEditDisabled,
    isSaving,
    onEdit,
    onCancel,
    onSave,
    name
}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={3} justifyContent="flex-end" className={classes.root} aria-label={name}>
            <Grid item className={classes.buttons}>
                {renderButtons({
                    hidden,
                    isEdit,
                    isCancelDisabled,
                    isSaveDisabled,
                    isEditDisabled,
                    isSaving,
                    onEdit,
                    onCancel,
                    onSave
                })}
            </Grid>
        </Grid>
    );
};

export default EditButtonsGroup;

EditButtonsGroup.propTypes = {
    hidden: PropTypes.bool,
    isCancelDisabled: PropTypes.bool,
    isEdit: PropTypes.bool.isRequired,
    isEditDisabled: PropTypes.bool,
    isSaveDisabled: PropTypes.bool,
    isSaving: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
    onSave: PropTypes.func
};

EditButtonsGroup.defaultProps = {
    onEdit: () => {},
    isCancelDisabled: false,
    isEditDisabled: false,
    isSaveDisabled: false,
    isSaving: false,
    onSave: undefined,
    hidden: false
};
