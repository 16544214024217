/* eslint-disable import/prefer-default-export */
export const ingestionAggregatedStatuses = [
    'UNEXECUTED',
    'SUCCEEDED',
    'WARNING',
    'FAILED',
    'QUEUED',
    'RUNNING',
    'STOPPED',
];

export const defaultIngestionStatus = 'UNEXECUTED';
export const successIngestionStatus = 'SUCCEEDED';

export const ingestionStatusesThemeColorMap = {
    WARNING: 'warning',
    FAILED: 'error',
    SUCCEEDED: 'success',
    UNEXECUTED: 'primary',
    QUEUED: 'primary',
    RUNNING: 'primary',
    STOPPED: 'success',
};

export const ingestionStatusesLabelsMap = {
    WARNING: 'Requires Attention',
    FAILED: 'Failed',
    SUCCEEDED: 'Succeeded',
    UNEXECUTED: 'Not Available',
    QUEUED: 'Queued',
    RUNNING: 'Running',
    STOPPED: 'Stopped',
};
