import { getText } from './http';

class MatchApi {
    constructor(b2cHost, urlPart1, urlPart2) {
        this.b2cHost = b2cHost;
        this.urlPart1 = urlPart1;
        this.urlPart2 = urlPart2;
    }

    getUrlSuffix(projectId, seasonId, matchId) {
        return `v1/${projectId}/football/seasons/${seasonId}/${this.urlPart1}/${matchId}/${this.urlPart2}`;
    }

    getUrl(projectId, seasonId, matchId) {
        return `${this.b2cHost}/${this.getUrlSuffix(projectId, seasonId, matchId)}`;
    }

    getJson(projectId, seasonId, matchId, setJson) {
        const url = `/api/sts-mls/b2c?b2cUrl=${encodeURIComponent(this.getUrlSuffix(projectId, seasonId, matchId))}`;
        return getText(url, setJson);
    }
}

export function getLineupApi(b2cHost) {
    return new MatchApi(b2cHost, 'matches', 'lineups');
}

export function getMatchTeamStatisticsApi(b2cHost) {
    return new MatchApi(b2cHost, 'match', 'teamstats');
}

export function getMatchPlayerStatisticsApi(b2cHost) {
    return new MatchApi(b2cHost, 'match', 'playerstats');
}

export function getFeedApi(b2cHost) {
    return new MatchApi(b2cHost, 'matches', 'feed');
}
