import { Grid } from '@material-ui/core';
import { XMLParser } from 'fast-xml-parser';
import React from 'react';
import ReactJson from 'react-json-view';
import XMLViewer from 'react-xml-viewer';
import './asyncXml.css';
import ButtonNav from './buttonNav';

const xmlTabs = [
    'XML_AS_JSON',
    'XML'
];

function getXmlTabName(tab) {
    switch (tab) {
        case 'XML_AS_JSON':
            return 'JSON';
        case 'XML':
            return 'Original XML';
        default:
            return undefined;
    }
}

export default function AsyncXmlAndMultipleJson(props) {
    const {
        xml,
        json,
        basic
    } = props;

    const [xmlTab, setXmlTab] = React.useState('XML_AS_JSON');
    const [jsonTab, setJsonTab] = React.useState(json[0].name);

    const xmlDisplay = React.useMemo(() => {
        const {
            type,
            data
        } = xml;
        if (type !== 'SUCCEEDED') {
            return '';
        }
        if (basic) {
            return <div>{data}</div>;
        }
        if (xmlTab === 'XML') {
            return <XMLViewer xml={data} collapsible theme={{ overflowBreak: true }} />;
        }
        const parser = new XMLParser({ ignoreAttributes: false, attributeNamePrefix: '' });
        const parsed = parser.parse(data);
        return <ReactJson src={parsed} />;
    }, [xmlTab, xml]);

    const [jsonDisplay, jsonType] = React.useMemo(() => {
        const {
            type,
            data
        } = json.find((d) => jsonTab === d.name);
        if (type !== 'SUCCEEDED') {
            return [undefined, type];
        }
        if (basic) {
            return <div>{data}</div>;
        }
        return [<ReactJson src={JSON.parse(data)} />, type];
    }, [jsonTab, json]);

    const buttons = React.useMemo(() => (basic
        ? undefined
        : (
            <ButtonNav
                tabs={xmlTabs}
                tab={xmlTab}
                getTabName={getXmlTabName}
                setTab={setXmlTab}
            />
        )), [basic, xmlTabs, xmlTab]);

    return (
        <>
            <Grid container columns={11}>
                <Grid item xs={1} />
                <Grid item xs={5} style={{ paddingRight: '0.5rem' }}>
                    {buttons}
                </Grid>
                <Grid item xs={5}>
                    <ButtonNav
                        tabs={json.map((t) => t.name)}
                        tab={jsonTab}
                        getTabName={(n) => n}
                        setTab={setJsonTab}
                    />
                </Grid>
            </Grid>
            <Grid container columns={11}>
                <Grid item xs={1} />
                <Grid item xs={5} style={{ paddingRight: '0.5rem' }}>
                    <div className="async-xml">
                        {xml.type === 'SUCCEEDED' ? xmlDisplay : undefined}
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <div className="async-xml">
                        {jsonType === 'SUCCEEDED' ? jsonDisplay : undefined}
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
