import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export default function Captioned({ caption, children }) {
    return (
        <Grid container direction="column">
            <Grid item>
                <Typography color="secondary" variant="overline">{caption}</Typography>
            </Grid>
            <Grid item>
                {children}
            </Grid>
        </Grid>
    );
}
