import React from 'react';
import { useParams } from 'react-router-dom';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import { useEditProjectApi, useDeleteProjectApi } from '../hooks';
import ProjectForm from './ProjectForm';

function EditProject({ goBack, onComplete, project }) {
    const { projectCode } = useParams();

    const [{
        isSaving,
        isError: isSaveError,
        error: saveError,
    }, onSaveHandler] = useEditProjectApi(onComplete);
    const handleDeleteSuccess = () => onComplete(true);
    const [{
        isSaving: isDeleting,
        isError: isDeleteError,
        error: deleteError,
    }, onDeleteHandler] = useDeleteProjectApi(handleDeleteSuccess);

    const handleDelete = () => onDeleteHandler(projectCode);

    useErrorToast(isDeleteError, deleteError);
    useErrorToast(isSaveError, saveError);

    return (
        <div>
            <ProjectForm
                project={project}
                onSubmit={onSaveHandler}
                onDelete={handleDelete}
                onCancel={goBack}
                isLoading={isSaving || isDeleting}
                isError={isSaveError || isDeleteError}
            />
        </div>
    );
}

export default EditProject;
