import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1)
    }
}));

const GoBackButton = ({ goBack, text }) => {
    const classes = useStyles();
    return (
        <Box>
            <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="go back"
                className={classes.margin}
                onClick={goBack}
            >
                <ArrowBackIcon className={classes.extendedIcon} />
                <span className={classes.margin}>{text}</span>
            </Fab>
        </Box>
    );
};

export default GoBackButton;

GoBackButton.propTypes = {
    goBack: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};
