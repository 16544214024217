import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';

import { ingestionStatusesLabelsMap } from '../const';
import PrimaryHelpIcon from './icons/PrimaryHelpIcon';
import OrangeWarningIcon from './icons/OrangeWarningIcon';
import GreenSucessIcon from './icons/GreenSuccessIcon';

export default function IngestionStatusIcon({ ingestionStatus }) {
    return (
        <Tooltip title={`Ingestion Status: ${ingestionStatusesLabelsMap[ingestionStatus]}`} placement="top">
            <span>
                {ingestionStatus === 'UNEXECUTED' && <PrimaryHelpIcon />}
                {ingestionStatus === 'SUCCEEDED' && <GreenSucessIcon />}
                {ingestionStatus === 'WARNING' && <OrangeWarningIcon />}
                {ingestionStatus === 'FAILED' && <ErrorIcon color="secondary" />}
            </span>
        </Tooltip>
    );
}
