import React, { useRef, useState } from 'react';
import {
    Redirect,
    Route, Switch, useHistory, useParams, useRouteMatch
} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useProvidersList } from '../../providers/hooks';
import GoBackButton from '../../shared/components/GoBackButton';
import AddDataSetConfig from '../data-set-config/AddDataSetConfig';
import DataSetConfigsListing from '../data-set-config/DataSetConfigsListing';
import EditDataSetConfig from '../data-set-config/EditDataSetConfig';
import { useGetDataSourceMonitoringStatus } from '../data-set-config/hooks';
import { useGetDataSourceApi, useEditDataSourceApi, useDeleteDataSourceApi } from '../hooks';
import { useErrorToast, useShowToastContext } from '../../shared/toast/ToastProvider';
import DataSourceForm from './DataSourceForm';

const ADD_DATASET_CONFIG_ROUTE = 'add';
const DATASET_CONFIG_ROUTE = 'data-set-configs';

const ProjectDataSourceDetails = ({ onComplete, goBack }) => {
    const { path, url } = useRouteMatch();
    const history = useHistory();
    const { projectCode: urlProjectCode, dataSourceCode: urlDataSourceCode } = useParams();
    const [projectCode] = useState(urlProjectCode);
    const [dataSourceCode] = useState(urlDataSourceCode);
    const mountedRef = useRef(true);

    const [{
        isLoading: isDataSourceLoading,
        isError: isDataSourceLoadError,
        error: dataSourceLoadError,
        data: dataSource
    },
    onDataSourceCodeUpdate,
    forceReloadDatasource
    ] = useGetDataSourceApi(projectCode, dataSourceCode);

    const dataSetConfigs = dataSource.dataSetConfigs || [];
    const [{
        isLoading: isDataSetsStatusesLoading,
        isError: isLoadDataSetsStatusesError,
        error: loadDataSetsStatusesError,
        data: dataSourceStatus
    }] = useGetDataSourceMonitoringStatus(projectCode, dataSourceCode, dataSetConfigs.length);

    if (urlProjectCode !== projectCode || urlDataSourceCode !== dataSourceCode) {
        onDataSourceCodeUpdate(urlProjectCode, urlDataSourceCode);
    }

    const [{
        isLoading: isProvidersLoading,
        isError: isLoadProvidersError,
        error: loadProvidersError,
        data: providers
    }] = useProvidersList();
    const providersSelectList = providers.map((provider) => ({
        key: provider.code,
        value: provider.name
    }));

    const [{
        isSaving,
        isError: isSaveError,
        error: saveError
    }, onSaveHandler] = useEditDataSourceApi(onComplete);

    const [{
        isSaving: isDeleting,
        isError: isDeleteError,
        error: deleteError
    }, onDeleteHandler] = useDeleteDataSourceApi(() => onComplete(true));
    const handleDelete = () => onDeleteHandler({
        project: urlProjectCode,
        code: urlDataSourceCode
    });

    const showAlert = useShowToastContext();

    const handleAddDataSetConfig = () => {
        history.push(`${url}/${DATASET_CONFIG_ROUTE}/${ADD_DATASET_CONFIG_ROUTE}`);
    };

    const handleDataSetConfigDetails = (model) => {
        history.push(`${url}/${DATASET_CONFIG_ROUTE}/${model.id}`);
    };

    const goToDataSourceDetails = () => {
        forceReloadDatasource();
        history.push(url);
    };

    const handleEditCompleted = (isDelete) => {
        if (mountedRef.current) {
            forceReloadDatasource();
            const message = isDelete ? 'Deleted successfully' : 'Saved successfully';
            showAlert(message, false);
            goToDataSourceDetails();
        }
    };

    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useErrorToast(isDataSourceLoadError, dataSourceLoadError);
    useErrorToast(isSaveError, saveError);
    useErrorToast(isDeleteError, deleteError);
    useErrorToast(isLoadProvidersError, loadProvidersError);
    useErrorToast(isLoadDataSetsStatusesError, loadDataSetsStatusesError);

    if (isDataSourceLoading) {
        return <LinearProgress color="secondary" />;
    }

    const isLoading = isDataSourceLoading || isSaving || isDeleting || isProvidersLoading;
    return (
        <Switch>
            <Route exact path={path}>
                <DataSourceForm
                    dataSource={dataSource}
                    providers={providersSelectList}
                    isLoading={isLoading}
                    onSubmit={onSaveHandler}
                    onDelete={handleDelete}
                    isNew={false}
                    isSaving={isSaving}
                    onCancel={goBack}
                />
                <DataSetConfigsListing
                    dataSetConfigs={dataSetConfigs}
                    add={handleAddDataSetConfig}
                    details={handleDataSetConfigDetails}
                    dataSourceStatus={dataSourceStatus}
                    isStatusLoading={isDataSetsStatusesLoading}
                />
                <GoBackButton goBack={goBack} text="go to listing" />
            </Route>
            <Route path={`${path}/${DATASET_CONFIG_ROUTE}/${ADD_DATASET_CONFIG_ROUTE}`}>
                <AddDataSetConfig
                    dataSource={dataSource}
                    back={goToDataSourceDetails}
                    onComplete={handleEditCompleted}
                    provider={dataSource.provider}
                />
                <GoBackButton goBack={goToDataSourceDetails} text="go back" />
            </Route>
            <Route path={`${path}/${DATASET_CONFIG_ROUTE}/:dataSetConfigId`}>
                <EditDataSetConfig
                    dataSource={dataSource}
                    back={goToDataSourceDetails}
                    onComplete={handleEditCompleted}
                    provider={dataSource.provider}
                    dataSourceStatus={dataSourceStatus}
                    isStatusLoading={isDataSetsStatusesLoading}
                />
                <GoBackButton goBack={goToDataSourceDetails} text="go back" />
            </Route>
            <Route path={`${path}/${DATASET_CONFIG_ROUTE}`}>
                { /* Bread crumbs navigation fix */ }
                <Redirect to={url} />
            </Route>
        </Switch>
    );
};

export default ProjectDataSourceDetails;
