import { convert } from '../async';
import { getJson } from './http';

export function getProjects(setProjects) {
    return getJson('/api/projects', setProjects);
}

export function getStsConnections(projectId, setConnections) {
    return getJson(`/api/projects/${encodeURIComponent(projectId)}/connections`, (connections) => {
        const parsedConnections = convert(connections, (data) => data.map((d) => ({
            ...d,
            jsonProperties: JSON.parse(d.jsonProperties),
            provider: {
                ...d.provider,
                jsonSchema: JSON.parse(d.provider.jsonSchema)
            }
        })).filter((d) => d.provider.name === 'STS'));
        setConnections(parsedConnections);
    });
}
