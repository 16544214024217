import PropTypes from 'prop-types';
import React from 'react';
import cronstrue from 'cronstrue';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    humanizedCron: {
        maxWidth: 1000,
        marginBottom: theme.spacing(1)
    }
}));

const Cron = ({
    cron,
    isValid,
    onChange,
    setIsValid,
    disabled,
    errorMessage
}) => {
    const classes = useStyles();
    const [humanizedString, setHumanizedString] = React.useState('');
    const [touched, setTouched] = React.useState(false);
    React.useEffect(() => {
        try {
            const humanized = cronstrue.toString(cron, { verbose: true });
            setIsValid(true);
            setHumanizedString(humanized);
        } catch {
            setIsValid(false);
            const error = touched ? 'Check the cron expression syntax.' : '';
            setHumanizedString(error);
        }
    }, [cron]);

    const handleCronChange = ({ target: { value } }) => {
        setTouched(true);
        onChange(value);
    };

    const color = isValid ? 'textSecondary' : 'error';
    const tooltip = isValid ? humanizedString : '';

    return (
        <Grid container justifyContent="space-between" alignItems="flex-end" spacing={4}>
            <Grid item hidden>
                <TextField
                    placeholder="0 0 * * * ?"
                    disabled={disabled}
                    id="cron"
                    label="Cron"
                    margin="normal"
                    value={cron}
                    fullWidth
                    onChange={handleCronChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Tooltip title={tooltip} arrow>
                    <Typography variant="body1" className={classes.humanizedCron} noWrap color={color}>
                        {errorMessage || humanizedString}
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item hidden>
                <Alert variant="outlined" severity="warning">
                    UTC time
                </Alert>
            </Grid>
        </Grid>
    );
};

export default Cron;

Cron.propTypes = {
    cron: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    isValid: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    setIsValid: PropTypes.func
};

Cron.defaultProps = {
    isValid: true,
    setIsValid: () => {
    }
};
