export function notRequested() {
    return {
        type: 'NOT_REQUESTED'
    };
}

export function requested() {
    return {
        type: 'REQUESTED'
    };
}

export function succeeded(data) {
    return {
        type: 'SUCCEEDED',
        data
    };
}

export function error(message) {
    return {
        type: 'ERROR',
        message
    };
}

export function convert(source, converter) {
    if (source.type === 'SUCCEEDED') {
        return succeeded(converter(source.data));
    }
    return source;
}
