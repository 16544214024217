import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Container,
    LinearProgress,
} from '@material-ui/core';

import DataSourceOpsDetailsView from '../detail/DataSourceOpsDetailsView';
import { useDataSourceStatusDetailsApi } from '../hooks';
import GoBackButton from '../../shared/components/GoBackButton';
import { useErrorToast } from '../../shared/toast/ToastProvider';

export default function DataSourceDetailsPage({ onGoBack }) {
    const { projectCode, dataSourceCode } = useParams();
    const [{
        data,
        isLoading,
        isError
    }] = useDataSourceStatusDetailsApi(projectCode, dataSourceCode);

    useErrorToast(isError, 'Failed to load data source monitoring details');

    if (isLoading) {
        return (<LinearProgress role="progressbar" />);
    }

    return (
        <Container>
            {
                !isError && (<DataSourceOpsDetailsView dataSourceDetails={data} />)
            }
            <GoBackButton goBack={() => onGoBack(projectCode)} text="back to project details" />
        </Container>
    );
}
