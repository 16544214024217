import { usePagedListingApi, useGetApi, useSaveApi } from '../shared/hooks/fetchingHooks';
import {
    DESTINATIONS_API_ROUTE,
    buildGetDestinationUrl,
    editDestination,
    addDestination,
    deleteDestination,
    buildGetProjectDestinationsUrl
} from './api';

export const useDestinationsListingApi = () => usePagedListingApi(DESTINATIONS_API_ROUTE);
export const useProjectDestinationsListingApi = (project) => useGetApi(
    buildGetProjectDestinationsUrl(project), []
);

export const useGetDestinationApi = (projectCode, destinationCode) => {
    const startingUrl = buildGetDestinationUrl(projectCode, destinationCode);

    const [state, setUrl] = useGetApi(startingUrl, { code: '', sink: '', project: {} });
    const projectCodeUpdateHandler = (newCode, newDestinationCode) => setUrl(
        buildGetDestinationUrl(newCode, newDestinationCode)
    );

    return [state, projectCodeUpdateHandler];
};

export const useDestinationDetailsApi = (onComplete) => useSaveApi(editDestination, onComplete);

export const useAddDestinationApi = (onComplete) => useSaveApi(addDestination, onComplete);

export const useDeleteDestinationApi = (onComplete) => useSaveApi(deleteDestination, onComplete);
