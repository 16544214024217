import React from 'react';
import ToastConsumer from './ToastConsumer';
import { toastReducer, actions, initialSate } from './toast-reducer';

const ShowToastContext = React.createContext(null);

const useShowToastContextValueProvider = () => {
    const [state, dispatch] = React.useReducer(toastReducer, initialSate);
    const { showToast, hideToast } = actions(dispatch);

    return [state, showToast, hideToast];
};

/**
 * Used to show notification to user
 * @returns {function(message: string, isError: boolean)}
 */
export const useShowToastContext = () => React.useContext(ShowToastContext);

export const useErrorToast = (isError, message) => {
    const showAlert = useShowToastContext();
    React.useEffect(() => {
        if (isError) {
            showAlert(message || 'Operation failed', true);
        }
    }, [isError, message]);
};

function ToastProvider({ children }) {
    const [state, showToast, hideToast] = useShowToastContextValueProvider();
    return (
        <ShowToastContext.Provider value={showToast}>
            {children}
            <ToastConsumer state={state} handleClose={hideToast} />
        </ShowToastContext.Provider>
    );
}

export default ToastProvider;
