import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { notRequested } from '../shared/async';
import AsyncXmlAndJson from '../shared/components/asyncXmlAndJson';
import StageSelector from './stage-selection/stageSelector';
import GroupSelector from './group-selection/groupSelector';

const StandingsByGroupTab = (props) => {
    const {
        seasonId,
        projectId,
        competitionId,
        seasonPlatformId,
        getB2cStandingsByGroupUrl,
        getB2cStandingsByGroupJson,
        getStsStandingsUrl,
        getStsStandingsXml,
    } = props;

    const [b2cStandingsData, setb2cStandingsData] = React.useState(notRequested());
    const [stsStandingData, setStsStandingData] = React.useState(notRequested());
    const [stageId, setStageId] = useState(undefined);
    const [groupId, setGroupId] = useState(undefined);

    const b2cUrl = React.useMemo(() => {
        if (projectId && seasonId && groupId) {
            return getB2cStandingsByGroupUrl(seasonPlatformId, projectId, groupId);
        }
        return undefined;
    }, [projectId, getB2cStandingsByGroupUrl, groupId]);

    const stsUrl = React.useMemo(() => {
        if (competitionId) {
            return getStsStandingsUrl(competitionId,);
        }
        return undefined;
    }, [competitionId, seasonId, getStsStandingsUrl]);

    React.useEffect(() => {
        if (projectId && seasonPlatformId && groupId) {
            getB2cStandingsByGroupJson(
                seasonPlatformId,
                projectId,
                groupId,
                setb2cStandingsData
            );
        } else {
            setb2cStandingsData(notRequested());
        }
    }, [projectId, setb2cStandingsData, seasonPlatformId, groupId]);

    React.useEffect(() => {
        if (competitionId && seasonId) {
            getStsStandingsXml(competitionId, setStsStandingData);
        } else {
            setStsStandingData(notRequested());
        }
    }, [competitionId, seasonId, getStsStandingsXml, setStsStandingData]);

    return (
        <>
            <StageSelector {...props} setStageId={setStageId} stageId={stageId} />
            {stageId && (
                <GroupSelector
                    {...props}
                    setGroupId={setGroupId}
                    groupId={groupId}
                    stageId={stageId}
                />
            )}
            <Grid container columns={11}>
                <Grid item xs={1}>
                    URLs:
                </Grid>
                <Grid item xs={5} style={{ paddingRight: '0.5rem' }}>
                    <a href={stsUrl} target="_blank" rel="noreferrer">{stsUrl}</a>
                </Grid>
                <Grid item xs={5} style={{ overflowWrap: 'anywhere' }}>
                    <a href={b2cUrl} target="_blank" rel="noreferrer">
                        {b2cUrl}
                    </a>
                </Grid>
            </Grid>
            <AsyncXmlAndJson xml={stsStandingData} json={b2cStandingsData} />
        </>
    );
};

export default StandingsByGroupTab;
