import { usePagedListingApi, useGetApi } from '../shared/hooks/fetchingHooks';
import {
    OPERATIONS_API_ROUTE,
    buildGetProjectStatusUrl,
    buildGetDataSourceStatusUrl,
    buildGetOperationDetails
} from './api';

export const useProjectsStatusListingApi = () => usePagedListingApi(OPERATIONS_API_ROUTE);
export const useProjectStatusDetailsApi = (projectCode) => useGetApi(
    buildGetProjectStatusUrl(projectCode), []
);

export const useDataSourceStatusDetailsApi = (projectCode, dataSourceCode) => useGetApi(
    buildGetDataSourceStatusUrl(projectCode, dataSourceCode),
    {}
);

export const useOperationDetailsApi = (operationId) => useGetApi(
    buildGetOperationDetails(operationId),
    {}
);
