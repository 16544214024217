import { getText } from './http';

class TeamApi {
    constructor(b2cHost, urlPart) {
        this.b2cHost = b2cHost;
        this.urlPart = urlPart;
    }

    getUrlSuffix(projectId, teamId) {
        return `v1/${projectId}/football/teams/${teamId}/${this.urlPart}`;
    }

    getUrl(projectId, teamId) {
        return `${this.b2cHost}/${this.getUrlSuffix(projectId, teamId)}`;
    }

    getJson(projectId, teamId, setJson) {
        const url = `/api/sts-mls/b2c?b2cUrl=${encodeURIComponent(this.getUrlSuffix(projectId, teamId))}`;
        return getText(url, setJson);
    }
}

export function getRosterApi(b2cHost) {
    return new TeamApi(b2cHost, 'roster');
}

export function getTeamOfficialsApi(b2cHost) {
    return new TeamApi(b2cHost, 'coaches');
}
