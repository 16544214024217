import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import ToastProvider from './shared/toast/ToastProvider';
import App from './App';
import theme from './theme';

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <ToastProvider>
                    <App />
                </ToastProvider>
            </Router>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
