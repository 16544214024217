import { usePagedListingApi, useGetApi, useSaveApi } from '../shared/hooks/fetchingHooks';
import {
    CONNECTIONS_API_ROUTE,
    buildGetConnectionUrl,
    editConnection,
    addConnection,
    deleteConnection,
    buildGetProjectConnectionsUrl,
    buildListFilteredConnectionsUrl,
} from './api';

export const useConnectionsListingApi = () => usePagedListingApi(CONNECTIONS_API_ROUTE);
export const useProjectConnectionsListingApi = (project) => useGetApi(
    buildGetProjectConnectionsUrl(project), []
);

export const useListFilteredConnectionsApi = (project, provider) => {
    const initialUrl = buildListFilteredConnectionsUrl(project, { provider });
    const [state, setUrl] = useGetApi(initialUrl, []);
    const providerUpdateHandler = (newProvider) => setUrl(
        buildListFilteredConnectionsUrl(project, { provider: newProvider })
    );

    return [state, providerUpdateHandler];
};

export const useGetConnectionApi = (projectCode, connectionCode) => {
    const startingUrl = buildGetConnectionUrl(projectCode, connectionCode);

    const [state, setUrl] = useGetApi(startingUrl, { name: '', code: '' });
    const projectCodeUpdateHandler = (newCode, newConnectionCode) => setUrl(
        buildGetConnectionUrl(newCode, newConnectionCode)
    );

    return [state, projectCodeUpdateHandler];
};

export const useEditConnectionApi = (onComplete) => useSaveApi(editConnection, onComplete);

export const useAddConnectionApi = (onComplete) => useSaveApi(addConnection, onComplete);

export const useDeleteConnectionApi = (onComplete) => useSaveApi(deleteConnection, onComplete);
