import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';

const PrimaryHelpIcon = withStyles(({ palette }) => ({
    root: {
        color: palette.primary.main
    }
}))((props) => <HelpIcon {...props} />);

export default PrimaryHelpIcon;
