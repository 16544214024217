import React from 'react';
import AsyncSelect from '../shared/components/asyncSelect';
import { convert, notRequested } from '../shared/async';
import { getSeasons } from '../shared/api/stsApi';

export default function SeasonSelect(props) {
    const {
        connection,
        competitionId,
        seasonId,
        setSeasonId
    } = props;

    const [seasons, setSeasons] = React.useState(notRequested());

    const sortSeasons = (s) => s.sort((a, b) => {
        if (a.Season > b.Season) {
            return -1;
        }
        return a.Season < b.Season ? 1 : 0;
    });

    React.useEffect(() => {
        if (connection && competitionId) {
            getSeasons(connection, competitionId, (result) => {
                const sortedSeasons = convert(result, sortSeasons);
                setSeasons(sortedSeasons);
            });
        }
    }, [connection, competitionId, setSeasons]);

    return (
        <AsyncSelect result={seasons} id={seasonId} setId={setSeasonId} getIdFromItem={(s) => s.SeasonId} getNameFromItem={(s) => `${s.SeasonId} - ${s.Season}`} />
    );
}
