import { useState, useEffect, useReducer } from 'react';
import { getData } from '../../utils/api';

const FETCH_INIT = 'FETCH_INIT';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_FAILURE = 'FETCH_FAILURE';
const FETCH_CANCEL = 'FETCH_CANCEL';

const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case FETCH_INIT:
            return {
                ...state,
                isLoading: true,
                isError: false,
                error: null,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case FETCH_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.error,
            };
        case FETCH_CANCEL:
            return {
                ...state,
                isLoading: false
            };
        default:
            throw new Error();
    }
};

const dataSaveReducer = (state, action) => {
    switch (action.type) {
        case FETCH_INIT:
            return {
                ...state,
                isSaved: false,
                isSaving: true,
                isError: false,
                error: null,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                isSaved: true,
                isSaving: false,
                isError: false,
                data: action.payload,
            };
        case FETCH_FAILURE:
            return {
                ...state,
                isSaved: false,
                isSaving: false,
                isError: true,
                error: action.error,
            };
        default:
            throw new Error();
    }
};

export const usePagedListingApi = (url) => {
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isCancel, setCancel] = useState(false);

    /**
     * returns '?' or '&' depending if the url has already a query string
     * @param {string} urlString - url to be called
     * @returns {string} - the proper operator
     */
    const getQueryStringOperator = (urlString) => (urlString.includes('?') ? '&' : '?');

    const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            const skip = pageSize * currentPage;
            const uri = `${url}${getQueryStringOperator(url)}skip=${skip}&take=${pageSize}`;
            const result = await getData(uri);

            if (!isCancel) {
                setData(result);
                if (result?.totalPages) {
                    setTotalCount(result.totalPages);
                } else {
                    setTotalCount(result?.length || 0);
                }
            }
        } catch (error) {
            setIsError(true);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();

        return () => setCancel(true);
    }, [pageSize, currentPage, url]);

    return [{
        data,
        isLoading,
        isError,
        totalCount,
        pageSize,
        currentPage,
    }, setPageSize, setPage, fetchData];
};

export const useGetApi = (initialUrl, initialData) => {
    const [url, setUrl] = useState(initialUrl);
    const [forceReloadTimestamp, setForceReloadTimestamp] = useState((new Date()).toISOString());
    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: true,
        isError: false,
        data: initialData || {},
    });

    useEffect(() => {
        if (!url) {
            dispatch({ type: FETCH_CANCEL });

            return;
        }

        let isCancelled = false;
        const fetchData = async () => {
            dispatch({ type: FETCH_INIT });

            try {
                const result = await getData(url);
                if (!isCancelled) {
                    dispatch({ type: FETCH_SUCCESS, payload: result });
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(url, error);
                if (!isCancelled) {
                    dispatch({ type: FETCH_FAILURE, error: error.toString() });
                }
            }
        };

        fetchData();

        // eslint-disable-next-line consistent-return
        return () => {
            isCancelled = true;
        };
    }, [url, forceReloadTimestamp]);

    const forceReload = () => setForceReloadTimestamp((new Date()).toISOString());

    return [state, setUrl, forceReload];
};

export const useSaveApi = (callApiFunction, successCallback) => {
    const [state, dispatch] = useReducer(dataSaveReducer, {
        isSaved: false,
        isSaving: false,
        isError: false,
        error: null,
    });

    const saveHandler = async (model, onSuccess) => {
        dispatch({ type: FETCH_INIT });

        try {
            const result = await callApiFunction(model);
            if (result.ok) {
                dispatch({ type: FETCH_SUCCESS, payload: result });
                if (successCallback && typeof successCallback === 'function') {
                    successCallback();
                }
                if (onSuccess && typeof onSuccess === 'function') {
                    onSuccess();
                }
            } else {
                let error = result.statusText;
                if (result.status === 400) {
                    error = 'Validation failed';
                    try {
                        const { error: jsonError } = await result.json();
                        if (jsonError) {
                            error += `: ${jsonError}`;
                        }
                    } catch {
                        // json() could throw exception if body not provided
                    }
                }

                dispatch({ type: FETCH_FAILURE, error });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            dispatch({ type: FETCH_FAILURE, error: error.toString() });
        }
    };

    return [state, saveHandler];
};
