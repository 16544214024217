/* eslint-disable */
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import Select from '../../shared/components/Select';

const AddLocale = ({
    locales,
    onAddLocale
}) => {
    const [open, setOpen] = React.useState(false);
    const [locale, setLocale] = React.useState('');

    if (locales.length === 0) {
        return null;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (reason && reason == "backdropClick") {
            return;
        }
        setLocale('');
        setOpen(false);
    };

    const handleChange = (value) => {
        setLocale(value);
    };

    const handleSave = () => {
        if (locale) {
            onAddLocale(locale);
        }
        setLocale('');
        setOpen(false);
    };

    return (
        <>
            <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                    <Tooltip title="Add Locale">
                        <IconButton aria-label="add-locale" edge="start" onClick={handleClickOpen}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                onCancel={handleClose}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Select locale</DialogTitle>
                <DialogContent>
                    <Select
                        values={locales}
                        value={locale}
                        setValue={handleChange}
                        label="Available locales"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" disabled={!locale}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddLocale;
