import Box from '@material-ui/core/Box';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import { useGetNextRunApi } from './hooks';

const formatDate = (date) => {
    if (!Date.parse(date)) {
        return date;
    }

    return (new Date(date)).toString();
};

const NextRun = ({ savedCron }) => {
    const { dataSetConfigId, projectCode, dataSourceCode } = useParams();
    const [
        { isError, data: nextRun }, , forceReload
    ] = useGetNextRunApi(projectCode, dataSourceCode, dataSetConfigId);

    const [currentCron, setCurrentCron] = React.useState(savedCron);
    React.useEffect(() => {
        if (currentCron !== savedCron) {
            setCurrentCron(savedCron);
            forceReload();
        }
    }, [savedCron]);

    // TODO: need to add timeout to refresh the value when next run happens
    const nextRunValue = savedCron ? nextRun.date : '';

    useErrorToast(isError, 'Failed to load next run date time.');

    if (!nextRunValue) {
        return null;
    }

    return (
        <Box>
            <Typography variant="subtitle2" component="span">Next run:&nbsp;</Typography>
            <Typography variant="body2">{formatDate(nextRunValue)}</Typography>
        </Box>
    );
};

const IsEnabledWrapper = ({ isEnabled, ...rest }) => {
    if (!isEnabled) {
        return null;
    }

    return <NextRun {...rest} />;
};

export default IsEnabledWrapper;
