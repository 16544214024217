/* eslint-disable */
import { getText } from './http';

class StandingsByGroupApi {
    constructor(b2cHost) {
        this.b2cHost = b2cHost;
    }

    /* eslint-disable class-methods-use-this */
    getUrlSuffix(projectId, seasonId, groupId) {
        return `v1/${projectId}/football/seasons/${seasonId}/groups/${groupId}/standings`;
    }

    getUrl(seasonId, projectId, groupId) {
        return `${this.b2cHost}/${this.getUrlSuffix(seasonId, projectId, groupId)}`;
    }

    getJson(projectId, seasonId, groupId, setJson) {
        const url = `/api/sts-mls/b2c?b2cUrl=${encodeURIComponent(this.getUrlSuffix(projectId, seasonId, groupId))}`;
        return getText(url, setJson);
    }
}


export function getStandingsByGroupApi(b2cHost) {
  return new StandingsByGroupApi(b2cHost);
}
