import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Card,
    CardActions,
    CardContent,
    Button,
    Typography
} from '@material-ui/core';

import IngestionStatusIcon from '../../shared/components/IngestionStatusIcon';
import Captioned from '../../shared/components/Captioned';
import Empty from '../../shared/components/Empty';

const ProjectOpsListItem = ({
    projectOpsItem: {
        projectCode,
        projectName,
        status
    },
    onViewProjectOpsDetails
}) => (
    <Grid item xs={4}>
        <Card>
            <CardContent>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={8}>
                        <Captioned caption="Project Name">
                            <Typography variant="h5" component="h2">
                                {projectName}
                            </Typography>
                        </Captioned>
                    </Grid>
                    <Grid container item xs={4} justifyContent="flex-end">
                        <IngestionStatusIcon ingestionStatus={status} />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={() => onViewProjectOpsDetails(projectCode)}>View Details</Button>
            </CardActions>
        </Card>
    </Grid>
);

const ProjectOpsList = ({ projectOps, onViewProjectOpsDetails }) => {
    if (!projectOps || projectOps.length === 0) {
        return <Empty text="No Project Operations Status found!" />;
    }

    return (
        <Grid container spacing={2}>
            {
                projectOps && projectOps.map((item) => (
                    <ProjectOpsListItem
                        key={`prj-ops-${item.projectCode}`}
                        projectOpsItem={item}
                        onViewProjectOpsDetails={onViewProjectOpsDetails}
                    />
                ))
            }
        </Grid>
    );
};

ProjectOpsList.propTypes = {
    projectOps: PropTypes.arrayOf(
        PropTypes.shape({
            projectCode: PropTypes.string.isRequired,
            projectName: PropTypes.string.isRequired,
            ingestionStatus: PropTypes.oneOf([
                'UNEXECUTED',
                'SUCCEEDED',
                'FAILED'
            ]),
            lastRun: PropTypes.string,
            lastOperationId: PropTypes.string
        })
    ).isRequired,
    onViewProjectOpsDetails: PropTypes.func
};

ProjectOpsList.defaultProps = {
    onViewProjectOpsDetails: () => {}
};

export default ProjectOpsList;
