import { Grid } from '@material-ui/core';
import React from 'react';
import { notRequested } from '../shared/async';
import AsyncXmlAndMultipleJson from '../shared/components/asyncXmlAndMultipleJson';
import MatchSelector from './matchSelector';

export default function MatchTab(props) {
    const {
        projectId,
        seasonPlatformId,
        matchId,
        matchPlatformId,
        getStsMatchDataUrl,
        getStsMatchDataXml,
        getB2cMatchData
    } = props;

    const [stsMatchData, setStsMatchData] = React.useState(notRequested());
    const [b2cMatchData, setB2cMatchData] = React.useState(getB2cMatchData.map(((d) => ({
        name: d.name,
        data: notRequested()
    }))));

    const stsUrl = React.useMemo(() => {
        if (matchId) {
            return getStsMatchDataUrl(matchId);
        }
        return undefined;
    }, [matchId, getStsMatchDataUrl]);

    const b2cUrls = React.useMemo(() => {
        if (projectId && seasonPlatformId && matchPlatformId) {
            return getB2cMatchData.map(
                (d) => d.getMatchDataUrl(projectId, seasonPlatformId, matchPlatformId)
            );
        }
        return [];
    }, [projectId, seasonPlatformId, matchPlatformId, getB2cMatchData]);

    React.useEffect(() => {
        if (matchId) {
            getStsMatchDataXml(matchId, setStsMatchData);
        } else {
            setStsMatchData(notRequested());
        }
    }, [matchId, getStsMatchDataXml]);

    React.useEffect(() => {
        if (projectId && seasonPlatformId && matchPlatformId) {
            for (let i = 0; i < getB2cMatchData.length; i += 1) {
                const {
                    name,
                    getMatchDataJson
                } = getB2cMatchData[i];

                getMatchDataJson(
                    projectId,
                    seasonPlatformId,
                    matchPlatformId,
                    (d) => setB2cMatchData(
                        (prev) => prev.map((p) => (p.name === name ? { ...p, data: d } : p))
                    )
                );
            }
        } else {
            for (let i = 0; i < getB2cMatchData.length; i += 1) {
                setB2cMatchData(
                    (prev) => prev.map((p, pi) => (pi === i ? { ...p, data: notRequested() } : p))
                );
            }
        }
    }, [projectId, seasonPlatformId, matchPlatformId, getB2cMatchData]);

    return (
        <>
            <MatchSelector {...props} />
            <Grid container columns={11}>
                <Grid item xs={1}>
                    URLs:
                </Grid>
                <Grid item xs={5} style={{ paddingRight: '0.5rem' }}>
                    <a href={stsUrl} target="_blank" rel="noreferrer">{stsUrl}</a>
                </Grid>
                <Grid item xs={5} style={{ overflowWrap: 'anywhere' }}>
                    {
                        b2cUrls.map((u) => (
                            <div key={u}>
                                <a href={u} target="_blank" rel="noreferrer">{u}</a>
                            </div>
                        ))
                    }
                </Grid>
            </Grid>
            <AsyncXmlAndMultipleJson xml={stsMatchData} json={b2cMatchData} />
        </>
    );
}
