export const TABS_ROUTES = {
    DETAILS: 'details',
    CONNECTIONS: 'connections',
    DATA_SOURCES: 'data-sources',
    DESTINATIONS: 'destinations'
};

export const getTabIndex = (projectTabRoute) => {
    if (!projectTabRoute) {
        return 0;
    }

    switch (projectTabRoute) {
        case TABS_ROUTES.CONNECTIONS:
            return 1;
        case TABS_ROUTES.DATA_SOURCES:
            return 2;
        // case TABS_ROUTES.DESTINATIONS:
        //    return 3;
        default:
            return 0;
    }
};
