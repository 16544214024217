import React from 'react';
import { useProvidersList } from './hooks';

export const withProviders = (WrappedComponent) => ({
    isLoading: isOuterLoading,
    isError: isOuterError,
    ...otherProps
}) => {
    const [{
        isLoading: isProvidersLoading,
        isError: isLoadProvidersError,
        data: providers
    }] = useProvidersList();
    const isError = isOuterError || isLoadProvidersError;
    const isLoading = isOuterLoading || isProvidersLoading;
    const providersSelectList = providers.map((provider) => ({
        key: provider.code,
        value: provider.name
    }));

    return (
        <WrappedComponent
            isLoading={isLoading}
            isError={isError}
            providersSelectList={providersSelectList}
            providersList={providers}
            {...otherProps}
        />
    );
};

export const getSchema = (selectedProviderCode, providers) => {
    const provider = providers.find((p) => p.code === selectedProviderCode);

    return provider && provider.jsonSchema ? provider.jsonSchema : '';
};
