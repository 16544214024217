import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ContactsIcon from '@material-ui/icons/Contacts';
import NotesIcon from '@material-ui/icons/Notes';
import OperationsMonitoringIcon from '@material-ui/icons/Dvr';
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite';
import ApiIcon from '@material-ui/icons/DataUsage';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import { ROUTES_URLS } from './Routes';
import ListItemLink from './ListItemLink';
import useMenuStyles from './Menu-styles';
import SvgLogo from './SvgLogo';

const menuItems = [
    {
        url: ROUTES_URLS.PROJECTS,
        linkText: 'Projects',
        Icon: ContactsIcon
    },
    {
        url: ROUTES_URLS.CONNECTIONS,
        linkText: 'Connections',
        Icon: SettingsInputCompositeIcon
    },
    // Hide Destinations for the time being
    // {
    //     url: ROUTES_URLS.DESTINATIONS,
    //     linkText: 'Destinations',
    //     Icon: FlipToFrontIcon
    // },
    {
        url: ROUTES_URLS.OPERATIONS,
        linkText: 'Monitoring',
        Icon: OperationsMonitoringIcon
    },
    {
        url: ROUTES_URLS.OPERATIONAL_TOOL,
        linkText: 'Operational Tools',
        Icon: BuildRoundedIcon
    },
    {
        url: ROUTES_URLS.APIS,
        linkText: 'APIs',
        Icon: ApiIcon
    },
    {
        url: ROUTES_URLS.LOGS,
        linkText: 'Logging Monitor',
        Icon: NotesIcon
    },
];

function Menu({ children }) {
    const classes = useMenuStyles();

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <SvgLogo className={classes.svgLogo} logoWidth="10rem" />
                    <div className={classes.logoRight}>
                        <img className={classes.imageLogo} src="/logo192.png" alt="Deltatre" />
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {menuItems.map((item) => (
                        <ListItemLink
                            key={item.url}
                            Icon={item.Icon}
                            to={item.url}
                            primary={item.linkText}
                        />
                    ))}
                </List>
            </Drawer>
            <main className={clsx(classes.content, {
                [classes.contentShift]: open,
            })}
            >
                <div className={classes.drawerHeader} />
                {children}
            </main>
        </>
    );
}

export default Menu;
