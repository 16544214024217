import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const IndexPage = ({ children }) => (
    <Grid container justifyContent="center">
        <Grid item xs={6}>
            <Typography component="div">{children}</Typography>
        </Grid>
    </Grid>
);

export default IndexPage;
