import React from 'react';
import { useHistory } from 'react-router-dom';
import { TABS_ROUTES } from '../projects/const';
import Dialog from '../shared/components/Dialog';
import IndexPage from '../shared/components/IndexPage';
import ConnectionsList from './listing/ConnectionsList';

function Connections() {
    const [showAddDialog, setShowAddDialog] = React.useState(false);

    const history = useHistory();

    const handleAddConnection = () => {
        setShowAddDialog(true);
    };
    const handleCloseAddDialog = () => {
        setShowAddDialog(false);
    };

    const goToDetails = (project, id) => {
        history.push(`/projects/${project}/${TABS_ROUTES.CONNECTIONS}/${id}`);
    };
    const goToProjects = () => {
        history.push('/projects');
    };

    return (
        <IndexPage>
            <ConnectionsList
                addConnection={handleAddConnection}
                connectionDetails={goToDetails}
            />
            <Dialog
                onCancel={handleCloseAddDialog}
                onConfirm={goToProjects}
                open={showAddDialog}
                confirmText="GO TO PROJECTS"
            >
                Connection creation is available only under the PROJECTS section.
            </Dialog>
        </IndexPage>
    );
}

export default Connections;
