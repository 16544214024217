export const getData = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
        if (response.status === 401) {
            window.location.reload(true);
        }

        throw new Error(
            `${response.status} ${response.statusText}`
        );
    }

    return response.json();
};

export const postData = (url, model, method = 'POST') => fetch(url, {
    method,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(model)
});
