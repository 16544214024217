import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Paper, Tooltip } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import IngestionStatusChip from '../../shared/components/IngestionStatusChip';
import Empty from '../../shared/components/Empty';
import { ingestionAggregatedStatuses, defaultIngestionStatus, successIngestionStatus } from '../../shared/const';
import { toRelativeTimeString } from '../../utils/datetime-util';

const columns = [{
    id: 'dataSetLabel',
    label: 'Label'
}, {
    id: 'dataSetName',
    label: 'DataSet Config',
    minWidth: 200
}, {
    id: 'adapterCode',
    label: 'Adapter',
    minWidth: 160
}, {
    id: 'cron',
    label: 'Cron',
    minWidth: 110
}, {
    id: 'dataInfo',
    label: 'Info',
    minWidth: 90,
    format: (info) => {
        if (info) {
            return info.description;
        }

        return '';
    }
}, {
    id: 'rawDataUrl',
    label: 'Raw Data',
    minWidth: 93,
    format: (url) => {
        if (!url) {
            return 'N/A';
        }

        return <Link to={url}>View</Link>;
    }
}, {
    id: 'lastRunInfo',
    label: 'Last Run Status',
    format: (value) => {
        const { status, timeStamp } = value;
        return (
            <Tooltip disableFocusListener placement="top" title={timeStamp ? toRelativeTimeString(timeStamp) : 'not available'}>
                <span><IngestionStatusChip status={status} /></span>
            </Tooltip>
        );
    }
}, {
    id: 'aggregateStatus',
    label: 'Status',
    format: (value) => <IngestionStatusChip status={value} />
}];

const DataSetConfigOpsList = ({ dataSetConfigs, keyInfo }) => {
    const keyedConfigs = dataSetConfigs.map((item) => ({
        code: item.dataSetConfigId,
        lastRunInfo: item.lastRunInfo ? item.lastRunInfo : { status: defaultIngestionStatus, timeStamp: '' },
        // eslint-disable-next-line max-len
        rawDataUrl: !item.lastRunInfo || item.lastRunInfo.status !== successIngestionStatus || !keyInfo
            ? null
            : `/operations/${keyInfo.projectCode}/datasources/${keyInfo.dataSourceCode}/rawdata/${item.lastRunInfo.operationId}`,
        dataInfo: item.lastRunInfo && item.lastRunInfo.status === 'RUNNING' ? item.lastRunInfo.dataInfo : null,
        ...item
    }));

    return (
        <Paper>
            {dataSetConfigs.length === 0 && (
                <Empty text="No Data Set Configs found" />
            )}
            {dataSetConfigs.length > 0 && (
                <DataTable
                    disablePagination
                    withoutAddButton
                    columns={columns}
                    rows={keyedConfigs}
                    details={() => { }}
                />
            )}
        </Paper>
    );
};

DataSetConfigOpsList.propTypes = {
    dataSetConfigs: PropTypes.arrayOf(PropTypes.shape({
        aggregateStatus: PropTypes.oneOf(ingestionAggregatedStatuses).isRequired,
        dataSetConfigId: PropTypes.number.isRequired,
        dataSetName: PropTypes.string,
        adapterCode: PropTypes.string.isRequired,
        cron: PropTypes.string,
        lastRunInfo: PropTypes.shape({
            operationId: PropTypes.string.isRequired,
            status: PropTypes.oneOf(ingestionAggregatedStatuses).isRequired,
            timeStamp: PropTypes.string,
            dataInfo: PropTypes.shape({
                description: PropTypes.string,
            }),
        })
    })).isRequired,
    keyInfo: PropTypes.shape({
        dataSourceCode: PropTypes.string.isRequired,
        projectCode: PropTypes.string.isRequired
    }).isRequired
};

export default DataSetConfigOpsList;
