import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Container,
    LinearProgress
} from '@material-ui/core';

import { useProjectStatusDetailsApi } from '../hooks';
import { useErrorToast } from '../../shared/toast/ToastProvider';
import GoBackButton from '../../shared/components/GoBackButton';
import ProjectOpsDetailsView from '../detail/ProjectOpsDetailsView';

export default function ProjectOpsDetails({ onGoBack, onViewDataSourceDetails }) {
    const { projectCode } = useParams();
    const [{
        isLoading,
        data: projectOpsDetails,
        isError: isLoadError
    }] = useProjectStatusDetailsApi(projectCode);

    const handleViewDataSourceDetails = (code) => {
        onViewDataSourceDetails(projectCode, code);
    };

    useErrorToast(isLoadError, 'Failed to project monitoring details');

    if (isLoading) {
        return (<LinearProgress role="progressbar" />);
    }

    return (
        <Container p={4} pt={4}>
            {
                !isLoadError && (
                    <ProjectOpsDetailsView
                        projectDetails={projectOpsDetails}
                        onViewDataSourceDetails={handleViewDataSourceDetails}
                    />
                )
            }
            <GoBackButton goBack={onGoBack} text="back to projects" />
        </Container>
    );
}
