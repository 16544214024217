import React from 'react';
import {
    Paper,
    Typography,
    Grid,
    Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Captioned from '../../shared/components/Captioned';
import RawDataFileView from '../listing/RawDataFileView';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    heading: {
        textTransform: 'uppercase'
    },
    mainInfo: {
        paddingBottom: theme.spacing(2)
    }
}));

export default function OperationDetailsView({
    operationDetails: {
        info: {
            adapter,
            adapterVersion,
            connectionCode,
            dataset,
            operationId,
            timestamp
        },
        calls
    }
}) {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Typography variant="h4" className={classes.heading}>Operation Details</Typography>
            <Divider />
            <Grid container spacing={4} className={classes.mainInfo}>
                <Grid item>
                    <Captioned caption="Adapter">
                        <Typography variant="h5">{adapter}</Typography>
                    </Captioned>
                </Grid>
                <Grid item>
                    <Captioned caption="Adapter Version">
                        <Typography variant="h5">{adapterVersion}</Typography>
                    </Captioned>
                </Grid>
                <Grid item>
                    <Captioned caption="Operation ID">
                        <Typography variant="h5">{operationId}</Typography>
                    </Captioned>
                </Grid>
                <Grid item>
                    <Captioned caption="Connection Code">
                        <Typography variant="h5">{connectionCode}</Typography>
                    </Captioned>
                </Grid>
                <Grid item>
                    <Captioned caption="Data Set">
                        <Typography variant="h5">{dataset}</Typography>
                    </Captioned>
                </Grid>
                <Grid item>
                    <Captioned caption="Date">
                        <Typography variant="h5">{`${new Date(timestamp)}`}</Typography>
                    </Captioned>
                </Grid>
            </Grid>
            <Typography variant="h5">Data recieved</Typography>
            <Divider />
            <RawDataFileView calls={calls} />
        </Paper>
    );
}
