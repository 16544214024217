import { Grid } from '@material-ui/core';
import React from 'react';
import CompetitionSelect from './competitionSelect';
import ConnectionSelect from './connectionSelect';
import ProjectSelect from './projectSelect';
import SeasonSelect from './seasonSelect';

export default function CompetitionAndSeasonSelector(props) {
    const { competitionPlatformId, seasonPlatformId } = props;

    return (
        <>
            <Grid container columns={11}>
                <Grid item xs={1}>
                    Project:
                </Grid>
                <Grid item xs={10}>
                    <ProjectSelect {...props} />
                </Grid>
            </Grid>
            <Grid container columns={11}>
                <Grid item xs={1}>
                    Connection:
                </Grid>
                <Grid item xs={10}>
                    <ConnectionSelect {...props} />
                </Grid>
            </Grid>
            <Grid container columns={11}>
                <Grid item xs={1}>
                    Competition:
                </Grid>
                <Grid item xs={5}>
                    <CompetitionSelect {...props} />
                </Grid>
                <Grid item xs={5}>
                    {competitionPlatformId}
                </Grid>
            </Grid>
            <Grid container columns={11}>
                <Grid item xs={1}>
                    Season:
                </Grid>
                <Grid item xs={5}>
                    <SeasonSelect {...props} />
                </Grid>
                <Grid item xs={5}>
                    {seasonPlatformId}
                </Grid>
            </Grid>
        </>
    );
}
