/* eslint-disable */
import { getText } from './http';

class StandingsApi {
    constructor(b2cHost, urlPart) {
        this.b2cHost = b2cHost;
        this.urlPart = urlPart;
    }

    getUrlSuffix(projectId, seasonId) {
        return `v1/${projectId}/football/seasons/${seasonId}/${this.urlPart}`;
    }

    getUrl(seasonId, projectId) {
        return `${this.b2cHost}/${this.getUrlSuffix(seasonId, projectId)}`;
    }

    getJson(projectId, seasonId, setJson) {
        const url = `/api/sts-mls/b2c?b2cUrl=${encodeURIComponent(
            this.getUrlSuffix(projectId, seasonId)
        )}`;
        return getText(url, setJson);
    }
}

export function getStandingsApi(b2cHost) {
    return new StandingsApi(b2cHost, 'standings');
}